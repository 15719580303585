import { useCallback } from 'react';
import useMessageApi from 'components/global/useMessageApi';

function useAddressLookupHandler() {
  const message = useMessageApi();
  const func = async (input: {
    address1?: string;
    postCode?: string;
    postCity?: string;
    country?: string;
    hideMessages?: boolean;
  }): Promise<{ position: { lat: number; lng: number }; partial: boolean }> => {
    const { address1, postCity, postCode, country, hideMessages } = input;
    const address = [address1, postCode, postCity, country].filter((c) => !!c).join(', ');

    if (!hideMessages) {
      message.loading({
        key: 'lookup' + address,
        content: 'Lookup address: ' + address
      });
    }

    const geocoder = new google.maps.Geocoder();
    const response = await geocoder.geocode({
      address
    });

    if (response?.results.length === 1) {
      const lat1 = response.results[0].geometry.location.lat();
      const lng1 = response.results[0].geometry.location.lng();

      if (response.results[0].partial_match && !hideMessages) {
        message.info({
          key: 'lookup' + address,
          content: `Approximate location found: ${lat1}, ${lng1}`
        });
      } else {
        if (!hideMessages) {
          message.success({
            key: 'lookup' + address,
            content: `Location found: ${lat1}, ${lng1}`
          });
        }
      }
      return {
        position: { lat: lat1, lng: lng1 },
        partial: response.results[0].partial_match || false
      };
    } else {
      throw new Error('No location found');
    }
  };

  return useCallback(func, [message]);
}

export default useAddressLookupHandler;
