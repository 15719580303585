import React, { useMemo } from 'react';
import { SelectProps } from 'antd/es/select';
import { useGetTicketOrderLineCategoriesQuery } from 'generated/types.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import CategoryTag from 'components/ticket/TicketAccounting/CategoryTag.tsx';
import { Flex } from 'antd';
import useTicketAccountingToolFilter from 'components/ticket/TicketAccounting/OrderLineTool/useTicketAccountingToolFilter.ts';
import Select from 'components/lib/Select/Select.tsx';

type OtherSelectProps = Omit<SelectProps<number[]>, 'value' | 'onSelect' | 'onChange' | 'options'>;

const CategoryFilterSelect: React.FC<OtherSelectProps> = (props) => {
  const intl = useConnectIntl();
  const { data, loading } = useGetTicketOrderLineCategoriesQuery({
    notifyOnNetworkStatusChange: true
  });
  const { filter, setFilter } = useTicketAccountingToolFilter();

  const options = useMemo(() => {
    return data?.allTicketOrderLineCategories.map((c) => ({
      value: c.ticketOrderLineCategoryId,
      label: (
        <Flex gap={'small'}>
          {c.name && <CategoryTag name={c.name} />}
          <div>{c.name}</div>
        </Flex>
      )
    }));
  }, [data?.allTicketOrderLineCategories]);

  const handleChange = (value: number[]) => {
    setFilter({ categoryIds: value.length ? value : undefined });
  };

  return (
    <Select<number[]>
      placeholder={intl.formatMsg({
        id: 'category_filter_select.placeholder',
        defaultMessage: 'Category'
      })}
      maxTagCount={1}
      mode={'multiple'}
      loading={loading}
      showSearch={false}
      style={{ minWidth: 200 }}
      popupMatchSelectWidth={false}
      allowClear={true}
      options={options}
      value={loading ? undefined : filter.categoryIds}
      onChange={handleChange}
      {...props}
    />
  );
};

export default CategoryFilterSelect;
