import React from 'react';
import { TicketArticleGroupIcon } from 'generated/types';
import {
  CarOutlined,
  DollarOutlined,
  FieldTimeOutlined,
  GiftOutlined,
  ProfileOutlined,
  ToolOutlined
} from '@ant-design/icons';

export function getArticleTypeIcon(type: TicketArticleGroupIcon) {
  switch (type) {
    case TicketArticleGroupIcon.Wrench:
      return <ToolOutlined />;
    case TicketArticleGroupIcon.Hour:
      return <FieldTimeOutlined />;
    case TicketArticleGroupIcon.DollarSign:
      return <DollarOutlined />;
    case TicketArticleGroupIcon.Travel:
      return <CarOutlined />;
    case TicketArticleGroupIcon.Generic:
      return <ProfileOutlined />;
    case TicketArticleGroupIcon.Freight:
      return <GiftOutlined />;
  }
}
