import React from 'react';
import { Typography } from 'antd';
import useConnectIntl from 'i18n/useConnectIntl.ts';

const TicketDueDateStatusText: React.FC = () => {
  const intl = useConnectIntl();
  return (
    <Typography.Text>
      {intl.formatMsg({
        id: 'ticket_due_date_status_text.resolved',
        defaultMessage: 'Closed'
      })}
    </Typography.Text>
  );
};

export default TicketDueDateStatusText;
