import { from, HttpLink, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { message } from 'antd';
import { setContext } from '@apollo/client/link/context';
import { appConfig } from 'common/appConfig';
import { getMainDefinition } from '@apollo/client/utilities';
import getAccessToken from 'auth/getAccessToken';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { parseISO } from 'date-fns';
import { AccessToken } from 'api/api.ts';
import { store } from 'redux/store.ts';
import { setWsConnected } from 'redux/appSlice.ts';

const httpLink = new HttpLink({
  uri: appConfig.API_GRAPHQL_URL
});

// errorLink - log errors. if "401", refresh if we got a token, fail if not
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.extensions?.code) {
        case 'AUTH_NOT_AUTHENTICATED':
        case 'AUTH_NOT_AUTHORIZED':
        case 'NOT_FOUND':
          // NOTE: These will render as "not found", let's not show them as errors
          break;
        default:
          message.error(err.message);
          break;
      }
    }
  }
});

// authLink - adds bearer token header
const authLink = setContext(async (_, { headers }) => {
  let authHeader = {};

  try {
    const token = await getAccessToken('auth-link');

    if (token?.bearerToken) {
      authHeader = {
        Authorization: `Bearer ${token.bearerToken}`
      };
    }
  } catch (err) {
    console.log('Failed to add auth header in apolloAuthLink');
  }

  return {
    headers: {
      ...headers,
      ...authHeader
    }
  };
});

/**
 * This is a factory function that creates a new GraphQLWsLink instance.
 * - It will attempt to reconnect forever if websocket connection is lost.
 * - It will schedule a token refresh/check.
 *   GraphQL requests will always refresh before requests if needed.
 *   Websocket connection will use a timer to schedule refreshes.
 *
 *   TODO: This works, but we currently don't provide any feedback to the user that the connection is lost.
 *         Investigate which patterns we can use to accomplish this. Some alternatives:
 *         1. retry-link? Will that work with ws?
 *         2. Fail the ws-connection here, handle errors in the component?
 *         3. A global offline handler + api ping-pong handler? With a "reconnect (5)" button?
 *           - this could be a good solution together with the current reconnect logic?
 */
const wsLinkFactory = () => {
  let activeSocket: unknown | undefined;
  let serverPingPongTimeout: NodeJS.Timeout | undefined;
  let refreshTimeout: NodeJS.Timeout | undefined;

  const handleRefresh = async () => {
    try {
      const token = await getAccessToken('ws-link');
      if (token) {
        await setNextRefresh(token);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const setNextRefresh = async (token: AccessToken) => {
    try {
      if (token.expires) {
        if (refreshTimeout) {
          clearTimeout(refreshTimeout);
        }
        const expiresDate = parseISO(token.expires);
        const now = new Date();
        const bufferMs = 10000; // refresh 10 seconds before token expires
        const diff = expiresDate.getTime() - now.getTime();
        const actual = Math.max(diff - bufferMs, 100);
        if (diff > 0 && activeSocket && activeSocket instanceof WebSocket) {
          refreshTimeout = setTimeout(() => {
            handleRefresh();
          }, actual);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fibonacci = (n: number): number => {
    if (n <= 1) return n;
    return fibonacci(n - 1) + fibonacci(n - 2);
  };

  const retryWait = (retries: number): Promise<void> => {
    return new Promise<void>((resolve) => {
      const delay = fibonacci(retries);
      setTimeout(resolve, delay * 1000); // Convert to milliseconds
    });
  };

  return new GraphQLWsLink(
    createClient({
      url: appConfig.API_GRAPHQL_SUBSCRIPTION_URL,
      retryAttempts: Infinity, // If websocket connection is lost, keep trying to reconnect forever
      shouldRetry: () => true,
      retryWait: retryWait,
      keepAlive: 10000, // ping-pong every 10 seconds
      on: {
        connected: async (socket: unknown) => {
          activeSocket = socket;
          try {
            const token = await getAccessToken('ws-link');
            if (token?.bearerToken) {
              await setNextRefresh(token);
            }
          } catch (err) {
            console.error(err);
          }
          console.log('🔌 wsLink connected');
          store.dispatch(setWsConnected(true));
        },
        closed: () => {
          console.log('🔌 wsLink closed');
          clearTimeout(refreshTimeout);
          clearTimeout(serverPingPongTimeout);
          store.dispatch(setWsConnected(false));
        }
        // ping: (received) => {
        //   if (!received) {
        //     serverPingPongTimeout = setTimeout(() => {
        //       if (activeSocket instanceof WebSocket) {
        //         // If we don't receive pong from the server in a timely manner, close the socket.
        //         // (This will trigger reconnect)
        //         console.log('🏓 wsLink ping-pong timeout, closing socket!');
        //         activeSocket?.close(4408, 'PingPong timeout');
        //       }
        //     }, 5000);
        //   }
        // },
        // pong: (received) => {
        //   if (received) {
        //     clearTimeout(serverPingPongTimeout);
        //   }
        // },
      },
      connectionParams: async () => {
        let authHeader = {};

        try {
          const token = await getAccessToken('ws-link');
          if (token?.bearerToken) {
            authHeader = {
              Authorization: `Bearer ${token.bearerToken}`
            };
          }
        } catch (err) {
          console.error(err);
        }

        return authHeader;
      }
    })
  );
};

const wsLink = wsLinkFactory();

// wsLink and httpLink are "terminating" links, meaning they have to be last in the chain
// this link splits requests between the two:
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

const apolloLink = from([errorLink, authLink, splitLink]);

export default apolloLink;
