import React, { useCallback, useEffect, useState } from 'react';
import { RetailerListItemFragment, useGetRetailerListQuery } from 'generated/types';
import Select, { Option } from 'components/lib/Select/Select';
import { BaseOptionType, DefaultOptionType, SelectProps, SelectValue } from 'antd/es/select';
import { Space } from 'antd';
import { CompanyFilledIcon } from 'components/icons/Icons';
import { FilterFunc } from 'rc-select/es/Select';

interface RetailerOption extends DefaultOptionType {
  retailer: RetailerListItemFragment;
}

type OtherSelectProps = Omit<SelectProps<number, RetailerOption>, 'options'>;

function TypedSelect<
  T extends SelectValue = string,
  TItem extends BaseOptionType | DefaultOptionType = RetailerOption
>({ children, ...props }: SelectProps<T, TItem>) {
  return <Select<T, TItem> {...props}>{children}</Select>;
}

const RetailerSelect: React.FC<OtherSelectProps> = (props) => {
  const { children, value, ...rest } = props;
  const [searchTerm, setSearchTerm] = useState('');

  const { data, fetchMore, loading } = useGetRetailerListQuery({
    variables: {
      searchTerm: '',
      cursor: undefined,
      includeMachineCount: false,
      showActiveMachines: undefined,
      showRetailersWithoutMachines: true
    },
    notifyOnNetworkStatusChange: true
  });

  const hasNextPage = data?.allRetailers?.pageInfo.hasNextPage || false;
  const nextCursor = data?.allRetailers?.pageInfo.endCursor;

  useEffect(() => {
    if (hasNextPage) {
      fetchMore({
        variables: {
          cursor: nextCursor,
          searchTerm: '',
          includeMachineCount: false,
          showActiveMachines: undefined,
          showRetailersWithoutMachines: true
        }
      });
    }
  }, [hasNextPage, nextCursor, fetchMore]);

  const handleFilterOption = useCallback<FilterFunc<RetailerOption>>((inputValue, option) => {
    return (
      (option && option.retailer.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1) || false
    );
  }, []);

  return (
    <TypedSelect
      showSearch={true}
      loading={loading}
      searchValue={searchTerm}
      onSearch={(value) => setSearchTerm(value)}
      filterOption={handleFilterOption}
      value={loading ? undefined : value}
      {...rest}
    >
      {data?.allRetailers?.edges
        ?.map((c) => c.node)
        .map((c) => {
          return (
            <Option key={c.retailerId} value={c.retailerId} retailer={c} label={c.name}>
              <Space>
                <CompanyFilledIcon />
                <div>{c.name}</div>
              </Space>
            </Option>
          );
        })}
    </TypedSelect>
  );
};

export default RetailerSelect;
