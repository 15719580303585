import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store.ts';
import { apolloClient } from 'graphql/apollo/apolloClient.ts';
import {
  DeployRetailerChangesDocument,
  DeployRetailerChangesMutation,
  DeployRetailerChangesMutationVariables
} from 'generated/types.tsx';
import { gql } from '@apollo/client';

gql`
  mutation DeployRetailerChanges($retailerId: Int!) {
    deployRetailerChanges(input: { forceAll: false, retailerId: $retailerId }) {
      retailer {
        id
        retailerId
      }
    }
  }
`;

export interface DeployState {
  isDeploying: boolean;
  pendingRetailerIds: number[];
}

const defaultState: DeployState = {
  isDeploying: false,
  pendingRetailerIds: []
};

export const addPendingRetailerChange = createAction<number>('deploy/addPendingRetailerChange');

export const flushPendingRetailerChanges = createAsyncThunk(
  'deploy/flushPendingRetailerChanges',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    if (state.deploy.isDeploying) {
      return;
    }
    if (state.deploy.pendingRetailerIds.length === 0) {
      return;
    }
    console.log('deploying changes');
    await apolloClient.query<DeployRetailerChangesMutation, DeployRetailerChangesMutationVariables>(
      {
        query: DeployRetailerChangesDocument
      }
    );
  }
);

export const selectHasPendingRetailerChanges = (state: RootState) => {
  return state.deploy.pendingRetailerIds.length > 0;
};

export const deploySlice = createSlice({
  name: 'deploy',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addPendingRetailerChange, (state, action) => {
      return {
        ...state,
        pendingRetailerIds: [...new Set([...state.pendingRetailerIds, action.payload])]
      };
    });
    builder.addCase(flushPendingRetailerChanges.pending, (state) => {
      return {
        ...state,
        isDeploying: true
      };
    });
    builder.addCase(flushPendingRetailerChanges.fulfilled, (state) => {
      return {
        ...state,
        isDeploying: false,
        pendingRetailerIds: []
      };
    });
    builder.addCase(flushPendingRetailerChanges.rejected, (state) => {
      return {
        ...state,
        isDeploying: false
      };
    });
  }
});

export const deploySliceReducer = deploySlice.reducer;
