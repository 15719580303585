import React, { useCallback, useState } from 'react';
import { Button, Col, Radio, Row, Switch, Typography, Divider, Space } from 'antd';
import { useGoogleMap } from '@react-google-maps/api';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import commonMessages from 'components/i18n/commonMessages';
import Popover from 'components/lib/Popover/Popover';
import { MoreOutlined } from '@ant-design/icons';

interface Props {
  controls?: JSX.Element;
  showPoi: boolean;
  onToggleShowPoi: (checked: boolean) => void;
}

const messages = defineMessages({
  roadmap: {
    id: 'map_layer_controls.roadmap',
    defaultMessage: 'Roadmap'
  },
  satellite: {
    id: 'map_layer_controls.satellite',
    defaultMessage: 'Satellite'
  },
  mapType: {
    id: 'map_layer_controls.type',
    defaultMessage: 'Map type'
  },
  layers: {
    id: 'map_layer_controls.layers',
    defaultMessage: 'Layers'
  },
  poi: {
    id: 'map_layer_controls.poi',
    defaultMessage: 'Points of interest'
  }
});

const MapLayerControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 8px;
`;

const MapLayerDivider = styled(Divider)`
  margin: 8px 0;
`;

export const MapLayerControlItem = styled.div``;

const MapLayerControls: React.FC<Props> = (props) => {
  const { controls, showPoi, onToggleShowPoi } = props;
  const map = useGoogleMap();
  if (!map) {
    throw new Error('Cannot use outside map');
  }

  const intl = useIntl();
  const [mapType, setMapType] = useState(map.getMapTypeId());
  const [open, setOpen] = useState(false);
  const handleOpenChange = useCallback((newOpen: boolean) => {
    setOpen(newOpen);
  }, []);
  const isRoadmap = mapType === google.maps.MapTypeId.ROADMAP;
  const isSatellite = mapType === google.maps.MapTypeId.SATELLITE;

  const handleTogglePoi = (checked: boolean) => {
    onToggleShowPoi(checked);
  };

  const changeMapType = (type: google.maps.MapTypeId.ROADMAP | google.maps.MapTypeId.SATELLITE) => {
    map.setMapTypeId(type);
    setMapType(type);
  };

  const handleSwitch = () => {
    if (isRoadmap) {
      changeMapType(google.maps.MapTypeId.SATELLITE);
    } else if (isSatellite) {
      changeMapType(google.maps.MapTypeId.ROADMAP);
    }
  };

  return (
    <Space.Compact>
      <Button onClick={handleSwitch}>
        {isRoadmap && <span>{intl.formatMessage(messages.satellite)}</span>}
        {isSatellite && <span>{intl.formatMessage(messages.roadmap)}</span>}
      </Button>
      <Popover
        trigger={'click'}
        open={open}
        onOpenChange={handleOpenChange}
        content={
          <MapLayerControlsContainer>
            {/*<Typography.Text type={'secondary'}>*/}
            {/*  {intl.formatMessage(messages.mapType)}*/}
            {/*</Typography.Text>*/}
            <MapLayerControlItem>
              <Radio.Group
                value={mapType}
                onChange={(e) => {
                  changeMapType(e.target.value);
                }}
              >
                <Radio value={'roadmap'}>{intl.formatMessage(messages.roadmap)}</Radio>
                <Radio value={'satellite'}>{intl.formatMessage(messages.satellite)}</Radio>
              </Radio.Group>
            </MapLayerControlItem>

            <MapLayerDivider />

            {/*<Typography.Text type={'secondary'}>*/}
            {/*  {intl.formatMessage(messages.layers)}*/}
            {/*</Typography.Text>*/}
            {controls}

            <MapLayerControlItem>
              <Row justify={'space-between'} gutter={[4, 4]}>
                <Col>
                  <Typography.Text>{intl.formatMessage(messages.poi)}</Typography.Text>
                </Col>
                <Col>
                  <Switch
                    checked={showPoi}
                    onChange={handleTogglePoi}
                    title={intl.formatMessage(messages.poi)}
                    checkedChildren={intl.formatMessage(commonMessages.on)}
                    unCheckedChildren={intl.formatMessage(commonMessages.off)}
                  />
                </Col>
              </Row>
            </MapLayerControlItem>
          </MapLayerControlsContainer>
        }
        placement={'bottomRight'}
      >
        <Button icon={<MoreOutlined />} size={'middle'} />
      </Popover>
    </Space.Compact>
  );
};

export default MapLayerControls;
