import React from 'react';
import { Typography } from 'antd';
import Select, { Option } from 'components/lib/Select/Select.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { useGetAllTicketTagsQuery } from 'generated/types.tsx';
import { SelectProps } from 'antd/es/select';
import useTicketFilter from 'components/ticket/TicketFilter/useTicketFilter.ts';

type OtherSelectProps = Omit<SelectProps, 'value' | 'onSelect' | 'onChange' | 'options'>;

const TicketTagsFilterSelect: React.FC<OtherSelectProps> = (props) => {
  const intl = useConnectIntl();
  const { data, loading } = useGetAllTicketTagsQuery();
  const { ticketFilter, setTicketFilter } = useTicketFilter();

  const handleChangeTag = (tags: string[]) => {
    setTicketFilter({
      ...ticketFilter,
      tags: tags.length > 0 ? tags : undefined
    });
  };

  return (
    <Select<string[]>
      placeholder={intl.formatMsg({
        id: 'ticket_tags_select.placeholder',
        defaultMessage: 'Tags'
      })}
      mode={'tags'}
      loading={loading}
      showSearch={true}
      style={{ minWidth: 150 }}
      popupMatchSelectWidth={false}
      allowClear={true}
      value={ticketFilter.tags}
      onChange={handleChangeTag}
      {...props}
    >
      {data?.allTicketTags.map((tag) => (
        <Option key={tag} value={tag}>
          <Typography.Text>{tag}</Typography.Text>
        </Option>
      ))}
    </Select>
  );
};

export default TicketTagsFilterSelect;
