import useQueryParam from 'hooks/useQueryParam';
import { TicketListDisplayMode } from 'components/ticket/TicketList/TicketListDisplayModeSegmented';

const TICKET_DISPLAY_MODE_KEY = 'tm';
const defaultDisplayMode: TicketListDisplayMode = 'list';

function useTicketDisplayMode() {
  const [modeParam, setModeParam] = useQueryParam<TicketListDisplayMode>(TICKET_DISPLAY_MODE_KEY);
  const ticketDisplayMode = modeParam || defaultDisplayMode;
  const setTicketDisplayMode = setModeParam;
  return { ticketDisplayMode, setTicketDisplayMode };
}

export default useTicketDisplayMode;
