import { defineMessages } from 'react-intl';

const machinePropertyMessages = defineMessages({
  notSetValue: {
    id: 'machineProperty.not_set_value',
    defaultMessage: 'Not set'
  },
  noHistory: {
    id: 'machineProperty.no_history',
    defaultMessage: 'No history'
  },
  clearValue: {
    id: 'machineProperty.clearValue',
    defaultMessage: 'Unset machine property'
  },
  setValue: {
    id: 'machineProperty.setValue',
    defaultMessage: 'Set machine property'
  },
  notSupported: {
    id: 'machineProperty.notSupported',
    defaultMessage: 'Not supported'
  },
  readonly: {
    id: 'machineProperty.readonly',
    defaultMessage: 'Read only'
  },
  notAuthorized: {
    id: 'machineProperty.notAuthorized',
    defaultMessage: 'You are not authorized to change this property'
  }
});

export default machinePropertyMessages;
