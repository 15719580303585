import React from 'react';
import { gql } from '@apollo/client/core';
import {
  TicketListItemFragmentDoc,
  TicketPermissionsFullFragment,
  TicketPermissionsFullFragmentDoc,
  useGetTicketPermissionsQuery
} from 'generated/types.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { useNavigate, useParams } from 'react-router-dom';
import parseNumber from 'util/parseNumber.ts';
import styled from 'styled-components';
import Spinner from 'components/lib/Spinner/Spinner.tsx';
import { isNotFoundError, isUnauthorizedApolloError } from 'graphql/apollo/apolloErrorUtil.ts';
import NotAuthorizedPage from 'pages/util/NotAuthorizedPage/NotAuthorizedPage.tsx';
import ErrorPage from 'pages/util/ErrorPage/ErrorPage.tsx';
import { Button } from 'antd';

interface Props {
  children?: React.ReactNode;
}

const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

gql`
  query GetTicketPermissions($ticketId: Int!) {
    ticket(ticketId: $ticketId) {
      ...TicketListItem
      permissions {
        ...TicketPermissionsFull
      }
    }
  }
  ${TicketListItemFragmentDoc}
  ${TicketPermissionsFullFragmentDoc}
`;

interface TicketPermissionsContext {
  permissions: TicketPermissionsFullFragment;
}

const TicketPermissionsContext = React.createContext<TicketPermissionsContext | undefined>(
  undefined
);

export const useTicketPermissions = () => {
  const context = React.useContext(TicketPermissionsContext);
  if (!context) {
    throw new Error('useTicketPermissions must be used within a TicketPermissionsContext');
  }
  return context.permissions;
};

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const RequireTicketAccess: React.FC<Props> = ({ children }) => {
  const intl = useConnectIntl();
  const { ticketId: ticketIdRaw } = useParams<{ ticketId: string }>();
  const ticketId = parseNumber(ticketIdRaw);
  const isValidTicketId = ticketId !== undefined;
  const navigate = useNavigate();

  const { data, loading, error } = useGetTicketPermissionsQuery({
    variables: isValidTicketId ? { ticketId } : undefined,
    skip: !isValidTicketId
  });

  if (loading) {
    return (
      <Container>
        <Spinner delayUntil={600} />
      </Container>
    );
  }

  const isUnauthorized = isUnauthorizedApolloError(error);
  const isNotFound = isNotFoundError(error);
  const hasFullTicketAccess = data?.ticket.permissions.canEditTicket;

  if (isUnauthorized || isNotFound || !isValidTicketId || hasFullTicketAccess === false) {
    return (
      <NotAuthorizedPage
        subtitle={intl.formatMsg(
          {
            id: 'require_ticket_access.subtitle',
            defaultMessage:
              'Sorry, you don’t have sufficient access to ticket {ticketId}. {br}Please contact the ticket owner for more information.'
          },
          { br: <br />, ticketId: ticketIdRaw }
        )}
        extra={
          <Center>
            <Button onClick={() => navigate(-1)} size={'small'} type={'link'}>
              Go back
            </Button>
          </Center>
        }
      />
    );
  } else if (error) {
    return <ErrorPage error={error} />;
  }

  return data ? (
    <TicketPermissionsContext.Provider value={{ permissions: data.ticket.permissions }}>
      {children}
    </TicketPermissionsContext.Provider>
  ) : (
    children
  );
};

export default RequireTicketAccess;
