import React, { forwardRef, useCallback, useState } from 'react';
import {
  MachinePropertyWithValueFragment,
  useSetMachinePropertyNumberValueMutation
} from 'generated/types.tsx';
import { gql } from '@apollo/client';
import { MachinePropertyValueEditor } from 'components/machine/properties/MachinePropertyList/machinePropertyValueEditor.ts';
import NotSetValue from 'components/machine/properties/MachinePropertyList/NotSetValue.tsx';
import { getFriendlyApolloErrorMessage } from 'graphql/apollo/apolloErrorUtil.ts';
import useMessageApi from 'components/global/useMessageApi.ts';
import { useMachinePermissions } from 'auth/RequireMachineAccess.tsx';
import BigText from 'components/machine/properties/MachinePropertyList/BigText.tsx';

gql`
  mutation SetMachinePropertyNumberValue($input: SetNumberInput!) {
    setMachinePropertyValue(input: { numberValue: $input }) {
      ...MachinePropertyWithValue
      valueHistory {
        ...NumberPropertyValueFull
      }
    }
  }
`;

interface Props {
  property: MachinePropertyWithValueFragment;
  value: number | null | undefined;
}

const NumberValue = forwardRef<MachinePropertyValueEditor, Props>(
  ({ property, value: originalValue }, ref) => {
    const [editing, setEditing] = useState(false);
    const [setValue] = useSetMachinePropertyNumberValueMutation();
    const messageApi = useMessageApi();
    const { canEditDetails } = useMachinePermissions();

    React.useImperativeHandle(
      ref,
      () => ({
        enableEditMode: () => setEditing(true)
      }),
      [setEditing]
    );

    const save = useCallback(
      async (value: string) => {
        try {
          const parsed = parseInt(value);
          if (isNaN(parsed)) {
            throw new Error('Invalid number');
          }

          setEditing(false);

          if (parsed === originalValue) {
            return;
          }

          await setValue({
            variables: {
              input: {
                machineId: property.machineId,
                serialNo: property.machineSerialNo,
                key: property.key,
                value: parsed
              }
            },
            optimisticResponse: {
              __typename: 'Mutation',
              setMachinePropertyValue: {
                ...property,
                __typename: 'MachineProperty',
                key: property.key,
                machineId: property.machineId,
                machineSerialNo: property.machineSerialNo,
                valueHistory: [
                  {
                    __typename: 'NumberPropertyValue',
                    numberValue: parsed,
                    active: true,
                    id: 'temp-id-value',
                    changed: new Date().toISOString(),
                    changedByUser: null // This will make the value update optimistically, and the user will pop up when saved to server
                  }
                ],
                value: {
                  numberValue: parsed,
                  active: true,
                  id: 'temp-id-value',
                  changed: new Date().toISOString(),
                  changedByUser: null
                }
              }
            }
          });
        } catch (err) {
          messageApi.error(
            getFriendlyApolloErrorMessage(err, 'Could not set machine property value. ')
          );
        }
      },
      [originalValue, setValue, property, messageApi]
    );

    if (!editing && (originalValue === null || originalValue === undefined)) {
      return <NotSetValue />;
    }

    return (
      <div>
        <BigText
          editable={
            property.readOnly || !canEditDetails
              ? false
              : {
                  triggerType: ['icon', 'text'],
                  editing: editing,
                  onStart: () => {
                    setEditing(true);
                  },
                  onCancel: () => {
                    setEditing(false);
                  },
                  onChange: async (value) => {
                    await save(value);
                  }
                }
          }
        >
          {originalValue || ''}
        </BigText>
      </div>
    );
  }
);

NumberValue.displayName = 'NumberValue';

export default NumberValue;
