import React from 'react';
import { useAppSelector } from 'redux/store.ts';
import { selectRootPermissions } from 'auth/authSlice.ts';
import { Button, Card, Divider, Flex, Space } from 'antd';
import commonMessages from 'components/i18n/commonMessages.ts';
import ReDeployParameterButton from 'components/retailer/ReDeployParametersButton/ReDeployParameterButton.tsx';
import Link from 'components/lib/Link/Link.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client/core';
import { useGetApiUrlsQuery } from 'generated/types.tsx';

gql`
  query GetApiUrls {
    me {
      id
      userId
      apiDocsUrls {
        name
        url
      }
    }
  }
`;

const RootActionsCard: React.FC = () => {
  const permissions = useAppSelector(selectRootPermissions);
  const intl = useConnectIntl();
  const navigate = useNavigate();

  const { data, error } = useGetApiUrlsQuery({
    fetchPolicy: 'cache-first'
  });

  if (!permissions) {
    return null;
  }

  const hasPermissionToAtLeastOneAction =
    permissions?.canCreateRetailer ||
    permissions?.canRedeployParameters ||
    permissions?.translationTool;
  if (!hasPermissionToAtLeastOneAction) {
    return null;
  }

  return (
    <Card title={intl.formatMsg(commonMessages.actions)}>
      <Space direction={'vertical'} style={{ width: '100%' }}>
        {permissions?.canCreateRetailer && (
          <Button
            type={'default'}
            style={{ width: '100%' }}
            onClick={() => navigate('/retailer/new')}
            disabled={!permissions.canCreateRetailer}
          >
            {intl.formatMsg({
              id: 'root_page.add_new_retailer',
              defaultMessage: 'Add retailer'
            })}
          </Button>
        )}
        {permissions?.canRedeployParameters && (
          <ReDeployParameterButton
            canRedeployParameters={permissions?.canRedeployParameters || false}
          />
        )}

        {permissions?.translationTool && (
          <>
            <Divider />
            <Link to={'/i18n'}>
              {intl.formatMsg({
                id: 'root_page.translation_tool',
                defaultMessage: 'Translation tool (beta)'
              })}
            </Link>
            {data?.me.apiDocsUrls.map((item) => (
              <a key={item.url} href={item.url} target={'_blank'} rel={'noreferrer'}>
                {item.name}
              </a>
            ))}
          </>
        )}
      </Space>
    </Card>
  );
};

export default RootActionsCard;
