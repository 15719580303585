import React from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Link from 'components/lib/Link/Link.tsx';
import { useAppSelector } from 'redux/store.ts';
import { selectIsAuthenticated } from 'auth/authSlice.ts';
import useConnectIntl from 'i18n/useConnectIntl';
import { AnimatePresence, motion } from 'framer-motion';
import useMessageApi from 'components/global/useMessageApi.ts';
import loginMessages from 'layouts/LoginLayout/loginMessages.ts';
import { VendanorConnectText } from 'layouts/components/icons/VendanorConnectText.tsx';

interface FormProps {
  username: string;
}

const LostPasswordPage = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const intl = useConnectIntl();
  const [form] = Form.useForm<FormProps>();
  const messageApi = useMessageApi();
  // const params = useParams<{ token?: string }>();

  const handleReset = (values: FormProps) => {
    console.log('👮‍ [auth]> handleReset', values);
    messageApi.warning('Not implemented yet');
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 5 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 5 }}
        style={{
          width: '100%'
        }}
      >
        <Form
          style={{ width: '100%' }}
          name={'login-form'}
          form={form}
          size={'large'}
          onFinish={handleReset}
          layout={'vertical'}
        >
          <Form.Item>
            <VendanorConnectText width={'220px'} />
            <br />
            <Typography.Text>
              {intl.formatMsg(loginMessages.resetPasswordDescription)}
            </Typography.Text>
          </Form.Item>
          {!isAuthenticated && (
            <>
              <Form.Item
                name={'username'}
                rules={[
                  {
                    required: true,
                    message: intl.formatMsg(loginMessages.emailMissing)
                  },
                  {
                    type: 'email',
                    message: intl.formatMsg(loginMessages.emailValidationError)
                  }
                ]}
              >
                <Input
                  placeholder={intl.formatMsg(loginMessages.emailPlaceholder)}
                  prefix={<UserOutlined />}
                  inputMode={'email'}
                  autoComplete={'username'}
                />
              </Form.Item>
              <Form.Item>
                <Button type='primary' htmlType='submit' style={{ width: '100%' }}>
                  {intl.formatMsg(loginMessages.resetPassword)}
                </Button>
              </Form.Item>
              <Form.Item noStyle={true}>
                <Link to={'/login'}>{intl.formatMsg(loginMessages.backToLogin)}</Link>
              </Form.Item>
            </>
          )}
          {isAuthenticated && <div>{intl.formatMsg(loginMessages.alreadyAuthenticated)}</div>}
        </Form>
      </motion.div>
    </AnimatePresence>
  );
};

export default LostPasswordPage;
