import { Reorder } from 'framer-motion';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ProductCategory, RetailerProductListItemFullFragment } from 'generated/types';
import styled from 'styled-components';
import { Empty, Spin } from 'antd';
import { useIntl } from 'react-intl';
import {
  productCategoryMessages,
  retailerProductMessages
} from 'components/retailer/RetailerProductCard/retailerProductMessages';
import { useDebouncedCallback } from 'use-debounce';
import RetailerProductSortableListItem from 'components/retailer/RetailerProductCard/RetailerProductSortableListItem';

interface Props {
  category: ProductCategory;
  data?: RetailerProductListItemFullFragment[];
  // onReorder?: (newOrder: RetailerProductListItemFragment[]) => void;
  onMoveProduct?: (
    movedProduct: RetailerProductListItemFullFragment,
    prevProduct: RetailerProductListItemFullFragment | undefined,
    nextProduct: RetailerProductListItemFullFragment | undefined
  ) => void;
  loading?: boolean;
}

const StyledReorderGroup = styled(Reorder.Group<RetailerProductListItemFullFragment>)`
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
`;

const RetailerProductSortableList: React.FC<Props> = ({
  category,
  loading,
  data,
  onMoveProduct
}) => {
  const intl = useIntl();
  const myRef = useRef(null);

  // This is our local (fast) state
  const [items, setItems] = useState<RetailerProductListItemFullFragment[]>(data || []);

  const handleSetItems = useCallback((items: RetailerProductListItemFullFragment[]) => {
    setItems(items);
  }, []);

  const handleSetItemsDebounced = useDebouncedCallback(
    (newItems: RetailerProductListItemFullFragment[]) => {
      const oldArrayProductIds = data?.map((c) => c.product.productId);
      const newArrayProductIds = newItems.map((c) => c.product.productId);

      if (!oldArrayProductIds) return;

      let idx = 0;
      const len = oldArrayProductIds.length;
      while (
        (oldArrayProductIds[idx] === newArrayProductIds[idx] ||
          oldArrayProductIds[idx] === newArrayProductIds[idx + 1]) &&
        idx < len
      ) {
        idx++;
      }

      const movedProductId = oldArrayProductIds[idx];
      const movedNewIndex = newArrayProductIds.indexOf(movedProductId);
      const movedProduct = newItems[movedNewIndex];
      const prevProduct = newItems[movedNewIndex - 1] || undefined;
      const nextProduct = newItems[movedNewIndex + 1] || undefined;
      onMoveProduct?.(movedProduct, prevProduct, nextProduct);
    },
    300
  );

  useEffect(() => {
    setItems(data || []);
  }, [data]);

  const isEmpty = !loading && data?.length === 0;

  if (loading) {
    return <Spin />;
  }

  if (isEmpty) {
    return (
      <Empty
        description={intl.formatMessage(retailerProductMessages.noProducts, {
          category: intl.formatMessage(productCategoryMessages[category])
        })}
        style={{ marginBottom: '16px' }}
      />
    );
  }

  return (
    <StyledReorderGroup
      ref={myRef}
      axis='y'
      onReorder={(items: RetailerProductListItemFullFragment[]) => {
        handleSetItems(items);
        handleSetItemsDebounced(items);
      }}
      values={items}
    >
      {items.map((item) => (
        <RetailerProductSortableListItem key={item.id} item={item} myRef={myRef} />
      ))}
    </StyledReorderGroup>
  );
};

export default RetailerProductSortableList;
