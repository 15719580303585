import React, { ReactNode, useMemo } from 'react';
import { getFriendlyApolloErrorMessages } from 'graphql/apollo/apolloErrorUtil.ts';
import { Button, Flex, Result, Space } from 'antd';
import ErrorIllustration from 'components/illustrations/ErrorIllustration.tsx';
import commonMessages from 'components/i18n/commonMessages.ts';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { isApolloError } from '@apollo/client';

interface Props {
  title?: string;
  error: Error;
  action?: ReactNode;
}

const ErrorResult: React.FC<Props> = ({ error, title, action }) => {
  const friendlyErrorMessages = useMemo(() => {
    if (isApolloError(error)) {
      return getFriendlyApolloErrorMessages(error);
    }
    return [error.message];
  }, [error]);

  const intl = useConnectIntl();

  return (
    <Flex justify={'center'} align={'center'} style={{ width: '100%' }}>
      <Result
        icon={<ErrorIllustration width={300} />}
        extra={
          <Space direction={'vertical'} align={'center'}>
            {action || (
              <Button onClick={() => window.location.reload()} size={'small'} type={'link'}>
                {intl.formatMsg(commonMessages.reload)}
              </Button>
            )}
          </Space>
        }
        title={
          title ||
          intl.formatMsg({
            id: 'error_result.title',
            defaultMessage: 'An error occurred'
          })
        }
        subTitle={
          <Space direction={'vertical'}>
            {friendlyErrorMessages?.map((line) => <div key={line}>{line}</div>)}
          </Space>
        }
      />
    </Flex>
  );
};

export default ErrorResult;
