import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { Breadcrumb, Space, Typography } from 'antd';
import styled from 'styled-components';
import PageHeaderRow from 'components/lib/page/rows/PageHeaderRow';
import { useNavigate, useParams } from 'react-router-dom';
import Link from 'components/lib/Link/Link';
import { GlobalOutlined } from '@ant-design/icons';
import commonMessages from 'components/i18n/commonMessages';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo';
import PageTitleRow from 'components/lib/page/rows/PageTitleRow.tsx';
import PageTabs from 'components/lib/page/PageTabs/PageTabs.tsx';
const { Title } = Typography;

const MyRow = styled(PageHeaderRow)`
  flex: 0 0 46px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
`;

type NotificationsTabs = 'all' | 'notifications' | 'updates';

/***
 * Note: this is only reachable on mobile. On desktop, we'll just use a popup
 * @constructor
 */
const NotificationsPage: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { screenMap: breakpoint } = useScreenInfo();
  const handleTabChange = useCallback(
    (tabKey: string) => {
      navigate(`/notifications/${tabKey}`);
    },
    [navigate]
  );
  const params = useParams<{ tab?: NotificationsTabs }>();
  const activeTab = params.tab || 'all';

  const title = intl.formatMessage({
    id: 'notifications_page.page_title',
    defaultMessage: 'Notifications'
  });

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <MyRow>
        {breakpoint.md && (
          <Breadcrumb
            items={[
              {
                key: 'root',
                title: (
                  <Link to={'/'}>
                    <Space>
                      <GlobalOutlined />
                      <div>{intl.formatMessage(commonMessages.global)}</div>
                    </Space>
                  </Link>
                )
              },
              {
                key: 'notifications',
                title: <Link to={'/notifications'}>{title}</Link>
              }
            ]}
          />
        )}
      </MyRow>
      <PageTitleRow>
        <Title level={3} style={{ marginBottom: 0 }}>
          {title}
        </Title>
      </PageTitleRow>
      <PageTabs
        defaultActiveKey={'language'}
        activeKey={activeTab}
        onChange={handleTabChange}
        items={[
          {
            key: 'all',
            label: intl.formatMessage({
              id: 'page_notifications.all',
              defaultMessage: 'All'
            }),
            active: activeTab === 'all'
          },
          {
            key: 'notifications',
            label: intl.formatMessage({
              id: 'page_notifications.notifications',
              defaultMessage: 'Notifications'
            }),
            active: activeTab === 'notifications'
          },
          {
            key: 'updates',
            label: intl.formatMessage({
              id: 'page_notifications.updates',
              defaultMessage: 'Updates'
            }),
            active: activeTab === 'updates'
          }
        ]}
      />
    </>
  );
};

export default NotificationsPage;
