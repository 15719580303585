import React from 'react';
import { gql } from '@apollo/client/core';
import {
  DataTransferMessageFullFragmentDoc,
  DataTransferMessageListItemFragmentDoc,
  useGetDataTransferMessageQuery
} from 'generated/types';
import { Spin, Typography } from 'antd';
import styled from 'styled-components';

interface Props {
  dataTransferMessageId: string;
}

gql`
  fragment DataTransferMessageFull on DataTransferMessage {
    ...DataTransferMessageListItem
    message
  }
  ${DataTransferMessageListItemFragmentDoc}
`;

gql`
  query GetDataTransferMessage($id: ID!) {
    dataTransferMessage(dataTransferMessageId: $id) {
      ...DataTransferMessageFull
    }
  }
  ${DataTransferMessageFullFragmentDoc}
`;

const PrettyJson = styled.pre`
  font-family: monospace;
  font-size: 0.85em;
`;

function isXml(str: string) {
  return /^\s*<[\s\S]*>/.test(str);
}

function prettifyXml(sourceXml: string) {
  if (!isXml(sourceXml)) throw new Error('Not XML');

  const xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
  const xsltDoc = new DOMParser().parseFromString(
    [
      // describes how we want to modify the XML - indent everything
      '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
      '  <xsl:strip-space elements="*"/>',
      '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
      '    <xsl:value-of select="normalize-space(.)"/>',
      '  </xsl:template>',
      '  <xsl:template match="node()|@*">',
      '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
      '  </xsl:template>',
      '  <xsl:output indent="yes"/>',
      '</xsl:stylesheet>'
    ].join('\n'),
    'application/xml'
  );

  const xsltProcessor = new XSLTProcessor();
  xsltProcessor.importStylesheet(xsltDoc);
  const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
  return new XMLSerializer().serializeToString(resultDoc);
}

const formatDataTransferMessage = (dataTransferMessage: string) => {
  try {
    return JSON.stringify(JSON.parse(dataTransferMessage), null, 2);
  } catch {
    try {
      return prettifyXml(dataTransferMessage);
    } catch {
      return dataTransferMessage;
    }
  }
};

const DataTransferMessage: React.FC<Props> = (props) => {
  const { dataTransferMessageId } = props;
  const { data, loading, error } = useGetDataTransferMessageQuery({
    variables: {
      id: dataTransferMessageId
    }
  });

  return (
    <div>
      <Typography.Title
        level={5}
        copyable={{
          text: data?.dataTransferMessage.message
        }}
      >
        Message
      </Typography.Title>
      {loading && <Spin />}
      {data && (
        <PrettyJson>{formatDataTransferMessage(data.dataTransferMessage.message)}</PrettyJson>
      )}
      {error && <Typography.Text type={'danger'}>{error.message}</Typography.Text>}
    </div>
  );
};

export default DataTransferMessage;
