import React from 'react';
import { useLocation } from 'react-router-dom';
import { Result } from 'antd';
import { defineMessages } from 'react-intl';
import Link from 'components/lib/Link/Link';
import styled from 'styled-components';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import NotFoundIllustration from 'components/illustrations/NotFoundIllustration.tsx';
import Page from 'components/lib/page/Page/Page.tsx';

const messages = defineMessages({
  title: {
    id: 'not_found_page.title',
    defaultMessage: 'Not found'
  },
  subtitle: {
    id: 'not_found_page.subtitle',
    defaultMessage: 'Sorry, the url {url} does not exist.'
  },
  mainPageLink: {
    id: 'not_found_page.main_page_link',
    defaultMessage: 'Go to main page'
  }
});

const ResultContainer = styled.div`
  flex: 1 1 auto;
  padding-top: 128px;
`;

interface Props {
  title?: string;
  subtitle?: React.ReactNode;
}

const NotFoundPage: React.FC<Props> = ({ title, subtitle }) => {
  const location = useLocation();
  const intl = useConnectIntl();

  return (
    <Page
      title={{
        pageTitle: title || intl.formatMsg(messages.title)
      }}
    >
      <ResultContainer>
        <Result
          icon={<NotFoundIllustration width={300} />}
          extra={<Link to={'/'}>{intl.formatMsg(messages.mainPageLink)}</Link>}
          subTitle={
            subtitle ||
            intl.formatMsg(messages.subtitle, {
              url: location.pathname
            })
          }
          title={title || intl.formatMsg(messages.title)}
        />
      </ResultContainer>
    </Page>
  );
};

export default NotFoundPage;
