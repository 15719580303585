import React from 'react';
import { Form, Space, Switch, Typography } from 'antd';
const { Text } = Typography;
import Card from 'components/lib/Card/Card';
import { gql } from '@apollo/client/core';
import {
  OperatorInspectionTypes,
  RetailerPvmSettingsFragmentDoc,
  useGetRetailerPvmSettingsQuery,
  useUpdateRetailerPvmSettingsMutation
} from 'generated/types';
import retailerMessages from 'components/retailer/retailerMessages';
import { SelectProps } from 'antd/es/select';
import Select from 'components/lib/Select/Select';
import { useEnumSelectOptions } from 'components/lib/Select/useEnumSelectOptions';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import { useRetailerPermissions } from 'auth/RequireRetailerAccess.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import useRetailerDeployTools from 'components/retailer/useRetailerDeployTools.ts';
import useOnUnmount from 'hooks/useOnUnmount.ts';

interface Props {
  retailerId?: number;
}

gql`
  fragment RetailerPvmSettings on Retailer {
    id
    retailerId
    operatorInspection
    returnCommerce
    uniqueRetailerReferences
    cylinderRemoveCapMessageVisible
    webOverrideHmi
  }
`;

gql`
  query GetRetailerPvmSettings($retailerId: Int!) {
    retailer(retailerId: $retailerId) {
      ...RetailerPvmSettings
    }
  }
  ${RetailerPvmSettingsFragmentDoc}
`;

gql`
  mutation UpdateRetailerPvmSettings($input: UpdateRetailerPvmSettingsInput!) {
    updateRetailerPvmSettings(input: $input) {
      ...RetailerPvmSettings
    }
  }
  ${RetailerPvmSettingsFragmentDoc}
`;

function TypedOperatorInspectionSelect<T extends string>({ children, ...props }: SelectProps<T>) {
  return <Select<T> {...props}>{children}</Select>;
}

const RetailerPvmSettingsCard: React.FC<Props> = ({ retailerId }) => {
  const operatorInspectionOptions = useEnumSelectOptions({
    enumObject: OperatorInspectionTypes,
    keyPrefix: 'operator_inspection',
    customLabelMessages: {
      DISABLED: {
        id: 'operator_inspection.disabled',
        defaultMessage: 'Disabled'
      },
      ENABLED: {
        id: 'operator_inspection.enabled',
        defaultMessage: 'Enabled'
      },
      ENFORCED: {
        id: 'operator_inspection.enforced',
        defaultMessage: 'Enforced'
      }
    }
  });

  const { canEditRetailer } = useRetailerPermissions();

  const { data, loading } = useGetRetailerPvmSettingsQuery({
    variables: {
      retailerId: retailerId || -1
    },
    skip: retailerId === undefined,
    fetchPolicy: 'cache-and-network'
  });

  const intl = useConnectIntl();
  const { flushPendingRetailerChanges, addPendingRetailerChange } = useRetailerDeployTools();
  useOnUnmount(() => {
    flushPendingRetailerChanges();
  });

  const [updatePvmSettings, { loading: saving }] = useUpdateRetailerPvmSettingsMutation();

  const handleChangeCap: SwitchChangeEventHandler = async (checked) => {
    if (!data || !retailerId) return;

    await updatePvmSettings({
      variables: {
        input: {
          retailerId: data.retailer.retailerId,
          cylinderRemoveCapMessageVisible: checked,
          uniqueRetailerReferences: data.retailer.uniqueRetailerReferences,
          operatorInspection: data.retailer.operatorInspection,
          returnCommerce: data.retailer.returnCommerce,
          webOverrideHmi: data.retailer.webOverrideHmi
        }
      }
    });
    addPendingRetailerChange(retailerId);
  };

  const handleChangeReturnCommerce = async (checked: boolean) => {
    if (!data || !retailerId) return;

    await updatePvmSettings({
      variables: {
        input: {
          retailerId: data.retailer.retailerId,
          cylinderRemoveCapMessageVisible: data.retailer.cylinderRemoveCapMessageVisible,
          uniqueRetailerReferences: data.retailer.uniqueRetailerReferences,
          operatorInspection: data.retailer.operatorInspection,
          returnCommerce: checked,
          webOverrideHmi: data.retailer.webOverrideHmi
        }
      }
    });
    addPendingRetailerChange(retailerId);
  };

  const handleChangeUniqueRetailerReferences = async (checked: boolean) => {
    if (!data || !retailerId) return;

    await updatePvmSettings({
      variables: {
        input: {
          retailerId: data.retailer.retailerId,
          cylinderRemoveCapMessageVisible: data.retailer.cylinderRemoveCapMessageVisible,
          uniqueRetailerReferences: checked,
          operatorInspection: data.retailer.operatorInspection,
          returnCommerce: data.retailer.returnCommerce,
          webOverrideHmi: data.retailer.webOverrideHmi
        }
      }
    });
    addPendingRetailerChange(retailerId);
  };

  const handleChangeOperatorInspection = async (value: OperatorInspectionTypes) => {
    if (!data || !retailerId) return;

    await updatePvmSettings({
      variables: {
        input: {
          retailerId: data.retailer.retailerId,
          cylinderRemoveCapMessageVisible: data.retailer.cylinderRemoveCapMessageVisible,
          uniqueRetailerReferences: data.retailer.uniqueRetailerReferences,
          operatorInspection: value,
          returnCommerce: data.retailer.returnCommerce,
          webOverrideHmi: data.retailer.webOverrideHmi
        }
      }
    });
    addPendingRetailerChange(retailerId);
  };

  const handleChangeWebOverrideHmi = async (value: string) => {
    if (!data || !retailerId) return;

    await updatePvmSettings({
      variables: {
        input: {
          retailerId: data.retailer.retailerId,
          cylinderRemoveCapMessageVisible: data.retailer.cylinderRemoveCapMessageVisible,
          uniqueRetailerReferences: data.retailer.uniqueRetailerReferences,
          operatorInspection: data.retailer.operatorInspection,
          returnCommerce: data.retailer.returnCommerce,
          webOverrideHmi: value
        }
      }
    });
    addPendingRetailerChange(retailerId);
  };

  return (
    <Card title={intl.formatMsg(retailerMessages.pvmSettings)}>
      <Space direction={'vertical'} style={{ width: '100%' }}>
        {/*<Text type={'secondary'}>PVM settings</Text>*/}
        <Form layout={'vertical'} disabled={!canEditRetailer}>
          <Form.Item label={intl.formatMessage(retailerMessages.cylinderRemoveCapMessage)}>
            <Switch
              checked={data?.retailer.cylinderRemoveCapMessageVisible || undefined}
              onChange={handleChangeCap}
              loading={saving || loading}
            />
          </Form.Item>
          <Form.Item label={intl.formatMessage(retailerMessages.returnCommerce)}>
            <Switch
              checked={data?.retailer.returnCommerce || undefined}
              loading={saving || loading}
              onChange={handleChangeReturnCommerce}
            />
          </Form.Item>
          <Form.Item label={intl.formatMessage(retailerMessages.uniqueRetailerReferences)}>
            <Switch
              checked={data?.retailer.uniqueRetailerReferences || undefined}
              loading={saving || loading}
              onChange={handleChangeUniqueRetailerReferences}
            />
          </Form.Item>
          <Form.Item label={intl.formatMessage(retailerMessages.operatorInspection)}>
            <TypedOperatorInspectionSelect
              options={operatorInspectionOptions}
              value={data?.retailer.operatorInspection}
              onChange={handleChangeOperatorInspection}
              allowClear={true}
              placeholder={intl.formatMessage(retailerMessages.operatorInspectionPlaceholder)}
            />
          </Form.Item>
          <Form.Item label={intl.formatMessage(retailerMessages.webOverrideHmi)}>
            {/*{data && !data.retailer.webOverrideHmi && <Text type={'secondary'}>Default</Text>}*/}
            <Text
              editable={
                canEditRetailer
                  ? {
                      triggerType: ['text', 'icon'],
                      onChange: handleChangeWebOverrideHmi
                    }
                  : false
              }
            >
              {data?.retailer.webOverrideHmi}
            </Text>
          </Form.Item>
        </Form>
      </Space>
    </Card>
  );
};

export default RetailerPvmSettingsCard;
