import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { appSliceReducer } from 'redux/appSlice';
import { authSliceReducer } from 'auth/authSlice';
import { i18nSliceReducer } from 'i18n/i18nSlice';
import { deploySliceReducer } from 'redux/deploySlice';
import * as Sentry from '@sentry/react';
import { machineNavSliceReducer } from 'redux/machineNavSlice.ts';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

export const store = configureStore({
  devTools: true,
  reducer: {
    auth: authSliceReducer,
    app: appSliceReducer,
    i18n: i18nSliceReducer,
    deploy: deploySliceReducer,
    machineNav: machineNavSliceReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
