import React from 'react';
import { useIntl } from 'react-intl';
import { Card } from 'antd';
import CreateRetailerForm from 'components/retailer/CreateRetailerForm/CreateRetailerForm';
import PageContentRow from 'components/lib/page/rows/PageContentRow.tsx';
import Page from 'components/lib/page/Page/Page.tsx';

const CreateRetailerPage: React.FC = () => {
  const intl = useIntl();

  const createRetailerTitle = intl.formatMessage({
    id: 'create_retailer_page.title',
    defaultMessage: 'Add retailer'
  });

  return (
    <Page
      title={{
        pageTitle: createRetailerTitle
      }}
      header={createRetailerTitle}
      showBreadcrumbs={true}
      showBottomBorder={true}
    >
      <PageContentRow style={{ paddingTop: '16px', paddingBottom: '64px' }}>
        <Card>
          <CreateRetailerForm />
        </Card>
      </PageContentRow>
    </Page>
  );
};

export default CreateRetailerPage;
