import { LoginResponse } from 'api/api';
import Cookies from 'js-cookie';
import { getLocalStorageItem } from 'common/localStorage';
import { differenceInSeconds, isAfter, parseJSON } from 'date-fns';
import { KEY_LOGIN, KEY_REFRESH_TOKEN_FLAG } from 'auth/constants';

interface CheckResult {
  localData?: LoginResponse;
  hasRefreshFlagCookie: boolean;
  hasAccessToken: boolean;
  hasValidAccessToken: boolean;
  willExpireSoon: boolean;
}

/***
 * Use this to probe for information
 * Check if we have an access token stored in local storage
 * Check if we have a refresh token available
 */
export const checkAccessToken = (): CheckResult => {
  const hasRefreshCookie = !!Cookies.get(KEY_REFRESH_TOKEN_FLAG);
  const localData = getLocalStorageItem<LoginResponse>(KEY_LOGIN);
  const token = localData?.accessToken;

  if (!token) {
    return {
      hasAccessToken: false,
      localData,
      hasValidAccessToken: false,
      hasRefreshFlagCookie: hasRefreshCookie,
      willExpireSoon: false
    };
  }

  const exp = parseJSON(token.expires);
  const today = new Date();
  const isAccessTokenExpired = isAfter(today, exp);
  const isAccessTokenValid = !isAccessTokenExpired;
  const diff = differenceInSeconds(exp, new Date());
  const willAccessTokenExpireSoon = diff < 180; // 3 minutes OK?

  return {
    hasAccessToken: true,
    hasValidAccessToken: isAccessTokenValid,
    localData,
    hasRefreshFlagCookie: hasRefreshCookie,
    willExpireSoon: willAccessTokenExpireSoon
  };
};

export default checkAccessToken;
