import { Level } from 'layouts/matrix/level';
import { matchPath, useLocation } from 'react-router-dom';
import { isRootTab } from 'layouts/matrix/matrix';

export function getMatrixLevel(pathname: string): Level | undefined {
  if (matchPath({ path: '/', end: true }, pathname)) {
    return 'root';
  }

  if (matchPath({ path: '/ticket/', end: false }, pathname)) {
    return 'ticket';
  }
  if (matchPath({ path: '/machine/', end: false }, pathname)) {
    return 'machine';
  }
  if (matchPath({ path: '/retailer/', end: false }, pathname)) {
    return 'retailer';
  }
  if (matchPath({ path: '/prediction/', end: false }, pathname)) {
    return 'prediction';
  }

  const matchTabRoot = matchPath({ path: '/:tab', end: false }, pathname);
  if (matchTabRoot?.params.tab !== undefined) {
    if (isRootTab(matchTabRoot.params.tab)) {
      return 'root';
    }
  }

  return undefined;
}

export function useMatrixLevel() {
  const location = useLocation();
  return getMatrixLevel(location.pathname);
}

const levelMap: { [key in Level]: number } = {
  root: 0,
  retailer: 1,
  machine: 2,
  ticket: 3,
  prediction: 3
};

export function useMatrixLevelNumber() {
  const level = useMatrixLevel();
  return level ? levelMap[level] : undefined;
}
