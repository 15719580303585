import React, { useCallback, useMemo, useState } from 'react';
import {
  useAddTicketTagMutation,
  useGetTicketTagsQuery,
  useRemoveTicketTagMutation
} from 'generated/types';
import { gql } from '@apollo/client/core';
import TicketTagsSelect from 'components/ticket/TicketTagsSelect/TicketTagsSelect';
import { SelectProps } from 'antd/es/select';

gql`
  query GetTicketTags($ticketId: Int!) {
    ticket(ticketId: $ticketId) {
      id
      ticketId
      tags
    }
  }
`;

gql`
  mutation addTicketTag($input: AddTicketTagInput!) {
    addTicketTag(input: $input) {
      ticket {
        id
        ticketId
        tags
      }
    }
  }
`;

gql`
  mutation removeTicketTag($input: RemoveTicketTagInput!) {
    removeTicketTag(input: $input) {
      ticket {
        id
        ticketId
        tags
      }
    }
  }
`;

interface Props {
  ticketId?: number;
}

type OtherSelectProps = Omit<
  SelectProps<string[]>,
  | 'options'
  | 'mode'
  | 'value'
  | 'onInputKeyDown'
  | 'onSelect'
  | 'onDeselect'
  | 'searchValue'
  | 'onSearch'
  | 'loading'
>;

const TicketTagsSelectConnected: React.FC<Props & OtherSelectProps> = (props) => {
  const { ticketId, ...rest } = props;

  const { data, loading, refetch } = useGetTicketTagsQuery({
    variables: ticketId
      ? {
          ticketId
        }
      : undefined,
    skip: !ticketId,
    notifyOnNetworkStatusChange: false // don't update loading when refetching (?)
  });

  const selectedTags = useMemo(() => {
    return data?.ticket.tags || [];
  }, [data?.ticket.tags]);

  const [addTag] = useAddTicketTagMutation({
    notifyOnNetworkStatusChange: true
  });

  const [removeTag] = useRemoveTicketTagMutation({
    notifyOnNetworkStatusChange: true
  });

  const handleSelect = useCallback(
    async (tag: string) => {
      if (!ticketId) {
        return;
      }

      setSearchText('');

      await addTag({
        variables: {
          input: {
            ticketId,
            tag
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          addTicketTag: {
            __typename: 'AddTicketTagPayload',
            ticket: {
              id: data?.ticket.id || 'na',
              ticketId: data?.ticket.ticketId || 0,
              tags: [...selectedTags, tag],
              __typename: 'Ticket'
            }
          }
        }
      });
    },
    [ticketId, addTag, selectedTags, data]
  );

  const handleDeselect = useCallback(
    async (tag: string) => {
      if (!ticketId) {
        return;
      }
      const optimisticTags = selectedTags.filter((f) => f.toLowerCase() !== tag.toLowerCase());

      await removeTag({
        variables: {
          input: {
            ticketId,
            tag
          }
        },
        optimisticResponse: data
          ? {
              __typename: 'Mutation',
              removeTicketTag: {
                __typename: 'RemoveTicketTagPayload',
                ticket: {
                  __typename: 'Ticket',
                  tags: optimisticTags,
                  id: data.ticket.id,
                  ticketId: data.ticket.ticketId
                }
              }
            }
          : undefined
      });

      // NOTE: If we remove the last assigned tag, it's removed from the allTags query...
      // Now we re-fetch after removing a tag, just in case.
      // That will cause the tag to suddenly disappear from the dropdown, but it's better than
      // outdated cache?
      // TODO: Are there other ways to specify that allTags must be invalidated after removeTag?
      await refetch();
    },
    [ticketId, removeTag, selectedTags, refetch, data]
  );

  const handleInputKeyDown = useCallback<
    React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
  >((e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      // It's enough to clear this, select event is already dispatched by antd component
      setSearchText('');
    }
  }, []);

  const [searchText, setSearchText] = useState('');

  const isEmpty = ticketId === undefined;
  const showSpinner = loading || isEmpty; // isAdding || isRemoving // flickering when adding and removing

  return (
    <TicketTagsSelect
      value={selectedTags}
      onInputKeyDown={handleInputKeyDown}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      searchValue={searchText}
      onSearch={(value) => setSearchText(value)}
      loading={showSpinner}
      {...rest}
    />
  );
};

export default TicketTagsSelectConnected;
