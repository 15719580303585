import Cookies from 'js-cookie';
import { parseISO } from 'date-fns';
import { KEY_REFRESH_TOKEN_FLAG } from 'auth/constants';

/***
 * When logging in or refreshing we set a client per session token with the same
 * expiration as our real refresh token cookie (which is not available from javascript)
 * @param source
 * @param remember
 * @param expiresRaw
 */
export const setRefreshFlagCookie = (source: string, remember: boolean, expiresRaw?: string) => {
  Cookies.set(KEY_REFRESH_TOKEN_FLAG, source, {
    expires: expiresRaw ? parseISO(expiresRaw) : undefined
  });
  console.log(
    `🍪 set refresh-flag-cookie on ${source} remember=${remember} expires=${
      expiresRaw || 'PerSession'
    }`
  );
};

export const clearRefreshFlagCookie = () => {
  Cookies.remove(KEY_REFRESH_TOKEN_FLAG, {
    expires: new Date()
  });
};
