import { Message } from 'typescript-react-intl';

type Keys =
  | 'yes'
  | 'no'
  | 'delete'
  | 'confirm_delete_item'
  | 'confirm'
  | 'discard'
  | 'load_more'
  | 'loading'
  | 'empty'
  | 'saving'
  | 'save'
  | 'cancel'
  | 'on'
  | 'off'
  | 'actions'
  | 'settings'
  | 'navigate'
  | 'global'
  | 'warning'
  | 'edit'
  | 'true'
  | 'false'
  | 'mainPageLink'
  | 'reload'
  | 'retry'
  | 'inactive'
  | 'reset'
  | 'search';

type CommonMessages = { [key in Keys]: Message };

const commonMessages: CommonMessages = {
  yes: {
    id: 'common.yes',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'common.no',
    defaultMessage: 'No'
  },
  true: {
    id: 'common.true',
    defaultMessage: 'True'
  },
  false: {
    id: 'common.false',
    defaultMessage: 'False'
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Delete'
  },
  confirm_delete_item: {
    id: 'common.confirm_delete_item',
    defaultMessage: 'Are you sure you want to delete this item?'
  },
  confirm: {
    id: 'common.confirm',
    defaultMessage: 'Confirm'
  },
  load_more: {
    id: 'common.load_more',
    defaultMessage: 'Load more'
  },
  loading: {
    id: 'common.loading',
    defaultMessage: 'Loading'
  },
  off: {
    id: 'common.off',
    defaultMessage: 'Off'
  },
  on: {
    id: 'common.on',
    defaultMessage: 'On'
  },
  saving: {
    id: 'common.saving',
    defaultMessage: 'Saving'
  },
  save: {
    id: 'common.save',
    defaultMessage: 'Save'
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'Cancel'
  },
  edit: {
    id: 'common.edit',
    defaultMessage: 'Edit'
  },
  actions: {
    id: 'common.actions',
    defaultMessage: 'Actions'
  },
  settings: {
    id: 'common.settings',
    defaultMessage: 'Settings'
  },
  navigate: {
    id: 'common.navigate',
    defaultMessage: 'Navigate'
  },
  global: {
    id: 'common.global',
    defaultMessage: 'Global'
  },
  empty: {
    id: 'common.empty',
    defaultMessage: 'Empty'
  },
  warning: {
    id: 'common.warning',
    defaultMessage: 'Warning'
  },
  search: {
    id: 'common.search',
    defaultMessage: 'Search'
  },
  mainPageLink: {
    id: 'common.mainPageLink',
    defaultMessage: 'Main page'
  },
  reload: {
    id: 'common.reload',
    defaultMessage: 'Reload'
  },
  inactive: {
    id: 'common.inactive',
    defaultMessage: 'Inactive'
  },
  reset: {
    id: 'common.reset',
    defaultMessage: 'Reset'
  },
  discard: {
    id: 'common.discard',
    defaultMessage: 'Discard'
  },
  retry: {
    id: 'common.retry',
    defaultMessage: 'Retry'
  }
};

export default commonMessages;
