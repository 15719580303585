import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Collapse, Dropdown, Flex, Space, Tooltip, Typography } from 'antd';
import { parseISO } from 'date-fns';
import useDateFormatTools from 'i18n/useDateFormatTools.ts';
import {
  ClearOutlined,
  EditOutlined,
  HistoryOutlined,
  KeyOutlined,
  MoreOutlined,
  StopOutlined,
  WarningOutlined
} from '@ant-design/icons';
import MachinePropertyHistory from 'components/machine/properties/MachinePropertyList/MachinePropertyHistory.tsx';
import machinePropertyMessages from 'components/machine/properties/MachinePropertyList/machinePropertyMessages.ts';
import useMessageApi from 'components/global/useMessageApi.ts';
import TextValue from 'components/machine/properties/MachinePropertyList/TextValue.tsx';
import { MachinePropertyValueEditor } from 'components/machine/properties/MachinePropertyList/machinePropertyValueEditor.ts';
import JsonValue from 'components/machine/properties/MachinePropertyList/JsonValue.tsx';
import NumberValue from 'components/machine/properties/MachinePropertyList/NumberValue.tsx';
import BooleanValue, {
  BooleanMachinePropertyValueEditor
} from 'components/machine/properties/MachinePropertyList/BooleanValue.tsx';
import SelectorValue from 'components/machine/properties/MachinePropertyList/SelectorValue.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { useMachinePermissions } from 'auth/RequireMachineAccess.tsx';
import {
  MachinePropertyWithValueFragment,
  EntityPropertyType,
  useClearMachinePropertyValueMutation
} from 'generated/types.tsx';

const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  max-width: 100%;
`;

const Stack = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const HistoryCollapse = styled(Collapse)`
  && {
    .ant-collapse-content {
      background: ${(props) => props.theme.ant.colorBgElevated};
    }
    .ant-collapse-item:last-child > .ant-collapse-content {
      border-radius: 4px;
    }
  }
`;

const HistoryCollapsePanel = styled(Collapse.Panel)`
  &&& {
    .ant-collapse-header {
      padding: 4px 0;
      padding-inline-start: 0;
      overflow: hidden;
    }
    .ant-collapse-header-text {
      overflow: hidden;
    }
  }
`;

const HistoryHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  color: ${(props) => props.theme.ant.colorTextSecondary};
  overflow: hidden;
`;

const HistoryDate = styled.div`
  // dont wrap text
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 0 0 auto;
`;

const HistoryName = styled.div`
  flex: 1 1 auto;

  // dont wrap text, show ellipsis when overflow
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Props {
  property: MachinePropertyWithValueFragment;
}

const MachinePropertyListItem: React.FC<Props> = ({ property }) => {
  const { formatDate } = useDateFormatTools();
  const intl = useConnectIntl();
  const messageApi = useMessageApi();

  const hasHistory = property.value !== null && property.value !== undefined;
  let hasValue = false;

  if (property.value) {
    switch (property.value.__typename) {
      case 'TextPropertyValue':
        hasValue = property.value.textValue !== null && property.value.textValue !== undefined;
        break;
      case 'JsonPropertyValue':
        hasValue = property.value.jsonValue !== null && property.value.jsonValue !== undefined;
        break;
      case 'NumberPropertyValue':
        hasValue = property.value.numberValue !== null && property.value.numberValue !== undefined;
        break;
      case 'BooleanPropertyValue':
        hasValue =
          property.value.booleanValue !== null && property.value.booleanValue !== undefined;
        break;
      case 'SelectorPropertyValue':
        hasValue =
          property.value.selectorValue !== null && property.value.selectorValue !== undefined;
        break;
      case 'ColorPropertyValue':
        hasValue = property.value.colorValue !== null && property.value.colorValue !== undefined;
        break;
      case 'TextLinesPropertyValue':
        hasValue =
          property.value.textLinesValue !== null && property.value.textLinesValue !== undefined;
        break;
      default:
        break;
    }
  }

  const [isHistoryExpanded, setIsHistoryExpanded] = useState(false);

  const [clearMachineProperty] = useClearMachinePropertyValueMutation();
  const { canEditDetails } = useMachinePermissions();

  const textRef = useRef<MachinePropertyValueEditor>(null);
  const numberRef = useRef<MachinePropertyValueEditor>(null);
  const booleanRef = useRef<BooleanMachinePropertyValueEditor>(null);
  const selectorRef = useRef<MachinePropertyValueEditor>(null);

  const handleClearMachineProperty = useCallback(async () => {
    try {
      await clearMachineProperty({
        variables: {
          input: {
            key: property.key,
            machineId: property.machineId,
            serialNo: property.machineSerialNo
          }
        }
      });
    } catch (err) {
      messageApi.error({
        content: 'Failed to clear machine property'
      });
    }
  }, [
    clearMachineProperty,
    property.key,
    property.machineSerialNo,
    property.machineId,
    messageApi
  ]);

  const handleEnableMachineProperty = useCallback(async () => {
    try {
      switch (property.dataType) {
        case EntityPropertyType.Boolean:
          if (booleanRef.current) {
            booleanRef.current.enableAndSetToTrue();
          } else {
            messageApi.warning({
              content: 'Failed to enable boolean property'
            });
          }
          break;
        case EntityPropertyType.Number:
          if (numberRef.current) {
            numberRef.current.enableEditMode();
          } else {
            messageApi.warning({ content: 'Failed to enable number property' });
          }
          break;
        case EntityPropertyType.Text:
          if (textRef.current) {
            textRef.current.enableEditMode();
          } else {
            messageApi.warning({ content: 'Failed to enable text property' });
          }
          break;
        case EntityPropertyType.Json:
          messageApi.warning({
            content: 'Failed to enable json property, no editor yet'
          });
          break;
        case EntityPropertyType.Selector:
          if (selectorRef.current) {
            selectorRef.current.enableEditMode();
          }
          break;
        default:
          messageApi.warning({ content: 'Failed to enable property' });
      }
    } catch (err) {
      messageApi.error({
        content: 'Failed to enabled machine property'
      });
    }
  }, [messageApi, property]);

  let valueEditor = null;

  switch (property.dataType) {
    case EntityPropertyType.Boolean:
      valueEditor = (
        <BooleanValue
          property={property}
          value={
            property.value?.__typename === 'BooleanPropertyValue'
              ? property.value.booleanValue
              : null
          }
          ref={booleanRef}
        />
      );
      break;
    case EntityPropertyType.Number:
      valueEditor = (
        <NumberValue
          property={property}
          value={
            property.value?.__typename === 'NumberPropertyValue' ? property.value.numberValue : null
          }
          ref={numberRef}
        />
      );
      break;
    case EntityPropertyType.Text:
      valueEditor = (
        <TextValue
          property={property}
          value={
            property.value?.__typename === 'TextPropertyValue' ? property.value.textValue : null
          }
          ref={textRef}
        />
      );
      break;
    case EntityPropertyType.Selector:
      valueEditor = (
        <SelectorValue
          property={property}
          value={
            property.value?.__typename === 'SelectorPropertyValue'
              ? property.value.selectorValue
              : null
          }
          ref={selectorRef}
        />
      );
      break;
    case EntityPropertyType.Json:
      valueEditor = (
        <JsonValue
          property={property}
          value={
            property.value?.__typename === 'JsonPropertyValue' ? property.value.jsonValue : null
          }
        />
      );
      break;
    default:
      break;
  }

  return (
    <Container key={property.key}>
      <Stack>
        <HeaderContainer>
          <Tooltip
            title={
              <Flex vertical={true} gap={2}>
                <Space size={'small'}>
                  <KeyOutlined />
                  {property.key}
                </Space>
                {property.machinePropertyUpdateType && (
                  <Space size={'small'}>
                    <WarningOutlined />
                    {intl.formatMsg({
                      id: property.machinePropertyUpdateType
                    })}
                  </Space>
                )}
                {property.readOnly && (
                  <Space size={'small'}>
                    <StopOutlined />
                    {intl.formatMsg(machinePropertyMessages.readonly)}
                  </Space>
                )}
              </Flex>
            }
          >
            <Typography.Text>
              {intl.formatMsg({
                id: property.key
              })}
            </Typography.Text>
          </Tooltip>
          <Dropdown
            trigger={['click']}
            arrow={true}
            disabled={!canEditDetails || property.readOnly}
            menu={{
              items: [
                {
                  key: 'reset',
                  label: intl.formatMsg(machinePropertyMessages.clearValue),
                  icon: <ClearOutlined />,
                  onClick: () => handleClearMachineProperty(),
                  disabled: !hasValue
                },
                {
                  key: 'enable',
                  label: intl.formatMsg(machinePropertyMessages.setValue),
                  // icon: <CheckOutlined />,
                  icon: <EditOutlined />,
                  onClick: () => handleEnableMachineProperty(),
                  disabled: hasValue
                }
              ]
            }}
          >
            <Button icon={<MoreOutlined />} type={'text'} />
          </Dropdown>
        </HeaderContainer>

        {valueEditor}

        {!hasHistory && (
          <HistoryHeaderContainer>
            <HistoryOutlined />
            <div>{intl.formatMsg(machinePropertyMessages.noHistory)}</div>
          </HistoryHeaderContainer>
        )}
        {hasHistory && (
          <HistoryCollapse
            ghost={true}
            activeKey={isHistoryExpanded ? 'main' : undefined}
            onChange={() => setIsHistoryExpanded(!isHistoryExpanded)}
          >
            <HistoryCollapsePanel
              showArrow={false}
              header={
                <HistoryHeaderContainer>
                  <HistoryOutlined />
                  {property.value?.changed && (
                    <HistoryDate>
                      {formatDate(parseISO(property.value.changed), {
                        representation: 'complete'
                      })}
                    </HistoryDate>
                  )}
                  {property.value?.changedByUser &&
                    property.value.changedByUser.__typename === 'User' && (
                      <HistoryName>
                        {' - '}
                        {property.value.changedByUser.name}
                      </HistoryName>
                    )}
                </HistoryHeaderContainer>
              }
              key={'main'}
            >
              {property && <MachinePropertyHistory property={property} />}
            </HistoryCollapsePanel>
          </HistoryCollapse>
        )}
      </Stack>
    </Container>
  );
};

export default MachinePropertyListItem;
