import React, { useCallback, useMemo } from 'react';
import { Select } from 'antd';
import { useGetAssignableUsersQuery, UserListItemFragment } from 'generated/types';
import { SelectProps } from 'antd/es/select';
import useTicketFilter from 'components/ticket/TicketFilter/useTicketFilter';
import useConnectIntl from 'i18n/useConnectIntl.ts';

type OtherSelectProps = Omit<SelectProps, 'value' | 'onSelect' | 'onChange' | 'options'>;

const TicketCreatedByFilterSelect: React.FC<OtherSelectProps> = (props) => {
  const intl = useConnectIntl();
  const { ticketFilter, setTicketFilter } = useTicketFilter();

  const { data, loading } = useGetAssignableUsersQuery({
    notifyOnNetworkStatusChange: true
  });

  const userDictionary = useMemo(() => {
    const dictionary: Record<string, UserListItemFragment> = {};
    data?.allTicketAssignableUsers.forEach((user) => {
      dictionary[user.userId] = user;
    });
    return dictionary;
  }, [data?.allTicketAssignableUsers]);

  const selectedValue = useMemo(() => {
    return [...(ticketFilter.createdByUserIds?.map((c) => c.toString()) ?? [])];
  }, [ticketFilter.createdByUserIds]);

  const handleSelectCreatedBy = useCallback(
    async (value: string) => {
      const nextSelectedValue = [...selectedValue, value];
      setTicketFilter({
        ...ticketFilter,
        createdByUserIds: nextSelectedValue.length > 0 ? nextSelectedValue : undefined
      });
    },
    [selectedValue, setTicketFilter, ticketFilter]
  );

  const handleDeselectCreatedBy = useCallback(
    async (value: string) => {
      const nextSelectedValue = selectedValue.filter((v) => v !== value);
      setTicketFilter({
        ...ticketFilter,
        createdByUserIds: nextSelectedValue.length > 0 ? nextSelectedValue : undefined
      });
    },
    [selectedValue, setTicketFilter, ticketFilter]
  );

  const handleClear = () => {
    setTicketFilter({
      ...ticketFilter,
      createdByUserIds: undefined
    });
  };

  const items = useMemo(() => {
    return data?.allTicketAssignableUsers.map((user) => {
      return {
        value: user.userId.toString(),
        label: user.name
      };
    });
  }, [data?.allTicketAssignableUsers]);

  return (
    <Select<string[]>
      placeholder={intl.formatMessage({
        id: 'ticket_opened_by_filter_select.placeholder',
        defaultMessage: 'Opened by'
      })}
      showSearch={true}
      mode={'multiple'}
      loading={loading}
      disabled={loading}
      style={{ minWidth: 150 }}
      popupMatchSelectWidth={false}
      value={selectedValue}
      allowClear={true}
      onSelect={handleSelectCreatedBy}
      onDeselect={handleDeselectCreatedBy}
      onClear={handleClear}
      filterOption={(input, option) => {
        return !!(
          option?.label && option.label.toString().toLowerCase().indexOf(input.toLowerCase()) !== -1
        );
      }}
      options={items}
      optionRender={(option) => {
        const user = userDictionary[option.value as string];
        return user.isMe ? <b>{user.name}</b> : user.name;
      }}
      {...props}
    />
  );
};

export default TicketCreatedByFilterSelect;
