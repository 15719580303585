import { Select, Space } from 'antd';
import React, { useCallback } from 'react';
import { SelectProps } from 'antd/es/select';
import useTicketFilter from 'components/ticket/TicketFilter/useTicketFilter';
import { useGetTicketTypesQuery } from 'generated/types';
import { Option } from 'components/lib/Select/Select';
import { getTicketTypeIcon } from 'components/ticket/TicketType/getTicketTypeIcon';
import useConnectIntl from 'i18n/useConnectIntl.ts';

type OtherSelectProps = Omit<SelectProps, 'value' | 'onSelect' | 'onChange' | 'options'>;

const TicketTypeFilterSelect: React.FC<OtherSelectProps> = (props) => {
  const { ticketFilter, setTicketFilter } = useTicketFilter();

  const handleChange = useCallback(
    (value: number[]) => {
      if (value.length > 0) {
        setTicketFilter({ ticketTypeIds: value });
      } else {
        setTicketFilter({ ticketTypeIds: undefined });
      }
    },
    [setTicketFilter]
  );

  const { data } = useGetTicketTypesQuery({
    variables: {
      machineType: undefined
    },
    notifyOnNetworkStatusChange: true
  });

  const intl = useConnectIntl();

  return (
    <Select
      placeholder={'Ticket type'}
      value={ticketFilter.ticketTypeIds}
      onChange={handleChange}
      mode={'multiple'}
      style={{ minWidth: 250 }}
      allowClear={true}
      showSearch={false}
      {...props}
    >
      {data?.allTicketTypes.map((c) => (
        <Option value={c.ticketTypeId} key={c.id}>
          <Space>
            <div>{getTicketTypeIcon(c)}</div>
            <div>{intl.formatMsg({ id: c.nameKeyI18n })}</div>
          </Space>
        </Option>
      ))}
    </Select>
  );
};

export default TicketTypeFilterSelect;
