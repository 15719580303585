import React from 'react';
import { useIntl } from 'react-intl';
import CreateTicketForm from 'components/ticket/CreateTicketForm/CreateTicketForm';
import PageContentRow from 'components/lib/page/rows/PageContentRow.tsx';
import Page from 'components/lib/page/Page/Page.tsx';
import { useMatrixLevel } from 'layouts/matrix/useMatrixLevel.ts';

const CreateTicketPage: React.FC = () => {
  const intl = useIntl();

  const createTicketTitle = intl.formatMessage({
    id: 'create_ticket_page.title',
    defaultMessage: 'New ticket'
  });

  const level = useMatrixLevel();

  return (
    <Page
      title={{
        pageTitle: createTicketTitle
      }}
      showBreadcrumbs={level !== 'root'}
      header={createTicketTitle}
      showBottomBorder={true}
    >
      <PageContentRow style={{ paddingBottom: 64, paddingTop: 16 }}>
        <CreateTicketForm />
      </PageContentRow>
    </Page>
  );
};

export default CreateTicketPage;
