import styled from 'styled-components';
import { Button } from 'antd';

const MainNavMiniButton = styled(Button)`
  && {
    background-color: unset;
    border: none;
    box-shadow: none;
    color: #fff;
    transition:
      color,
      background-color 0.15s ease-in;

    &:hover {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.06);
    }
    &:focus,
    &:active {
      color: #fff;
      background-color: transparent;
    }
  }
`;

export default MainNavMiniButton;
