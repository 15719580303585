import React from 'react';
import { RobotOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import predictionMessages from 'components/prediction/predictionMessages.ts';
import { PredictionItemFragment } from 'generated/types.tsx';

interface Props {
  prediction: PredictionItemFragment;
}
const PredictorVersion: React.FC<Props> = ({ prediction }) => {
  const intl = useConnectIntl();
  return (
    <Typography.Text>
      <RobotOutlined />
      <span style={{ marginLeft: 4 }}>
        {intl.formatMsg(predictionMessages.predictorInfo, {
          ms: prediction.elapsedMilliseconds,
          version: prediction.predictorVersion
        })}
      </span>
    </Typography.Text>
  );
};

export default PredictorVersion;
