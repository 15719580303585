import React, { useState } from 'react';
import { Button, Input, message, Space } from 'antd';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useAddRetailerBlockedPaymentCardMutation } from 'generated/types';
import commonMessages from 'components/i18n/commonMessages.ts';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { getFriendlyApolloErrorMessage } from 'graphql/apollo/apolloErrorUtil.ts';

interface Props {
  id: string;
  retailerId?: number;
  onCancel?: (id: string) => void;
  onSaved?: (id: string) => void;
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddBlockedCard = (props: Props) => {
  const { retailerId, onCancel, onSaved, id } = props;
  const [add] = useAddRetailerBlockedPaymentCardMutation();
  const intl = useConnectIntl();

  const [value, setValue] = useState<string | undefined>(undefined);

  const handleSave = async () => {
    if (!retailerId || !value) return;

    try {
      await add({
        variables: {
          input: {
            retailerId,
            cardNumber: value
          }
        }
      });
    } catch (err: unknown) {
      message.error(getFriendlyApolloErrorMessage(err));
    }

    onSaved?.(id);
  };

  const handleCancel = () => {
    onCancel?.(id);
  };

  return (
    <Container>
      <Input
        placeholder={'123456******789876'}
        style={{ marginRight: '8px' }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Space size={'small'}>
        <Button type={'primary'} icon={<SaveOutlined />} onClick={handleSave}>
          {intl.formatMsg(commonMessages.save)}
        </Button>
        <Button icon={<CloseOutlined />} onClick={handleCancel}>
          {intl.formatMsg(commonMessages.cancel)}
        </Button>
      </Space>
    </Container>
  );
};

export default AddBlockedCard;
