import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Avatar, Button, Card, Dropdown, Popconfirm, Space } from 'antd';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';
import {
  DeletedUserListItemFragmentDoc,
  TicketCommentFullFragmentDoc,
  TicketStatus,
  TicketStatusFragmentDoc,
  useCloseTicketMutation,
  useCreateTicketCommentMutation,
  useGetTicketReplyInfoQuery,
  useReopenTicketMutation,
  UserListItemFragmentDoc
} from 'generated/types';
import { gql } from '@apollo/client/core';
import { SendOutlined, UserOutlined } from '@ant-design/icons';
import { VendanorEditorDef } from 'components/lib/ConnectEditor/vendanorEditorDef';
import Spin from 'antd/es/spin';
import UserAvatar from 'components/user/UserAvatar/UserAvatar';
import {
  TicketCloseAsNotCompletedIcon,
  TicketCloseIcon
} from 'components/ticket/TicketDiscussion/ticketIcons';
import { hasUploadingFile } from 'remirror';
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import { VnFile } from 'components/lib/upload/VnFile';
import useTicketCommentUploadHandler from 'components/ticket/TicketDiscussion/useTicketCommentUploadHandler';
import { UploadRequestOption } from 'rc-upload/es/interface';
import instanceOfRcFile from 'components/ticket/TicketDiscussion/instanceOfRcFile';
import useMessageApi from 'components/global/useMessageApi';
import ConnectEditor from 'components/lib/ConnectEditor/ConnectEditor.tsx';
import useAutoSaveTicketComment from 'components/ticket/TicketDiscussion/useAutoSaveTicketComment.ts';
import useConnectIntl from 'i18n/useConnectIntl.ts';

interface Props {
  ticketId: number;
}

gql`
  query GetTicketReplyInfo($ticketId: Int!) {
    ticket(ticketId: $ticketId) {
      ...TicketStatus
      machine {
        id
        machineId
        active
      }
    }
    me {
      ...UserListItem
    }
  }
  ${TicketStatusFragmentDoc}
  ${UserListItemFragmentDoc}
`;

gql`
  mutation createTicketComment($input: CreateTicketCommentInput!) {
    createTicketComment(input: $input) {
      ticketComment {
        ...TicketCommentFull
        ticket {
          id
          ticketId
          comments {
            ...TicketCommentFull
          }
        }
      }
    }
  }
  ${TicketCommentFullFragmentDoc}
`;

gql`
  mutation closeTicket($input: CloseTicketInput!) {
    closeTicket(input: $input) {
      ticket {
        id
        ticketId
        status
        closed
        closedBy {
          ...UserListItem
        }
        assignedUserId
        assignedTo {
          ...UserListItem
        }
        comments {
          ...TicketCommentFull
        }
        isSubscribing
        subscribers {
          ...UserListItem
          ...DeletedUserListItem
        }
      }
    }
  }
  ${TicketCommentFullFragmentDoc}
  ${UserListItemFragmentDoc}
  ${DeletedUserListItemFragmentDoc}
`;

gql`
  mutation reopenTicket($input: ReopenTicketInput!) {
    reopenTicket(input: $input) {
      ticket {
        id
        ticketId
        status
        comments {
          ...TicketCommentFull
        }
        isSubscribing
        subscribers {
          ...UserListItem
          ...DeletedUserListItem
        }
      }
    }
  }
  ${TicketCommentFullFragmentDoc}
  ${UserListItemFragmentDoc}
  ${DeletedUserListItemFragmentDoc}
`;

const Container = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  flex-direction: row;
`;

const AvatarCol = styled.div`
  flex: 0 0 auto;
`;

const ContentCol = styled.div`
  flex: 1 1 100px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: hidden;
  padding-right: 8px;
`;

const Footer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 12px;
`;

const messages = defineMessages({
  comment: {
    id: 'ticket_reply.comment_comment',
    defaultMessage: 'Comment'
  },
  close: {
    id: 'ticket_reply.comment_close',
    defaultMessage: 'Close'
  },
  closeWithComment: {
    id: 'ticket_reply.comment_close_with_comment',
    defaultMessage: 'Close with comment'
  },
  closeAsNotCompleted: {
    id: 'ticket_reply.comment_close_as_not_completed',
    defaultMessage: 'Close as not completed'
  },
  placeholder: {
    id: 'ticket_reply.comment_placeholder',
    defaultMessage: 'Leave a comment'
  },
  reopen: {
    id: 'ticket_reply.comment_reopen',
    defaultMessage: 'Reopen'
  }
});

type UploadCancelRefDict = { [key: string]: () => void };

const TicketReply: React.FC<Props> = (props) => {
  const { ticketId } = props;
  const intl = useConnectIntl();
  const message = useMessageApi();
  const editorRef = useRef<VendanorEditorDef>();
  const uploadCancelByUidRefDict = useRef<UploadCancelRefDict>({});

  const { data, loading } = useGetTicketReplyInfoQuery({
    variables: {
      ticketId: ticketId
    }
  });

  const [createComment, { loading: creatingComment }] = useCreateTicketCommentMutation();
  const [closeTicket, { loading: closingTicket }] = useCloseTicketMutation();
  const [reopenTicket, { loading: reopeningTicket }] = useReopenTicketMutation();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isEditorEmpty, setIsEditorEmpty] = useState(true);
  const isOpen = data?.ticket.status === TicketStatus.Open || false;
  const isCommentButtonDisabled =
    loading || isEditorEmpty || creatingComment || closingTicket || reopeningTicket;
  const isCloseButtonDisabled = loading || creatingComment || closingTicket || reopeningTicket;
  const isReOpenButtonDisabled = loading || creatingComment || reopeningTicket || closingTicket;

  // TODO: Maybe add "isClosed" or "isOpen" bool to TicketType (+ core)?
  const showInactiveMachineWarning =
    (data?.ticket.machine.active === false &&
      (data.ticket.status === TicketStatus.Closed ||
        data.ticket.status === TicketStatus.ClosedAsNotCompleted)) ||
    false;

  const [assetIdsToRemove, setAssetIdsToRemove] = useState<string[]>([]);

  // TODO: Optimize ? This will cause a bunch of re-renders?
  const handleIsEmptyChange = useCallback((isEmpty: boolean) => {
    setIsEditorEmpty(isEmpty);
  }, []);

  const getEditorUploadInProgress = useCallback(() => {
    if (editorRef.current === undefined) return false;
    const state = editorRef.current.getState();
    return hasUploadingFile(state);
  }, []);

  const getEditorContent = useCallback(() => {
    if (editorRef.current === undefined) return undefined;
    if (isEditorEmpty) return undefined; // <== is this always in sync? yes, now it is...
    const state = editorRef.current.getState();
    const jsonState = editorRef.current.helpers.getJSON(state);
    const json = JSON.stringify(jsonState);
    const html = editorRef.current?.helpers.getHTML(state);

    if (json === '{"type":"doc","content":[{"type":"paragraph"}]}') {
      // NOTE: This is just in case, should in theory not end up here
      return undefined;
    }
    return {
      json,
      html
    };
  }, [isEditorEmpty]);

  const resetEditor = () => {
    setFileList([]);
    if (editorRef.current === undefined) return;
    editorRef.current?.setContent('', { triggerChange: true });
    setIsEditorEmpty(true);
    setAssetIdsToRemove([]);
  };

  const handleSubmitComment = useCallback(async () => {
    try {
      const isUploadingAsset = fileList.some((c) => c.status === 'uploading');
      if (getEditorUploadInProgress() || isUploadingAsset) {
        message.warning({
          content: intl.formatMessage({
            id: 'ticket_reply.download_in_progress_warning',
            defaultMessage: 'Upload in progress, please wait until it is finished'
          })
        });
        return;
      }

      const editorContent = getEditorContent();
      if (editorContent) {
        const assetIdsToSave = (fileList || [])
          .filter((c) => c.status === 'done')
          .map((c) => c.response?.assetId)
          .filter((c) => c);

        await createComment({
          variables: {
            input: {
              ticketId,
              contentHtml: editorContent.html,
              addedAssetIds: assetIdsToSave,
              removedAssetIds: assetIdsToRemove
            }
          }
        });
        resetEditor();
      }
    } catch (err) {
      message.error({
        content: 'An error occurred, could not save comment 😢'
      });
    }
  }, [
    createComment,
    ticketId,
    getEditorContent,
    getEditorUploadInProgress,
    intl,
    fileList,
    assetIdsToRemove,
    message
  ]);

  const handleClose = useCallback(
    async (options: { closeAsNotCompleted: boolean }) => {
      try {
        const closeAsNotCompleted = options.closeAsNotCompleted;
        const isUploadingAsset = fileList.some((c) => c.status === 'uploading');
        if (getEditorUploadInProgress() || isUploadingAsset) {
          message.warning({
            content: intl.formatMessage({
              id: 'ticket_reply.download_in_progress_warning',
              defaultMessage: 'Upload in progress, please wait until it is finished'
            })
          });
          return;
        }

        const editorContent = getEditorContent();
        await closeTicket({
          variables: {
            input: {
              ticketId,
              commentHtml: editorContent?.html,
              closeAsNotCompleted
            }
          }
        });
        resetEditor();
      } catch (err) {
        message.error({
          content: 'An error occurred, could not close ticket 😢'
        });
      }
    },
    [closeTicket, ticketId, getEditorContent, getEditorUploadInProgress, intl, fileList, message]
  );

  const handleReOpen = useCallback(async () => {
    const isUploadingAsset = fileList.some((c) => c.status === 'uploading');
    if (getEditorUploadInProgress() || isUploadingAsset) {
      message.warning({
        content: intl.formatMessage({
          id: 'ticket_reply.download_in_progress_warning',
          defaultMessage: 'Upload in progress, please wait until it is finished'
        })
      });
      return;
    }

    try {
      const editorContent = getEditorContent();
      await reopenTicket({
        variables: {
          input: {
            ticketId,
            commentHtml: editorContent?.html
          }
        }
      });
      resetEditor();
    } catch (err) {
      message.error({ content: 'Could not re-open ticket' });
    }
  }, [
    reopenTicket,
    ticketId,
    getEditorContent,
    fileList,
    getEditorUploadInProgress,
    intl,
    message
  ]);

  const handleUp = useTicketCommentUploadHandler();
  const handleUploadFile = useCallback(
    async (options: UploadRequestOption) => {
      const { file } = options;
      const abortController = new AbortController();
      if (instanceOfRcFile(file)) {
        uploadCancelByUidRefDict.current[file.uid] = () =>
          abortController.abort('Upload cancelled by user');
      }
      await handleUp(options, abortController.signal);
    },
    [handleUp]
  );

  const handleRemoveFile = async (file: UploadFile<VnFile>) => {
    if (file.response?.assetId) {
      // save assetIds to remove later when submitting comment (no direct removal yet)
      setAssetIdsToRemove([...assetIdsToRemove, file.response?.assetId]);
    }
    const remainingFileList = (fileList || []).filter((c) => c.uid !== file.uid);
    setFileList(remainingFileList);
  };

  const handleCancelUpload = async (file: UploadFile<VnFile>) => {
    uploadCancelByUidRefDict.current[file.uid]?.();
    const remainingItems = (fileList || []).filter((c) => c.uid !== file.uid);
    setFileList(remainingItems);
  };

  const handleChangeFile = (info: UploadChangeParam) => {
    setFileList(info.fileList);
  };

  const { draft, handleAutoSaveTicketComment } = useAutoSaveTicketComment(ticketId);
  useEffect(() => {
    if (draft) {
      editorRef.current?.setContent(draft.json, { triggerChange: true });
    }
  }, [draft]);

  const closeTextWithComment = isEditorEmpty
    ? intl.formatMessage(messages.close)
    : intl.formatMessage(messages.closeWithComment);
  const closeText = intl.formatMessage(messages.close);
  const closeAsNotCompletedText = intl.formatMessage(messages.closeAsNotCompleted);

  return (
    <Card size={'small'}>
      <Container>
        <AvatarCol>
          {data && <UserAvatar user={data.me} />}
          {!data && <Avatar icon={<UserOutlined />} size={'small'} />}
        </AvatarCol>
        <ContentCol>
          <ConnectEditor
            ref={editorRef}
            placeholder={intl.formatMessage(messages.placeholder)}
            editable={true}
            onAutoSave={handleAutoSaveTicketComment}
            onIsEmptyChange={handleIsEmptyChange}
            enableUpload={true}
            onUploadFile={handleUploadFile}
            onRemoveFile={handleRemoveFile}
            onUploadStateChange={handleChangeFile}
            onCancelUpload={handleCancelUpload}
            fileList={fileList}
          />
          <Footer>
            <Button
              type={'primary'}
              disabled={isCommentButtonDisabled}
              onClick={handleSubmitComment}
              icon={<SendOutlined />}
            >
              {intl.formatMessage(messages.comment)}
            </Button>
            {isOpen && (
              <Dropdown.Button
                type={'default'}
                disabled={isCloseButtonDisabled}
                style={{ overflow: 'hidden' }}
                onClick={() => handleClose({ closeAsNotCompleted: false })}
                menu={{
                  items: [
                    {
                      key: 'close',
                      label: (
                        <Space>
                          <TicketCloseIcon />
                          {closeText}
                        </Space>
                      ),
                      onClick: () => handleClose({ closeAsNotCompleted: false })
                    },
                    {
                      key: 'closeAsNotCompleted',
                      label: (
                        <Space>
                          <TicketCloseAsNotCompletedIcon />
                          {closeAsNotCompletedText}
                        </Space>
                      ),
                      onClick: () => handleClose({ closeAsNotCompleted: true })
                    }
                  ]
                }}
              >
                <Space>
                  <TicketCloseIcon />
                  {closeTextWithComment}
                </Space>
              </Dropdown.Button>
            )}
            {!isOpen && showInactiveMachineWarning && (
              <Popconfirm
                title={'Reopen the ticket'}
                description={'Are you sure you want to reopen the ticket on a deactivated machine?'}
                onConfirm={handleReOpen}
                overlayInnerStyle={{ maxWidth: 350 }}
              >
                <Button type={'default'} disabled={isReOpenButtonDisabled}>
                  {intl.formatMessage(messages.reopen)}
                </Button>
              </Popconfirm>
            )}
            {!isOpen && !showInactiveMachineWarning && (
              <Button type={'default'} onClick={handleReOpen} disabled={isReOpenButtonDisabled}>
                {intl.formatMessage(messages.reopen)}
              </Button>
            )}
            {(creatingComment || closingTicket || reopeningTicket) && <Spin size={'small'} />}
          </Footer>
        </ContentCol>
      </Container>
    </Card>
  );
};

export default TicketReply;
