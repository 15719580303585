import React, { createContext, useCallback, useContext } from 'react';
import { Helmet } from 'react-helmet-async';

interface Title {
  pageTitle: string;
  headTitleParts: Array<string | undefined>;
}

export type SetTitleArgs = Partial<Title>;

interface TitleContextType {
  title: Title;
  setTitle: (args: SetTitleArgs) => void;
}

const defaultTitle = 'Vendanor Connect';
const defaultHeadTitleParts = ['Vendanor Connect'];

const TitleContext = createContext<TitleContextType>({
  title: {
    pageTitle: defaultTitle,
    headTitleParts: defaultHeadTitleParts
  },
  setTitle: () => {}
});

export const useTitleContext = (): TitleContextType => {
  const context = useContext(TitleContext);
  if (!context) {
    throw new Error('useTitleContext must be used within a TitleProvider');
  }
  return context;
};

export const TitleProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [currentTitle, setCurrentTitle] = React.useState<Title>({
    pageTitle: defaultTitle,
    headTitleParts: defaultHeadTitleParts
  });

  const setTitle = useCallback((args: SetTitleArgs) => {
    const { pageTitle, headTitleParts } = args;
    setCurrentTitle({
      pageTitle: pageTitle || defaultTitle,
      headTitleParts: headTitleParts || (pageTitle ? [pageTitle] : defaultHeadTitleParts)
    });
  }, []);

  return (
    <TitleContext.Provider value={{ title: currentTitle, setTitle }}>
      <Helmet>
        <title>
          {currentTitle.headTitleParts
            .filter((c) => c !== undefined && c !== null && c !== '')
            .join(' · ')}
        </title>
      </Helmet>
      {children}
    </TitleContext.Provider>
  );
};
