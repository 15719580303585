import { ProductCategory } from 'generated/types';
import { Message } from 'typescript-react-intl';
import { defineMessages } from 'react-intl';

type RetailerProductMessages = { [key in ProductCategory]: Message };

const messages: RetailerProductMessages = {
  [ProductCategory.Alpilox]: {
    id: 'product_category_alpilox',
    defaultMessage: 'Alpilox'
  },
  [ProductCategory.PropaneGas]: {
    id: 'product_category_propaneGas',
    defaultMessage: 'Propane gas'
  },
  [ProductCategory.WindshieldWasherFluid]: {
    id: 'product_category_wfd',
    defaultMessage: 'Washer fluid'
  }
};

export const productCategoryMessages = defineMessages(messages);

export const retailerProductMessages = defineMessages({
  noProducts: {
    id: 'retailer.product.no_products',
    defaultMessage: 'No enabled products'
  },
  selectProducts: {
    id: 'retailer.product.select_products',
    defaultMessage: 'Select products'
  },
  drawerTitle: {
    id: 'retailer.product.drawer_title',
    defaultMessage: 'Select products for {retailer}'
  },
  templateInfoIntro: {
    id: 'retailer.product.template_info_intro',
    defaultMessage:
      'Use a template to customize the product name. Empty value uses the default template. Syntax: {br} <b>translate(key)</b>{br}<b>\\n</b>{br}'
  },
  tooltipRetailerProductId: {
    id: 'retailer.product.tooltip_retailer_product_id',
    defaultMessage:
      'The retailer product id. This is the id that is used to identify the product in the retailers system.'
  },
  labelRetailerProductId: {
    id: 'retailer.product.label_retailer_product_id',
    defaultMessage: 'Retailer product id'
  },
  labelNameTemplate: {
    id: 'retailer.product.label_name_template',
    defaultMessage: 'Product name template'
  },
  labelPreview: {
    id: 'retailer.product.label_preview',
    defaultMessage: 'Preview'
  },
  labelTemplateRules: {
    id: 'retailer.product.label_template_rules',
    defaultMessage: 'Template rules'
  },
  templateRules: {
    id: 'retailer.product.template_rules',
    defaultMessage: `<li>Max line length is 11 characters.</li>
                    <li>Max number of lines is 4.</li>
                    <li>
                      <b>translate(key)</b> for translations
                    </li>
                    <li>
                      <b>\\n</b> for new line
                    </li>`
  },
  noEnabledProducts: {
    id: 'retailer.product.no_enabled_products',
    defaultMessage: 'No enabled products'
  },
  noEnabledProductsTitle: {
    id: 'retailer.product.no_enabled_products_title',
    defaultMessage: 'Products'
  },
  labelProductId: {
    id: 'retailer.product.label_product_id',
    defaultMessage: 'Product id'
  }
});
