import React from 'react';
import { SelectProps } from 'antd/es/select';
import Select from 'components/lib/Select/Select.tsx';
import useTicketFilter from 'components/ticket/TicketFilter/useTicketFilter.ts';
import { SortDirection, TicketSortField } from 'generated/types.tsx';

type OtherSelectProps = Omit<SelectProps, 'value' | 'onSelect' | 'onChange' | 'options'>;

type SortPresetKey =
  | 'newest'
  | 'oldest'
  | 'due_soon'
  | 'most_recently_updated'
  | 'least_recently_updated'
  | 'most_severe'
  | 'least_severe'
  | 'closest'
  | 'unknown';

type Dict = {
  [key in SortPresetKey]: {
    field: TicketSortField;
    direction: SortDirection;
    label: string;
    disabled?: boolean;
  };
};

const dict: Dict = {
  newest: {
    field: TicketSortField.Opened,
    direction: SortDirection.Descending,
    label: 'Newest'
  },
  oldest: {
    field: TicketSortField.Opened,
    direction: SortDirection.Ascending,
    label: 'Oldest'
  },
  due_soon: {
    field: TicketSortField.Due,
    direction: SortDirection.Ascending,
    label: 'Due'
  },
  most_recently_updated: {
    field: TicketSortField.Updated,
    direction: SortDirection.Descending,
    label: 'Most recently updated'
  },
  least_recently_updated: {
    field: TicketSortField.Updated,
    direction: SortDirection.Ascending,
    label: 'Least recently updated'
  },
  most_severe: {
    field: TicketSortField.Severity,
    direction: SortDirection.Ascending,
    label: 'Most severe'
  },
  least_severe: {
    field: TicketSortField.Severity,
    direction: SortDirection.Descending,
    label: 'Least severe'
  },
  closest: {
    field: TicketSortField.Distance,
    direction: SortDirection.Ascending,
    label: 'Closest',
    disabled: true
  },
  unknown: {
    field: TicketSortField.Opened,
    direction: SortDirection.Descending,
    label: 'Unknown'
  }
};

const getPresetKey = (field: TicketSortField, direction: SortDirection): SortPresetKey => {
  const key = Object.keys(dict).find((key) => {
    const { field: presetField, direction: presetDirection } = dict[key as SortPresetKey];
    return field === presetField && direction === presetDirection;
  });
  return (key as SortPresetKey) || 'unknown';
};

const TicketSortSelect: React.FC<OtherSelectProps> = (props) => {
  const { ticketFilter, setTicketFilter } = useTicketFilter();

  const options: { label: string; value: string; disabled?: boolean }[] = Object.keys(dict).map(
    (key) => {
      const { label, disabled } = dict[key as SortPresetKey];
      return { label, value: key, disabled };
    }
  );

  return (
    <Select
      popupMatchSelectWidth={false}
      onSelect={(value) => {
        const { field, direction } = dict[value as SortPresetKey];
        setTicketFilter({ sortField: field, sortDirection: direction });
      }}
      value={getPresetKey(ticketFilter.sortField, ticketFilter.sortDirection)}
      options={options}
      {...props}
    />
  );
};

export default TicketSortSelect;
