import { Radio, RadioChangeEvent, RadioGroupProps } from 'antd';
import React from 'react';
import { useEnumRadioOptions } from 'components/lib/Radio/useEnumRadioOptions';
import useTicketFilter from 'components/ticket/TicketFilter/useTicketFilter';
import { TicketStatus } from 'generated/types.tsx';
import { Message } from 'typescript-react-intl';
import { defineMessages } from 'react-intl';
import { isEqual } from 'lodash-es';

interface Props {
  showAllButton?: boolean;
}

type OtherProps = Omit<RadioGroupProps, 'optionType' | 'value' | 'onChange' | 'options'>;

// Backend has Open, Closed and CloseAsNotCompleted
enum TicketStatusRadio {
  Open = 'OPEN',
  Closed = 'CLOSED',
  All = 'ALL'
}

type TicketStatusMessages = { [key in TicketStatusRadio]: Message };
const statusMessages: TicketStatusMessages = {
  [TicketStatusRadio.Open]: {
    id: 'ticket_status_extended.open',
    defaultMessage: 'Open'
  },
  [TicketStatusRadio.Closed]: {
    id: 'ticket_status_extended.closed',
    defaultMessage: 'Closed'
  },
  [TicketStatusRadio.All]: {
    id: 'ticket_status_extended.all',
    defaultMessage: 'All'
  }
};
defineMessages(statusMessages);

const TicketStatusFilterRadio: React.FC<Props & OtherProps> = (props) => {
  const { children, showAllButton, ...rest } = props;
  const { ticketFilter, setTicketFilter } = useTicketFilter();

  const statusOptions = useEnumRadioOptions({
    enumObject: TicketStatusRadio,
    keyPrefix: 'ticket_status_extended',
    customLabelMessages: statusMessages
  });

  const handleChangeStatus = (e: RadioChangeEvent) => {
    if (e.target.value === TicketStatusRadio.All) {
      setTicketFilter({
        statuses: [TicketStatus.Open, TicketStatus.Closed, TicketStatus.ClosedAsNotCompleted]
      });
    } else if (e.target.value === TicketStatusRadio.Open) {
      setTicketFilter({
        statuses: [TicketStatus.Open]
      });
    } else if (e.target.value === TicketStatusRadio.Closed) {
      setTicketFilter({
        statuses: [TicketStatus.Closed, TicketStatus.ClosedAsNotCompleted]
      });
    }
  };

  let value: TicketStatusRadio | undefined;

  if (
    ticketFilter.statuses &&
    isEqual(
      [...ticketFilter.statuses].sort(),
      [TicketStatus.Open, TicketStatus.Closed, TicketStatus.ClosedAsNotCompleted].sort()
    )
  ) {
    value = TicketStatusRadio.All;
  } else if (isEqual(ticketFilter.statuses, [TicketStatus.Open])) {
    value = TicketStatusRadio.Open;
  } else if (
    isEqual(
      [...ticketFilter.statuses].sort(),
      [TicketStatus.Closed, TicketStatus.ClosedAsNotCompleted].sort()
    )
  ) {
    value = TicketStatusRadio.Closed;
  } else if (!ticketFilter.statuses) {
    value = TicketStatusRadio.All;
  }

  return (
    <Radio.Group
      value={value}
      onChange={handleChangeStatus}
      optionType={'button'}
      options={statusOptions}
      {...rest}
    />
  );
};

export default TicketStatusFilterRadio;
