import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import useIsMobile from 'layouts/responsive/useIsMobile.ts';
import Breadcrumbs, { BreadcrumbRow } from 'layouts/MainLayout/Breadcrumbs/Breadcrumbs.tsx';
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { isArray } from 'lodash-es';
import { ConnectTabsProps } from 'components/lib/Tabs/Tabs.tsx';
import PageTitleRow from 'components/lib/page/rows/PageTitleRow.tsx';
import PageHeaderRow from 'components/lib/page/rows/PageHeaderRow.tsx';
import PageTabs from 'components/lib/page/PageTabs/PageTabs.tsx';
import { Typography } from 'antd';
import { SetTitleArgs, useTitleContext } from 'layouts/MainLayout/TitleContext.tsx';

// On desktop, the scrollable area is controlled by each page
const DesktopContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

const BottomBorderRow = styled(PageHeaderRow)`
  border-bottom: 1px solid ${(props) => props.theme.ant.colorBorder};
  flex: 0 0 16px;

  position: sticky;
  top: calc(-15px);

  z-index: 3;

  @media (min-width: 480px) {
    position: relative;
    top: unset;
  }
  padding-top: 0;
  padding-bottom: 0;
`;

interface Props {
  title: SetTitleArgs;

  showBreadcrumbs?: boolean | BreadcrumbItemType[];

  // retailer logo, name etc. can be as large as we want?
  header?: React.ReactNode;

  // Tabs specified here will render using the PageTabs component which adds a scrollable container for the tab content
  tabs?: ConnectTabsProps;

  // If you need a simple page without tabs, you can use children.
  // Children are wrapped in a scrollable container (one for desktop, one for mobile)
  // Note: children and tabs cannot be used together
  children?: ReactNode;

  // Add an empty div below the title/header with 1px border
  showBottomBorder?: boolean;
}

const Page: React.FC<Props> = ({
  header,
  showBreadcrumbs,
  showBottomBorder,
  title,
  tabs,
  children
}) => {
  const isMobile = useIsMobile();

  const headerRow = header ? (
    typeof header === 'string' ? (
      <>
        <PageTitleRow>
          <Typography.Title level={2} style={{ marginBottom: 0 }}>
            {header}
          </Typography.Title>
        </PageTitleRow>
        {showBottomBorder && <BottomBorderRow />}
      </>
    ) : (
      <PageHeaderRow>{header}</PageHeaderRow>
    )
  ) : null;

  let breadcrumbsVisible = false;
  let overrideItems: BreadcrumbItemType[] | undefined = undefined;

  if (typeof showBreadcrumbs === 'boolean') {
    breadcrumbsVisible = showBreadcrumbs;
  } else if (isArray(showBreadcrumbs) && showBreadcrumbs?.length > 0) {
    breadcrumbsVisible = true;
    overrideItems = showBreadcrumbs;
  }

  const { setTitle } = useTitleContext();

  useEffect(() => {
    setTitle(title);
  }, [setTitle, title]);

  // show a warning if BOTH children and tabs are provided!
  if (children && tabs) {
    throw new Error('Page component cannot have both children and tabs');
  }

  if (isMobile) {
    return (
      <>
        {breadcrumbsVisible && (
          <BreadcrumbRow $isMobile={isMobile}>
            <Breadcrumbs overrideItems={overrideItems} />
          </BreadcrumbRow>
        )}
        {headerRow}
        {tabs && tabs.items && <PageTabs {...tabs} />}
        {children}
      </>
    );
  }

  return (
    <>
      <DesktopContainer>
        {breadcrumbsVisible && (
          <BreadcrumbRow $isMobile={isMobile}>
            <Breadcrumbs />
          </BreadcrumbRow>
        )}
        {headerRow}
        {tabs && tabs.items && <PageTabs {...tabs} />}
        {/* Any children are wrapped in a scrollable container (for desktop, mobile has its own scroll container) */}
        {children && <ScrollContainerDesktop>{children}</ScrollContainerDesktop>}
      </DesktopContainer>
    </>
  );
};

export default Page;

const ScrollContainerDesktop = styled.div`
  background-color: ${(props) => props.theme.ant.colorBgLayout};

  @media (min-width: 480px) {
    // On desktop, this is the scrollable area when using tabs
    overflow-y: auto;
    height: 100%;
  }
`;
