import { Alert, Button, Drawer, Form, Radio, Space, Spin, Steps } from 'antd';
import RetailerSelect from 'components/retailer/RetailerSelect/RetailerSelect';
import getMachineIcon from 'components/icons/getMachineIcon';
import commonMessages from 'components/i18n/commonMessages';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  GetTargetMachineListQuery,
  GetTargetMachineListQueryVariables,
  MachineTargetFragment,
  MachineTargetFragmentDoc,
  MinimalPageInfoFragmentDoc,
  useGetOpenTicketCountQuery,
  useGetTargetMachineListLazyQuery,
  useGetTargetMachineQuery,
  useMoveMachineMutation
} from 'generated/types';
import useMatrixNav from 'layouts/matrix/useMatrixNav';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'components/lib/DatePicker/DatePicker';
import { ApolloQueryResult, gql } from '@apollo/client/core';
import { addYears, parseISO } from 'date-fns';
import useFormatTools from 'i18n/useFormatTools';
import { QueryResult } from '@apollo/client/react/types/types';
import useMessageApi from 'components/global/useMessageApi';
import { ApolloError } from '@apollo/client';
import { getFriendlyApolloErrorMessages } from 'graphql/apollo/apolloErrorUtil.ts';

interface Props {
  machineId?: number;
  open: boolean;

  onCancel?: () => void;
  onComplete?: () => void;
}

gql`
  fragment MachineTarget on Machine {
    ...MachineListItem
    warrantyExpiryDate
    deliveryDate
    retailer {
      id
      retailerId
      name
    }
  }
`;

gql`
  query GetTargetMachineList($cursor: String, $retailerId: Int!) {
    allMachines (first: 50, after: $cursor, allMachineFilter: {
      active: false,
      retailerId: $retailerId
    }, order: [{
      id: ASC
    }]) {
      pageInfo {
        ...MinimalPageInfo
      }
      edges {
        node {
          ...MachineTarget
        }
      }
    }
    ${MinimalPageInfoFragmentDoc}
    ${MachineTargetFragmentDoc}
  }
`;

gql`
  query GetTargetMachine($machineId: Int!) {
    machine(machineId: $machineId) {
      ...MachineTarget
    }
  }
`;

gql`
  mutation MoveMachine($input: MoveMachineInput!) {
    moveMachine(input: $input) {
      sourceMachine {
        id
        machineId
        retailerId
        retailerReference
        active
        retailer {
          id
          retailerId
          machineTags
        }
      }
      targetMachine {
        id
        machineId
        retailerId
        retailerReference
        active
        retailer {
          id
          retailerId
          machineTags
        }
      }
    }
  }
`;

gql`
  query GetOpenTicketCount($machineId: Int!) {
    allTickets(
      filter: { machineId: $machineId, ticketStatuses: [OPEN] }
      sortDirection: ASCENDING
      sortField: OPENED
    ) {
      totalCount
    }
  }
`;

const MoveMachineDrawer: React.FC<Props> = ({ machineId, open, onCancel, onComplete }) => {
  const intl = useIntl();
  const message = useMessageApi();
  const { getUrlToMachine } = useMatrixNav();
  const navigate = useNavigate();
  const { formatMachineTitle } = useFormatTools();

  const [currentStep, setCurrentStep] = useState(0);
  const [targetMachineId, setTargetMachineId] = useState<number | undefined | 'new'>(undefined);
  const [targetRetailerId, setTargetRetailerId] = useState<number | undefined>(undefined);
  const [targetDeliveryDate, setTargetDeliveryDate] = useState<Date | undefined>(undefined);
  const [targetWarrantyExpiryDate, setTargetWarrantyExpiryDate] = useState<Date | undefined>(
    undefined
  );

  const { data } = useGetTargetMachineQuery({
    variables: machineId
      ? {
          machineId
        }
      : undefined,
    skip: !machineId
  });

  const [
    getTargetMachines,
    { loading: loadingTargets, data: dataTargets, fetchMore: fetchMoreTargetMachines }
  ] = useGetTargetMachineListLazyQuery({
    notifyOnNetworkStatusChange: true
  });

  const { data: dataTicketCount } = useGetOpenTicketCountQuery({
    variables: machineId
      ? {
          machineId
        }
      : undefined,
    skip: !machineId
  });

  const showTicketWarning = dataTicketCount?.allTickets?.totalCount || 0 > 0 || false;

  const [moveMachine, { loading: moveLoading }] = useMoveMachineMutation();

  const handleCancelDeactivate = () => {
    resetForm();
    onCancel?.();
  };

  const resetForm = useCallback(() => {
    setTargetMachineId(undefined);
    setTargetRetailerId(undefined);
    setCurrentStep(0);
  }, []);

  const handleNewMachineTarget = () => {
    if (!data) return;

    setCurrentStep(3);

    // NOTE: New machine instance selected.
    const deliveryDate = new Date();
    // New logic:
    // If the old warranty expiry date is set, use that.
    // If the old warranty expiry date is not set, use the delivery date + 3 years.
    const warrantyExpiryDate = data.machine.warrantyExpiryDate
      ? parseISO(data.machine.warrantyExpiryDate)
      : addYears(deliveryDate, 3);
    setTargetDeliveryDate(deliveryDate);
    setTargetWarrantyExpiryDate(warrantyExpiryDate);
    setTargetMachineId('new'); // new = new machine instance, undefined = unselected
    return;
  };

  const handleChangeMachineTarget = (targetMachine: MachineTargetFragment) => {
    if (!dataTargets) return;
    if (!data) return;

    setCurrentStep(3);

    const deliveryDate = new Date();
    const warrantyExpiryDate = targetMachine.warrantyExpiryDate
      ? parseISO(targetMachine.warrantyExpiryDate)
      : data.machine.warrantyExpiryDate
        ? parseISO(data.machine.warrantyExpiryDate)
        : addYears(deliveryDate, 3);

    setTargetDeliveryDate(deliveryDate);
    setTargetWarrantyExpiryDate(warrantyExpiryDate);
    setTargetMachineId(targetMachine.machineId);
  };

  const handleChangeRetailer = useCallback(
    async (retailerId: number) => {
      try {
        let cursor = undefined;
        let hasNextPage: boolean;

        const result: QueryResult<GetTargetMachineListQuery, GetTargetMachineListQueryVariables> =
          await getTargetMachines({
            variables: {
              cursor,
              retailerId
            }
          });

        hasNextPage = result.data?.allMachines?.pageInfo.hasNextPage || false;
        cursor = result.data?.allMachines?.pageInfo.endCursor || undefined;

        while (hasNextPage) {
          const result2: ApolloQueryResult<GetTargetMachineListQuery | undefined> =
            await fetchMoreTargetMachines({
              variables: {
                retailerId,
                cursor
              }
            });
          hasNextPage = result2.data?.allMachines?.pageInfo.hasNextPage || false;
          cursor = result2.data?.allMachines?.pageInfo.endCursor || undefined;
        }
        setTargetRetailerId(retailerId);
        setCurrentStep(1);
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [getTargetMachines, fetchMoreTargetMachines]
  );

  const handleChangeDeliveryDate = (date: Date | null) => {
    if (date) {
      setTargetDeliveryDate(date);
      if (targetMachineId === undefined) {
        setTargetWarrantyExpiryDate(addYears(date, 3));
      }
    } else {
      setTargetDeliveryDate(undefined);
    }
  };

  const handleChangeWarrantyExpiryDate = (date: Date | null) => {
    if (date) {
      setTargetWarrantyExpiryDate(date);
    } else {
      setTargetWarrantyExpiryDate(undefined);
    }
  };

  const handleConfirmMove = async () => {
    try {
      if (!machineId) {
        message.error('Set machine id first');
        return;
      }

      if (!targetRetailerId) {
        message.error('Set target retailer id first');
        return;
      }

      if (!targetDeliveryDate) {
        message.error('Set target delivery date first');
        return;
      }

      if (!targetWarrantyExpiryDate) {
        message.error('Set target warranty expiry date first');
        return;
      }

      message.loading({
        key: 'moveMachine',
        content: 'Moving machine...'
      });

      const result = await moveMachine({
        variables: {
          input: {
            sourceMachineId: machineId,
            targetRetailerId,
            targetMachineId: targetMachineId === 'new' ? undefined : targetMachineId,
            deliveryDate: targetDeliveryDate.toISOString(),
            warrantyExpiryDate: targetWarrantyExpiryDate.toISOString()
          }
        }
      });

      if (result.data?.moveMachine.targetMachine) {
        const urlToMachine = getUrlToMachine(result.data.moveMachine.targetMachine.machineId);
        if (targetMachineId === undefined) {
          navigate(urlToMachine + '?editMachineDetails=true&editMachineLocation=true');
        } else {
          navigate(urlToMachine);
        }
      }

      message.success({
        key: 'moveMachine',
        content: 'Machine moved successfully!'
      });
      resetForm();
      onComplete?.();
    } catch (err: unknown) {
      if (err instanceof ApolloError) {
        const friendlyMessages = getFriendlyApolloErrorMessages(err, false);
        message.error({
          content: (
            <div>
              {friendlyMessages.map((c) => (
                <div key={c}>{c}</div>
              ))}
            </div>
          )
        });
      } else {
        message.error({
          key: 'moveMachine',
          content: 'Error moving machine'
        });
      }
    }
  };

  return (
    <Drawer
      title={'Transfer machine'}
      placement={'right'}
      closable={true}
      onClose={handleCancelDeactivate}
      open={open}
      width={400}
    >
      <Steps direction={'vertical'} size={'small'} current={currentStep}>
        <Steps.Step title={'Select retailer'} />
        <Steps.Step
          title={'Select machine entity'}
          description={'Create a new machine entity or select an existing one'}
        />
        <Steps.Step title={'Warranty'} description={'Check delivery and warranty expiry dates.'} />
        <Steps.Step title={'Confirm'} />
      </Steps>
      <Form layout={'vertical'} disabled={moveLoading}>
        <Form.Item label={'Retailer'}>
          <RetailerSelect
            style={{ width: '100%' }}
            onChange={handleChangeRetailer}
            value={targetRetailerId}
            placeholder={'Select retailer'}
          />
        </Form.Item>
        {loadingTargets && (
          <Form.Item>
            <Spin />
          </Form.Item>
        )}
      </Form>
      {dataTargets && currentStep >= 1 && (
        <Form.Item
          label={'Machine entity'}
          tooltip={
            dataTargets?.allMachines?.edges?.length === 0 ? (
              <span>No inactive machines found for the selected retailer</span>
            ) : undefined
          }
        >
          <Radio.Group
            // onChange={handleChangeMachineInstance}
            value={targetMachineId}
            buttonStyle={'outline'}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Radio value={'new'} key={'new'} onClick={handleNewMachineTarget}>
              Create a new machine instance
            </Radio>
            {dataTargets?.allMachines?.edges
              ?.map((c) => c.node)
              .map((machine) => (
                <Radio
                  value={machine.machineId}
                  key={machine.machineId}
                  onClick={() => {
                    handleChangeMachineTarget(machine);
                  }}
                >
                  {getMachineIcon(machine.machineType)} {formatMachineTitle(machine)}
                </Radio>
              ))}
          </Radio.Group>
        </Form.Item>
      )}

      {currentStep >= 2 && (
        <Form layout={'vertical'} labelCol={{ xs: 8 }} wrapperCol={{ xs: 16 }}>
          <Form.Item label={'Delivery date'}>
            <DatePicker
              value={targetDeliveryDate}
              onChange={handleChangeDeliveryDate}
              allowClear={false}
            />
          </Form.Item>
          <Form.Item label={'Warranty expiry date'}>
            <DatePicker
              value={targetWarrantyExpiryDate}
              onChange={handleChangeWarrantyExpiryDate}
              allowClear={false}
            />
          </Form.Item>
          {showTicketWarning && (
            <Form.Item>
              <Alert
                type={'warning'}
                description={`${
                  dataTicketCount?.allTickets?.totalCount || 0
                } open tickets will be transferred to the selected machine instance and retailer!`}
              />
            </Form.Item>
          )}
        </Form>
      )}

      <Form.Item>
        <Space>
          <Button
            type={'primary'}
            onClick={handleConfirmMove}
            disabled={currentStep !== 3 || moveLoading}
          >
            {intl.formatMessage(commonMessages.confirm)}
          </Button>
          <Button type={'default'} onClick={handleCancelDeactivate} disabled={moveLoading}>
            {intl.formatMessage(commonMessages.cancel)}
          </Button>
        </Space>
      </Form.Item>
    </Drawer>
  );
};

export default MoveMachineDrawer;
