import useQueryParam from 'hooks/useQueryParam.ts';

interface TicketAccountingToolFilter {
  accounted?: boolean | 'all';
  categoryIds?: number[];
  storageIds?: number[];
}

const defaultFilter: TicketAccountingToolFilter = {
  accounted: false,
  storageIds: undefined,
  categoryIds: undefined
};

function useTicketAccountingToolFilter() {
  const [filterParam, setFilterParam] = useQueryParam<TicketAccountingToolFilter>('olf');
  const filter = filterParam || defaultFilter;
  const setFilter = (value: Partial<TicketAccountingToolFilter>) => {
    setFilterParam({
      ...defaultFilter,
      ...filterParam,
      ...value
    });
  };
  return { filter, setFilter };
}

export default useTicketAccountingToolFilter;
