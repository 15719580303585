import React, { useCallback } from 'react';
import { SelectProps } from 'antd/es/select';
import { useIntl } from 'react-intl';
import Select, { Option } from 'components/lib/Select/Select';
import { Typography } from 'antd';
import styled from 'styled-components';
import { gql } from '@apollo/client/core';
import { useGetAllTicketAssignableUsersQuery, UserListItemFragment } from 'generated/types';

interface Props {
  value?: string;
  onChange?: (value?: string, user?: UserListItemFragment) => void;
}

type OtherSelectProps = Omit<SelectProps<string>, 'options' | 'value' | 'onChange'>;

const Label = styled.div`
  flex: 1 1 auto;
`;

gql`
  query GetAllTicketAssignableUsers {
    allTicketAssignableUsers {
      ...UserListItem
    }
  }
`;

const OptionContainer = styled.div`
  height: 100%;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  vertical-align: middle;
`;

const UserSelect: React.FC<Props & OtherSelectProps> = (props) => {
  const { data, loading } = useGetAllTicketAssignableUsersQuery();
  const { value, onChange, onClear, ...rest } = props;
  const intl = useIntl();

  const handleChange = useCallback(
    (value?: string) => {
      onChange?.(
        value,
        value ? data?.allTicketAssignableUsers.find((u) => u.id === value) : undefined
      );
    },
    [data?.allTicketAssignableUsers, onChange]
  );

  return (
    <Select
      value={loading ? undefined : value}
      onChange={handleChange}
      loading={loading}
      placeholder={intl.formatMessage({
        id: 'user_select.placeholder',
        defaultMessage: 'Select a user'
      })}
      allowClear={true}
      showSearch={true}
      filterOption={(input, option) => {
        return option?.label?.toString().toLowerCase().indexOf(input.toLowerCase()) !== -1;
      }}
      {...rest}
    >
      {data?.allTicketAssignableUsers.map((user: UserListItemFragment) => {
        return (
          <Option value={user.id} key={user.id} label={user.name}>
            <OptionContainer>
              <Label>{user.name}</Label>
              <Typography.Text type={'secondary'}>{user.title}</Typography.Text>
            </OptionContainer>
          </Option>
        );
      })}
    </Select>
  );
};

export default UserSelect;
