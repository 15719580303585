import { useCallback, useRef } from 'react';
import { MissingTranslationError, OnErrorFn } from '@formatjs/intl';
import useInterval from 'hooks/useInterval.ts';
import { useAppSelector } from 'redux/store.ts';
import { gql } from '@apollo/client/core';
import {
  ReportMissingTranslationsMutation,
  ReportMissingTranslationsMutationVariables,
  TranslationContext
} from 'generated/types.tsx';
import { selectIsAuthenticated } from 'auth/authSlice.ts';
import {
  DEFAULT_LOCALE,
  selectAllFallbackMessages,
  selectCurrentLocale,
  selectLoginFallbackMessages
} from 'i18n/i18nSlice.ts';
import { apolloClient } from 'graphql/apollo/apolloClient.ts';
import { selectIsServerAvailable } from 'redux/appSlice.ts';

const REPORT_MUTATION = gql`
  mutation ReportMissingTranslations($input: ReportMissingTranslationsInput!) {
    reportMissingTranslations(input: $input) {
      boolean
    }
  }
`;

/***
 * This hook is used to report missing translations to the server.
 * We batch up missing translations and send them to the server every 2 minutes.
 * If we're not authenticated, we don't report missing translations (but stash them for later)
 *
 * We also report missing translations for the default language "en", this is because
 * we want the backend to autofix the context for us.
 */
function useTranslationErrorHandler() {
  const ref = useRef<{
    missingAppMessageKeys: string[];
    reportedAppMessageKeys: string[];
    missingLoginMessageKeys: string[];
    reportedLoginMessageKeys: string[];
  }>({
    missingAppMessageKeys: [],
    missingLoginMessageKeys: [],
    reportedAppMessageKeys: [],
    reportedLoginMessageKeys: []
  });

  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isServerAvailable = useAppSelector(selectIsServerAvailable);
  const currentLocale = useAppSelector(selectCurrentLocale);
  const loginFallback = useAppSelector(selectLoginFallbackMessages);
  const appFallback = useAppSelector(selectAllFallbackMessages);

  const handleError = useCallback<OnErrorFn>((err) => {
    const errorCode = err.code;
    if (errorCode === 'MISSING_TRANSLATION') {
      const missingTranslationError = err as MissingTranslationError;
      const translationKey = missingTranslationError.descriptor?.id;
      const isLoginPage = window?.location?.pathname.indexOf('/login') === 0;

      if (isLoginPage && translationKey) {
        // console.log(
        //   'MISSING_TRANSLATION[LOGIN]:' + translationKey
        // );
        ref.current.missingLoginMessageKeys = [
          ...ref.current.missingLoginMessageKeys,
          translationKey
        ];
      } else if (!isLoginPage && translationKey) {
        // console.log(
        //   'MISSING_TRANSLATION[APP]:' + translationKey
        // );
        ref.current.missingAppMessageKeys = [...ref.current.missingAppMessageKeys, translationKey];
      }
    }
  }, []);

  useInterval(
    async () => {
      // report missing is protected by auth
      if (!isAuthenticated || !isServerAvailable) {
        return;
      }

      const appKeys = [...ref.current.missingAppMessageKeys];
      const loginKeys = [...ref.current.missingLoginMessageKeys];

      const alreadyReportedAppKeys = [...ref.current.reportedAppMessageKeys];
      const alreadyReportedLoginKeys = [...ref.current.reportedLoginMessageKeys];

      const appKeysUnreported = appKeys.filter((key) => !alreadyReportedAppKeys.includes(key));
      const loginKeysUnreported = loginKeys.filter(
        (key) => !alreadyReportedLoginKeys.includes(key)
      );

      if (appKeysUnreported.length === 0 && loginKeysUnreported.length === 0) {
        ref.current.missingAppMessageKeys = [];
        ref.current.missingLoginMessageKeys = [];
        return;
      }

      if (appKeysUnreported.length > 0) {
        try {
          ref.current.missingAppMessageKeys = [];

          const missingCountByKey: Record<string, number> = {};
          const missingCountByKeyForDefaultLocale: Record<string, number> = {};

          appKeysUnreported.forEach((c) => {
            missingCountByKey[c] = (missingCountByKey[c] || 0) + 1;
            if (currentLocale !== DEFAULT_LOCALE && appFallback[c] === undefined) {
              missingCountByKeyForDefaultLocale[c] =
                (missingCountByKeyForDefaultLocale[c] || 0) + 1;
            }
          });

          console.log('Report missing APP translations for current locale');
          await apolloClient.mutate<
            ReportMissingTranslationsMutation,
            ReportMissingTranslationsMutationVariables
          >({
            mutation: REPORT_MUTATION,
            variables: {
              input: {
                languageCode: currentLocale.toString(),
                context: TranslationContext.ConnectApp,
                keys: Object.entries(missingCountByKey).map(([key, count]) => ({
                  key,
                  count
                }))
              }
            }
          });

          if (currentLocale !== DEFAULT_LOCALE) {
            console.log('Report missing APP translations for default locale');
            await apolloClient.mutate<
              ReportMissingTranslationsMutation,
              ReportMissingTranslationsMutationVariables
            >({
              mutation: REPORT_MUTATION,
              variables: {
                input: {
                  languageCode: DEFAULT_LOCALE.toString(),
                  context: TranslationContext.ConnectApp,
                  keys: Object.entries(missingCountByKeyForDefaultLocale).map(([key, count]) => ({
                    key,
                    count
                  }))
                }
              }
            });
          }

          ref.current.reportedAppMessageKeys = [
            ...ref.current.reportedAppMessageKeys,
            ...appKeysUnreported
          ];
        } catch (err) {
          console.error(err);
        }
      }

      if (loginKeysUnreported.length > 0) {
        try {
          ref.current.missingLoginMessageKeys = [];

          const missingCountByKey: Record<string, number> = {};
          const missingCountByKeyForDefaultLocale: Record<string, number> = {};

          loginKeysUnreported.forEach((c) => {
            missingCountByKey[c] = (missingCountByKey[c] || 0) + 1;
            if (
              currentLocale !== DEFAULT_LOCALE &&
              (!loginFallback || loginFallback[c] === undefined)
            ) {
              missingCountByKeyForDefaultLocale[c] =
                (missingCountByKeyForDefaultLocale[c] || 0) + 1;
            }
          });

          console.log('Report missing LOGIN translations for current locale');
          await apolloClient.mutate<
            ReportMissingTranslationsMutation,
            ReportMissingTranslationsMutationVariables
          >({
            mutation: REPORT_MUTATION,
            variables: {
              input: {
                languageCode: currentLocale.toString(),
                context: TranslationContext.ConnectAppLogin,
                keys: Object.entries(missingCountByKey).map(([key, count]) => ({
                  key,
                  count
                }))
              }
            }
          });

          if (currentLocale !== DEFAULT_LOCALE) {
            console.log('Report missing LOGIN translations for default locale');

            await apolloClient.mutate<
              ReportMissingTranslationsMutation,
              ReportMissingTranslationsMutationVariables
            >({
              mutation: REPORT_MUTATION,
              variables: {
                input: {
                  languageCode: DEFAULT_LOCALE.toString(),
                  context: TranslationContext.ConnectAppLogin,
                  keys: Object.entries(missingCountByKeyForDefaultLocale).map(([key, count]) => ({
                    key,
                    count
                  }))
                }
              }
            });
          }

          ref.current.reportedLoginMessageKeys = [
            ...ref.current.reportedLoginMessageKeys,
            ...loginKeysUnreported
          ];
        } catch (err) {
          console.error(err);
        }
      }
    },
    1000 * 60 * 5
  ); // Check for any unreported missing keys every 5 minutes

  return { handleTranslationError: handleError };
}

export default useTranslationErrorHandler;
