import React from 'react';
import { useAppSelector } from 'redux/store.ts';
import { selectIsOnline } from 'redux/appSlice.ts';
import { Modal, Typography } from 'antd';
import { defineMessages } from 'react-intl';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import styled from 'styled-components';
import { PvmDino } from 'pvm-dino';

const messages = defineMessages({
  title: {
    id: 'offlineOverlay.title',
    defaultMessage: 'You are offline!'
  },
  message: {
    id: 'offlineOverlay.message',
    defaultMessage:
      'No internet connection found. Please check your connection and try again. Or press the spacebar to play a game!'
  }
});

const Game = styled.div`
  margin-top: -80px;
  z-index: 1;
`;

const Instructions = styled.div`
  flex: 65% 0 0;
  text-align: center;
  padding: 10px 40px;
`;

const OfflineOverlay: React.FC = () => {
  const isOnline = useAppSelector(selectIsOnline);
  const intl = useConnectIntl();
  return (
    <Modal
      title={intl.formatMsg(messages.title)}
      centered={true}
      open={isOnline === false}
      closable={false}
      footer={null}
      destroyOnClose={true}
    >
      <Game>
        <PvmDino />
        <Instructions>
          <Typography.Text>{intl.formatMsg(messages.message)}</Typography.Text>
        </Instructions>
      </Game>
    </Modal>
  );
};

export default OfflineOverlay;
