import { useMemo } from 'react';
import useEnumOptions, { EnumOptionsArgs } from 'i18n/useEnumOptions';
import { CheckboxOptionType } from 'antd';

export interface RadioOption<TEnum> extends CheckboxOptionType {
  typedValue: TEnum;
  translationKey: string;
}

/***
 * Helper to convert TypeScript enum to ant radio option array
 * @param options
 */
export function useEnumRadioOptions<TEnum extends string | number>(
  options: EnumOptionsArgs<TEnum>
) {
  const items = useEnumOptions(options);
  return useMemo(() => {
    return items.map<RadioOption<TEnum>>((item) => ({
      typedValue: item.typedValue,
      translationKey: item.translationKey,
      label: item.label,
      value: item.rawValue
    }));
  }, [items]);
}
