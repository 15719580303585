import React from 'react';
import styled from 'styled-components';
import useIsMobile from 'layouts/responsive/useIsMobile';
import Link from 'components/lib/Link/Link';

interface Props {
  icon: React.ReactNode;
  title: string;
  isSelected: boolean;
  url: string;
}

const NavLink = styled(Link)<{
  $isSelected: boolean;
}>`
  &&& {
    border: none;
    background-color: ${(props) =>
      props.$isSelected ? props.theme.ant.colorBgContainer : 'transparent'};
    color: ${(props) =>
      props.$isSelected
        ? props.theme.ant.colorText
        : props.theme.isDark
          ? props.theme.ant.colorText
          : props.theme.ant.colorBgBase};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;

    height: calc(56px + 8px);
    cursor: pointer;
    transition:
      color,
      background-color 0.15s ease-in-out;

    &:hover {
      color: ${(props) => (props.$isSelected ? props.theme.ant.colorText : '#fff')};

      background-color: ${(props) =>
        props.$isSelected ? props.theme.ant.colorBgContainer : 'rgba(0, 0, 0, 0.06)'};
    }
  }
`;

const Icon = styled.div<{ $isMobile?: boolean }>`
  font-size: 21px;
  padding-top: ${(props) => (props.$isMobile ? '2px' : '4px')};
  height: 32px;
`;

const Title = styled.div<{ $isMobile?: boolean }>`
  font-size: ${(props) => (props.$isMobile ? '9px' : '10px')};
  overflow: hidden;
  padding-bottom: ${(props) => (props.$isMobile ? '0' : '6px')};
`;

const MainNavLink: React.FC<Props> = (props) => {
  const isMobile = useIsMobile();

  return (
    <NavLink $isSelected={props.isSelected} to={props.url}>
      <Icon $isMobile={isMobile}>{props.icon}</Icon>
      <Title $isMobile={isMobile}>{props.title}</Title>
    </NavLink>
  );
};

export default MainNavLink;
