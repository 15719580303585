import React, { CSSProperties, ReactNode, useCallback, useMemo } from 'react';
import { Components, ItemProps, Virtuoso, VirtuosoHandle, VirtuosoProps } from 'react-virtuoso';
import { useScrollContext } from 'layouts/MainLayout/ScrollContext.tsx';
import Spinner from 'components/lib/Spinner/Spinner.tsx';
import styled, { css } from 'styled-components';
import { Empty } from 'antd';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import commonMessages from 'components/i18n/commonMessages.ts';
import useIsMobile from 'layouts/responsive/useIsMobile.ts';

interface Props<TData extends Record<string, unknown>> {
  parentScrollKey: string;
  style?: CSSProperties;
  data?: TData[];
  loading?: boolean;
  renderItem: (index: number, item: TData, context: VirtualListContextPublic) => React.ReactNode;
  onLoadMoreData?: () => Promise<void>;
  showSeparatorLines?: boolean;
  addPadding?: boolean;
  emptyPlaceholderText?: string;
  header?: (context: VirtualListContextPublic) => ReactNode;
  footer?: (context: VirtualListContextPublic) => ReactNode;
}

interface VirtualListContext {
  loadingInternal: boolean;
  showSeparatorLines: boolean;
  addPadding: boolean;
  empty: boolean;
  emptyPlaceholderText?: string;
  scrolling: boolean;
  header?: (context: VirtualListContext) => ReactNode;
  footer?: (context: VirtualListContext) => ReactNode;
}

type ExposedVirtuosoProps<TData extends Record<string, unknown>> = Pick<
  VirtuosoProps<TData, VirtualListContext>,
  | 'defaultItemHeight'
  | 'fixedItemHeight'
  | 'atBottomThreshold'
  | 'increaseViewportBy'
  | 'overscan'
  | 'scrollSeekConfiguration'
>;

type VirtualListContextPublic = Omit<VirtualListContext, 'header' | 'footer'>;

export const ParentScrollKeyMobile = 'mobile';

const CustomListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomList = React.forwardRef<HTMLDivElement, { context: VirtualListContext }>(
  (props, ref) => {
    const { context, ...rest } = props;
    return <CustomListContainer ref={ref} className={`connect-virtual-list`} {...rest} />;
  }
);
CustomList.displayName = 'CustomList';

const CustomListItem = styled.div<{ $isLast: boolean; $showLines: boolean; $addPadding: boolean }>`
  // NOTE: We can not use CSS last-child here because of virtual list height calculation

  box-sizing: border-box;
  ${(props) =>
    props.$addPadding &&
    css`
      padding-top: 12px;
    `}
  ${(props) =>
    props.$addPadding &&
    !props.$isLast &&
    css`
      padding-bottom: 12px;
    `}
  position: relative;
  ${(props) =>
    props.$showLines &&
    !props.$isLast &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${props.theme.ant.colorBorderSecondary};
        z-index: 1;
      }
    `}
`;

const CustomItem = React.memo(
  React.forwardRef<HTMLDivElement, ItemProps<unknown> & { context?: VirtualListContext }>(
    (props, ref) => {
      const { context, item, children, ...rest } = props;
      return (
        <CustomListItem
          ref={ref}
          className={'connect-virtual-list-item'}
          $isLast={false} // TODO: last item will have a border now, if we fix this the height must be the same with/without border!
          $showLines={props.context?.showSeparatorLines || false}
          $addPadding={props.context?.addPadding || false}
          {...rest}
        >
          {children}
        </CustomListItem>
      );
    }
  )
) as <TData>(props: ItemProps<TData> & { ref?: React.Ref<HTMLDivElement> }) => ReactNode;
(CustomItem as React.ForwardRefExoticComponent<unknown>).displayName = 'CustomListItem';

const CustomFooter: Components<unknown, VirtualListContext>['Footer'] = React.forwardRef<
  HTMLDivElement,
  { context: VirtualListContext }
>((props, ref) => {
  const intl = useConnectIntl();

  if (props.context?.footer) {
    return <div ref={ref}>{props.context.footer(props.context)}</div>;
  }

  if (props.context?.loadingInternal === true) {
    return (
      <div
        ref={ref}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: 16,
          paddingBottom: 16
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (props.context?.empty === true) {
    return (
      <div
        ref={ref}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: 16,
          paddingBottom: 16
        }}
      >
        <Empty
          description={props.context?.emptyPlaceholderText || intl.formatMsg(commonMessages.empty)}
        />
      </div>
    );
  }

  return null;
});
CustomFooter.displayName = 'CustomFooter';

const CustomHeaderItem = styled.div`
  padding-bottom: 12px;
  border-block-end: 1px solid ${(props) => props.theme.ant.colorBorderSecondary};
`;

const CustomHeader: Components<unknown, VirtualListContext>['Header'] = React.forwardRef<
  HTMLDivElement,
  { context: VirtualListContext }
>((props, ref) => {
  if (props.context?.header) {
    return <CustomHeaderItem ref={ref}>{props.context.header(props.context)}</CustomHeaderItem>;
  }
  return null;
});
CustomHeader.displayName = 'CustomHeader';

/*
const CustomScrollSeekPlaceholder: Components<
  unknown,
  VirtualListContext
>['ScrollSeekPlaceholder'] = React.forwardRef<HTMLDivElement, { context?: VirtualListContext }>(
  (props, ref) => {
    return (
      <CustomListItem
        ref={ref}
        className={'connect-virtual-list-item'}
        $isLast={false}
        $showLines={props.context?.showSeparatorLines || false}
        $addPadding={props.context?.addPadding || false}
        {...props}
      />
    );
  }
);
CustomScrollSeekPlaceholder.displayName = 'CustomScrollSeekPlaceholder';
*/

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomEmptyPlaceholder = React.forwardRef<HTMLDivElement, { $context?: VirtualListContext }>(
  (props, ref) => {
    if (props.$context?.loadingInternal === true) {
      return null;
    }

    return (
      <CenteredContainer ref={ref}>
        <Empty />
      </CenteredContainer>
    );
  }
);
CustomEmptyPlaceholder.displayName = 'CustomEmptyPlaceholder';

/*****
 * A reusable VirtualList component based on react-virtuoso
 * It will use the scrollRef from the ScrollContext to sync scrolling with the correct container
 * Styling will be similar to ant design's List component
 * Props will be similar to ant design's List component
 *
 * Performance tips:
 * - don't use margins, that could affect the measurement algorithm of the virtual list
 * - don't use antd Typography.Text with ellipsis, it measures dimensions per text (observer). Use regular CSS ellipsis
 * - don't do heavy calculations in LisItem components, use memoization and move this to the parent list
 * - consider hiding/replacing images or big svgs with placeholders when scrolling
 * ****/
const VirtualList = <TData extends Record<string, unknown>>({
  style,
  data,
  renderItem,
  onLoadMoreData,
  showSeparatorLines = true,
  addPadding = true,
  emptyPlaceholderText,
  footer,
  header,
  parentScrollKey,
  loading,
  ...rest
}: Props<TData> & ExposedVirtuosoProps<TData>) => {
  const { scrollRef } = useScrollContext();
  const isMobile = useIsMobile();
  const empty = !loading && data?.length === 0;
  const virtuosoRef = React.useRef<VirtuosoHandle>(null);
  const [loadingInternal, setLoadingInternal] = React.useState(false);
  const [scrolling, setScrolling] = React.useState(false);

  const handleEndReached = React.useCallback(async () => {
    if (onLoadMoreData) {
      try {
        setLoadingInternal(true);
        await onLoadMoreData();
      } catch (err) {
        console.error('error loading more data', err);
      } finally {
        setLoadingInternal(false);
      }
    }
  }, [onLoadMoreData]);

  // Here we set the custom scroll parent div. If mobile the entire viewport is scrollable, if it's desktop it depends on tabs etc.
  const customScrollParent = useMemo(() => {
    if (parentScrollKey === undefined) return undefined;
    if (scrollRef === undefined) return undefined;
    if (
      scrollRef.key === parentScrollKey ||
      (isMobile && scrollRef.key === ParentScrollKeyMobile)
    ) {
      return scrollRef.node || undefined;
    }
    return undefined;
  }, [parentScrollKey, scrollRef, isMobile]);

  const handleItemContent = useCallback(
    (index: number, item: TData, context: VirtualListContext) => {
      if (item === undefined) {
        return null;
      }
      return renderItem(index, item, context);
    },
    [renderItem]
  );

  const memoizedContext: VirtualListContext = useMemo(() => {
    return {
      loadingInternal: loadingInternal || loading || false,
      showSeparatorLines,
      addPadding,
      empty,
      emptyPlaceholderText,
      header,
      footer,
      scrolling
    };
  }, [
    loadingInternal,
    loading,
    showSeparatorLines,
    addPadding,
    empty,
    emptyPlaceholderText,
    header,
    footer,
    scrolling
  ]);

  return (
    <>
      {loading && !data && (
        <CenteredContainer style={{ height: 46 }}>
          <Spinner delayUntil={3000} />
        </CenteredContainer>
      )}
      {data && (
        <Virtuoso<TData, VirtualListContext>
          ref={virtuosoRef}
          customScrollParent={customScrollParent}
          style={style || { height: '100%' }}
          data={data}
          // logLevel={LogLevel.DEBUG} // Useful for debugging virtual list performance, see hidden console log!
          itemContent={handleItemContent}
          endReached={handleEndReached}
          atBottomThreshold={20} // default = 4
          context={memoizedContext}
          components={{
            List: CustomList,
            Item: CustomItem,
            Footer: CustomFooter,
            Header: CustomHeader
            // ScrollSeekPlaceholder: CustomScrollSeekPlaceholder
          }}
          isScrolling={setScrolling}
          {...rest}
        />
      )}
    </>
  );
};

export default VirtualList;
