import React from 'react';
import { Button, Result, Space } from 'antd';
import { defineMessages } from 'react-intl';
import Link from 'components/lib/Link/Link';
import styled from 'styled-components';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { ApolloError } from '@apollo/client';
import { getFriendlyApolloErrorMessages, getStatusCode } from 'graphql/apollo/apolloErrorUtil.ts';
import ErrorIllustration from 'components/illustrations/ErrorIllustration.tsx';
import NotFoundIllustration from 'components/illustrations/NotFoundIllustration.tsx';
import NotAuthorizedIllustration from 'components/illustrations/NotAuthorizedIllustration.tsx';
import commonMessages from 'components/i18n/commonMessages.ts';
import Page from 'components/lib/page/Page/Page.tsx';

const messages = defineMessages({
  title: {
    id: 'error_page.title',
    defaultMessage: 'An error occurred'
  }
});

const ResultContainer = styled.div`
  flex: 1 1 auto;
  padding-top: 128px;
`;

const ErrorPage: React.FC<{ error?: ApolloError }> = ({ error }) => {
  const intl = useConnectIntl();
  const friendlyErrorMessages = error ? getFriendlyApolloErrorMessages(error) : undefined;

  const statusCode = getStatusCode(error);

  return (
    <Page
      title={{
        pageTitle: intl.formatMsg(messages.title)
      }}
    >
      <ResultContainer>
        <Result
          icon={
            statusCode === 403 ? (
              <NotAuthorizedIllustration width={300} />
            ) : statusCode === 404 ? (
              <NotFoundIllustration width={300} />
            ) : (
              <ErrorIllustration width={300} />
            )
          }
          extra={
            <Space direction={'vertical'} align={'center'}>
              <Button onClick={() => window.location.reload()} size={'small'} type={'link'}>
                {intl.formatMsg(commonMessages.reload)}
              </Button>
              <Link to={'/'}>{intl.formatMsg(commonMessages.mainPageLink)}</Link>
            </Space>
          }
          subTitle={<div>{friendlyErrorMessages?.map((line) => <div key={line}>{line}</div>)}</div>}
          title={intl.formatMsg(messages.title)}
        />
      </ResultContainer>
    </Page>
  );
};

export default ErrorPage;
