import { useAppDispatch, useAppSelector } from 'redux/store.ts';
import { useCallback } from 'react';
import {
  addPendingRetailerChange,
  flushPendingRetailerChanges,
  selectHasPendingRetailerChanges
} from 'redux/deploySlice.ts';

function useRetailerDeployTools() {
  const dispatch = useAppDispatch();
  const hasPendingChanges = useAppSelector(selectHasPendingRetailerChanges);

  const add = useCallback(
    (retailerId: number) => {
      dispatch(addPendingRetailerChange(retailerId));
    },
    [dispatch]
  );

  const flush = useCallback(() => {
    if (hasPendingChanges) {
      dispatch(flushPendingRetailerChanges());
    }
  }, [dispatch, hasPendingChanges]);

  return {
    addPendingRetailerChange: add,
    flushPendingRetailerChanges: flush
  };
}

export default useRetailerDeployTools;
