import { Segmented, SegmentedProps } from 'antd';
import React, { useCallback } from 'react';
import { AimOutlined, CreditCardOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { defineMessages, useIntl } from 'react-intl';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo';
import { SegmentedOptions, SegmentedValue } from 'antd/es/segmented';
import styled from 'styled-components';
import { selectRootPermissions } from 'auth/authSlice.ts';
import { useAppSelector } from 'redux/store.ts';

export type TicketListDisplayMode = 'list' | 'map' | 'order-lines';

const messages = defineMessages({
  list: {
    id: 'ticket_list_display_mode_segmented.list',
    defaultMessage: 'List'
  },
  orderLines: {
    id: 'ticket_list_display_mode_segmented.orderLines',
    defaultMessage: 'Order lines'
  },
  map: {
    id: 'ticket_list_display_mode_segmented.map',
    defaultMessage: 'Map'
  }
});

interface Props {
  value?: TicketListDisplayMode;
  onChange?: (value: TicketListDisplayMode) => void;
}

type TicketListDisplaySegmentMode = 'list' | 'map' | 'order-lines';
type OtherSegmentedProps = Omit<
  SegmentedProps<TicketListDisplaySegmentMode>,
  'options' | 'onChange'
>;

const StyledSegmented = styled(Segmented<TicketListDisplaySegmentMode>)`
  && {
    .ant-segmented-item-icon {
      margin-right: 4px;
    }
  }
`;

const ItemContainer = styled.div`
  display: flex;
  gap: 4px;
`;

const TicketListDisplayModeSegmented: React.FC<Props & OtherSegmentedProps> = (props) => {
  const { value, onChange, size, block } = props;
  const { isDesktop } = useScreenInfo();
  const intl = useIntl();
  const rootPermissions = useAppSelector(selectRootPermissions);
  const canViewOrderLines = rootPermissions?.canViewTicketOrderLines || false;

  const handleChange = useCallback(
    (value: SegmentedValue) => {
      if (value === 'list') {
        onChange?.('list');
      } else if (value === 'order-lines') {
        onChange?.('order-lines');
      } else if (value === 'map') {
        onChange?.('map');
      }
    },
    [onChange]
  );

  const op: SegmentedOptions<TicketListDisplaySegmentMode> = [
    {
      value: 'list',
      label: (
        <ItemContainer>
          <div>
            <UnorderedListOutlined />
          </div>
          {isDesktop && intl.formatMessage(messages.list)}
        </ItemContainer>
      )
    },
    {
      value: 'map',
      label: (
        <ItemContainer>
          <div>
            <AimOutlined />
          </div>
          {isDesktop && intl.formatMessage(messages.map)}
        </ItemContainer>
      )
    }
  ];

  if (canViewOrderLines) {
    op.push({
      value: 'order-lines',
      label: (
        <ItemContainer>
          <div>
            <CreditCardOutlined />
          </div>
          {isDesktop && intl.formatMessage(messages.orderLines)}
        </ItemContainer>
      )
    });
  }

  return (
    <StyledSegmented options={op} value={value} onChange={handleChange} size={size} block={block} />
  );
};

export default TicketListDisplayModeSegmented;
