import React from 'react';
import { Col, Typography } from 'antd';
const { Text } = Typography;
import { InspectionFormFieldItemFragment, OperatorInspectionValues } from 'generated/types';
import styled from 'styled-components';
import { defineMessages } from 'react-intl';
import { parseISO } from 'date-fns';
import ChangedByUser from 'components/user/ChangedByUser/ChangedByUser.tsx';
import Select from 'components/lib/Select/Select';
import { useEnumSelectOptions } from 'components/lib/Select/useEnumSelectOptions';
import { SelectProps, SelectValue } from 'antd/es/select';
import { Message } from 'typescript-react-intl';
import useConnectIntl from 'i18n/useConnectIntl.ts';

interface Props {
  field: InspectionFormFieldItemFragment;
  loading?: boolean;
  onChange?: (value: OperatorInspectionValues) => void;
}

const SelectCol = styled(Col)`
  &&& {
    display: flex;
    flex-direction: column;
  }
`;

const UserCol = styled(Col)`
  &&& {
    display: flex;
    justify-content: flex-end;

    align-items: flex-start; // top or center?
    padding-top: 24px;

    flex: 1 0 auto;
  }
`;

// Strongly typed enum value in onSelect:
function TypedSelect<T extends SelectValue = OperatorInspectionValues>({
  children,
  ...props
}: SelectProps<T>) {
  return <Select<T> {...props}>{children}</Select>;
}

type OperatorInspectionMessages = {
  [key in OperatorInspectionValues]: Message;
};
const messages: OperatorInspectionMessages = {
  [OperatorInspectionValues.NotConsidered]: {
    id: 'inspection_form_field_not_considered',
    defaultMessage: 'Not considered'
  },
  [OperatorInspectionValues.DeviationCorrected]: {
    id: 'inspection_form_field_deviaiton_corrected',
    defaultMessage: 'Deviation corrected'
  },
  [OperatorInspectionValues.DeviationNotCorrected]: {
    id: 'inspection_form_field_not_corrected',
    defaultMessage: 'Deviation, not corrected'
  },
  [OperatorInspectionValues.NotConsideredGracePeriod]: {
    id: 'inspection_form_field_deviation_not_corrected_grace_period',
    defaultMessage: 'Deviation not corrected grace period'
  },
  [OperatorInspectionValues.NoDeviationFound]: {
    id: 'inspection_form_field_no_deviation_found',
    defaultMessage: 'No deviation found'
  }
};

const operatorInspectionMessages = defineMessages(messages);

const InspectionFormField: React.FC<Props> = (props) => {
  const { field, loading, onChange } = props;
  const intl = useConnectIntl();
  const options = useEnumSelectOptions<OperatorInspectionValues>({
    enumObject: OperatorInspectionValues,
    customLabelMessages: operatorInspectionMessages
  });

  const laban = intl.formatMsg({
    id: field.label
  });

  const helpText = field.description
    ? intl.formatMsg({
        id: field.description
      })
    : undefined;

  return (
    <>
      <SelectCol xs={20}>
        <Text>{laban}</Text>
        <TypedSelect
          value={field.inspectionValue}
          options={options}
          onSelect={(value: OperatorInspectionValues) => onChange?.(value)}
          loading={loading}
          style={{
            width: '100%'
          }}
        />
        <Text type={'secondary'}>{helpText}</Text>
      </SelectCol>
      <UserCol xs={4}>
        {field.changedByUser && field.changed && (
          <ChangedByUser user={field.changedByUser} changed={parseISO(field.changed)} />
        )}
      </UserCol>
    </>
  );

  // return (
  //   <Form.Item
  //     label={laban}
  //     help={helpText && <HelpContainer>{helpText}</HelpContainer>}
  //     extra={
  //       <ChangedContainer>
  //         {field.changedByUser && field.changed && (
  //           <FormFieldChangedByUser
  //             user={field.changedByUser}
  //             changed={parseISO(field.changed)}
  //             showText={true}
  //           />
  //         )}
  //       </ChangedContainer>
  //     }
  //   >
  //     <TypedSelect
  //       value={field.inspectionValue}
  //       options={options}
  //       onSelect={(value: OperatorInspectionValues) => onChange?.(value)}
  //       loading={loading}
  //     />
  //   </Form.Item>
  // );
};

export default InspectionFormField;
