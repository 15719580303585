import React, { useCallback, useMemo } from 'react';
import { gql } from '@apollo/client/core';
import {
  ArticleListItemFragment,
  ArticleListItemFragmentDoc,
  TicketArticleGroupFullFragment,
  useGetTicketOrderLineArticlesQuery
} from 'generated/types';
import Select from 'components/lib/Select/Select';
import ArticleItem from 'components/ticket/TicketAccounting/ArticleItem';
import { DefaultOptionType, SelectProps } from 'antd/es/select';
import { FilterFunc } from 'rc-select/es/Select';

interface Props {
  value?: string;
  onChange?: (article: ArticleListItemFragment) => void;
}

type OtherSelectProps = Omit<SelectProps<string>, 'options' | 'value' | 'onChange'>;

gql`
  query GetTicketOrderLineArticles {
    allTicketArticles {
      ...ArticleListItem
    }
  }
  ${ArticleListItemFragmentDoc}
`;

const ArticleSelect: React.FC<Props & OtherSelectProps> = (props) => {
  const { value, onChange, ...rest } = props;

  const { data, loading } = useGetTicketOrderLineArticlesQuery({
    notifyOnNetworkStatusChange: true
  });

  const articlesById = useMemo(() => {
    const temp: Record<string, ArticleListItemFragment> = {};
    data?.allTicketArticles.forEach((c) => {
      temp[c.id] = c;
    });
    return temp;
  }, [data]);

  const handleChange = useCallback(
    (selVal: string) => {
      const item = data?.allTicketArticles.find((c) => c.id === selVal);
      if (!item) {
        return;
      }
      onChange?.(item);
    },
    [onChange, data?.allTicketArticles]
  );

  const filterOption: FilterFunc<DefaultOptionType> = (input, option) => {
    const articleId = option?.value;
    const article = articleId ? articlesById[articleId] : undefined;
    return (
      article?.name.toLowerCase().includes(input.toLowerCase()) ||
      article?.vendorId?.toLowerCase().includes(input.toLowerCase()) ||
      article?.vendor?.toLowerCase().includes(input.toLowerCase()) ||
      false
    );
  };

  const groupedOptions = useMemo<DefaultOptionType[] | undefined>(() => {
    if (!data) {
      return undefined;
    }

    const articleGroupLookup: Record<string, TicketArticleGroupFullFragment> = {};
    const grouped: Record<string, DefaultOptionType[]> = {};

    data.allTicketArticles.forEach((item) => {
      const key = item.group.id;

      if (item.disabled && item.id !== value) {
        return;
      }

      if (!grouped[key]) {
        grouped[key] = [];
        articleGroupLookup[key] = item.group;
      }

      grouped[key].push({
        value: item.id,
        disabled: item.disabled,
        label: (
          <ArticleItem
            article={item}
            showIcon={false}
            showVendorInfo={true}
            showDisabledIcon={true}
          />
        ),
        selectedLabel: (
          <ArticleItem
            article={item}
            showIcon={true}
            showVendorInfo={false}
            showDisabledIcon={false}
          />
        )
      });
    });

    return Object.keys(grouped).map((key) => {
      const articleType = articleGroupLookup[key];

      return {
        label: articleType.name,
        options: grouped[key]
      };
    });
  }, [data, value]);

  return (
    <Select
      value={loading ? undefined : value}
      loading={loading}
      style={{
        minWidth: 250
      }}
      onChange={handleChange}
      showSearch={!loading}
      optionFilterProp={'children'}
      filterOption={filterOption}
      optionLabelProp={'selectedLabel'}
      options={groupedOptions}
      {...rest}
    />
  );
};

export default ArticleSelect;
