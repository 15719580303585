import React, { useCallback, useMemo, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  Avatar,
  Button,
  Col,
  Divider,
  Flex,
  Form,
  message,
  Row,
  Skeleton,
  Space,
  Switch,
  Typography
} from 'antd';
import styled from 'styled-components';
import { parseISO } from 'date-fns';
import useDateFormatTools from 'i18n/useDateFormatTools';
import { InfoCircleOutlined } from '@ant-design/icons';
import TicketSeverityDescription from 'components/ticket/TicketSeverity/TicketSeverityDescription';
import UserSelect from 'components/user/UserSelect/UserSelect';
import UserAvatar from 'components/user/UserAvatar/UserAvatar';
import TicketForm from 'components/ticket/TicketForm/TicketForm';
import useFormatTools from 'i18n/useFormatTools';
import EditableTitle from 'components/lib/EditableTitle/EditableTitle';
import TicketDiscussion from 'components/ticket/TicketDiscussion/TicketDiscussion';
import TicketTypeSelectConnected from 'components/ticket/TicketType/TicketTypeSelectConnected';
import TicketTagsSelectConnected from 'components/ticket/TicketTagsSelect/TicketTagsSelectConnected';
import TicketSeveritySelectConnected from 'components/ticket/TicketSeverity/TicketSeveritySelectConnected';
import useMatrixNav from 'layouts/matrix/useMatrixNav';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import useIsMobile from 'layouts/responsive/useIsMobile';
import Badge from 'components/lib/Badge/Badge';
import { Tab } from 'rc-tabs/es/interface';
import commonMessages from 'components/i18n/commonMessages';
import parseNumber from 'util/parseNumber';
import { isTicketTab } from 'layouts/matrix/matrix';
import {
  DeletedUser,
  TicketStatus,
  useGetTicketQuery,
  useOnTicketUpdatedSubscription,
  UserListItemFragment,
  useSetTicketAssignedUserMutation,
  useSetTicketIsSubscribingMutation,
  useSetTicketTitleMutation
} from 'generated/types';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import TicketDueDateSelectConnected from 'components/ticket/TicketDueDate/TicketDueDateSelectConnected.tsx';
import TicketDueDateStatusText from 'components/ticket/TicketDueDate/TicketDueDateStatusText.tsx';
import useTicketFormatTools from 'components/ticket/useTicketFormatTools.ts';
import ResponsiveListCard from 'components/lib/List/ResponsiveListCard.tsx';
import TicketStatusIcon from 'components/ticket/TicketStatusIcon/TicketStatusIcon.tsx';
import OrderLineList from 'components/ticket/TicketAccounting/OrderLineList/OrderLineList.tsx';
import Alert from 'components/lib/Alert/Alert.tsx';
import { TicketAlertDifferentSerialNo } from 'components/ticket/TicketList/TicketAlert.tsx';
import ServiceInfoTags from 'components/machine/ServiceInfoTags/ServiceInfoTags.tsx';
import PageTab from 'components/lib/page/PageTabs/PageTab.tsx';
import Page from 'components/lib/page/Page/Page.tsx';

const { Text } = Typography;

const LogoAndTitleContainer = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  position: relative;
  flex: 1 1 auto;
  min-height: ${(props) => (props.$isMobile ? ' 32px' : ' 80px')};
`;

const TicketAndStatusContainer = styled.div`
  flex: 1 1 auto;
`;

const TicketStatusContainer = styled.div<{ $isMobile?: boolean }>`
  height: ${(props) => (props.$isMobile ? ' 32px' : ' 80px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TicketTitle = styled(EditableTitle)`
  &&& {
    margin-bottom: 0.2em; // default .5em
  }
`;

// quick fix css bug in ant design? removing margin on form item:
const WatchForm = styled(Form)`
  &&& {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
`;

const TicketPage: React.FC = () => {
  const intl = useConnectIntl();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { formatUserName } = useFormatTools();
  const { machineId, getUrlToTicket } = useMatrixNav();
  const { formatDate } = useDateFormatTools();

  const { ticketId: ticketIdRaw, tab: tabRaw } = useParams();
  const ticketId = parseNumber(ticketIdRaw);
  const isValidTicketId = ticketId !== undefined;
  const activeTab = tabRaw || 'ticket';
  const tab = isTicketTab(activeTab) ? activeTab : undefined;
  const isValidTicketTab = !!tab;

  const { data, loading, error } = useGetTicketQuery({
    variables: ticketId
      ? {
          ticketId
        }
      : undefined,
    skip: !ticketId
    // fetchPolicy: 'cache-and-network',
  });

  // TODO: This works excellent!!
  // TODO: Refactor all subscribeToMore stuff!
  useOnTicketUpdatedSubscription({
    variables:
      ticketId && data
        ? {
            ticketId,
            machineId: data.ticket.machineIdRaw,
            retailerId: data.ticket.retailerIdRaw
          }
        : undefined,
    skip: !ticketId || !data
  });

  const [setAssignedUser, { loading: savingAssigned }] = useSetTicketAssignedUserMutation({
    notifyOnNetworkStatusChange: true
  });

  const [setIsSubscribing, { loading: savingIsSubscribing }] = useSetTicketIsSubscribingMutation({
    notifyOnNetworkStatusChange: true
  });

  const [setTitle] = useSetTicketTitleMutation({
    notifyOnNetworkStatusChange: true
  });

  const machineTitle = data?.ticket.machine.location || data?.ticket.machine.serialNo || undefined;

  const ticketUrl = ticketId ? getUrlToTicket(ticketId) : undefined;
  const titleRequired = data?.ticket.type.titleRequired || false;

  const handleTabChange = useCallback(
    (tabKey: string) => {
      if (!ticketUrl) {
        return;
      }
      navigate(`${ticketUrl}/${tabKey === 'ticket' ? '' : tabKey}`);
    },
    [navigate, ticketUrl]
  );

  const handleAssignToMe = useCallback(async () => {
    if (!ticketId || !data?.me.id) {
      return;
    }
    try {
      await setAssignedUser({
        variables: {
          input: {
            ticketId: ticketId,
            assignedUserId: data?.me.id
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          setTicketAssignedUser: {
            __typename: 'SetTicketAssignedUserPayload',
            ticket: data
              ? {
                  id: data.ticket.id,
                  ticketId: data.ticket.ticketId,
                  __typename: 'Ticket',
                  assignedUserId: data?.me.id || null,
                  comments: [...(data?.ticket.comments || [])],
                  assignedTo: {
                    id: data.me.id,
                    userId: data.me.userId,
                    active: true,
                    __typename: 'User',
                    name: data.me.name,
                    isMe: data.me.isMe,
                    title: data.me.title
                  },
                  isSubscribing: data.ticket.isSubscribing,
                  subscribers: data.ticket.subscribers
                }
              : undefined
          }
        }
      });
    } catch (e) {
      message.error(
        intl.formatMessage({
          id: 'ticket_page.error_assign_to_me',
          defaultMessage: 'Could not assign ticket to me'
        })
      );
    }
  }, [ticketId, data, setAssignedUser, intl]);

  const handleChangeAssignedUser = useCallback(
    async (value?: string, user?: UserListItemFragment) => {
      if (!ticketId) {
        return;
      }

      try {
        await setAssignedUser({
          variables: {
            input: {
              ticketId: ticketId,
              assignedUserId: value
            }
          },
          optimisticResponse: {
            __typename: 'Mutation',
            setTicketAssignedUser: {
              __typename: 'SetTicketAssignedUserPayload',
              ticket: data
                ? {
                    id: data.ticket.id,
                    ticketId: data.ticket.ticketId,
                    __typename: 'Ticket',
                    assignedUserId: value || null,
                    comments: [...(data?.ticket.comments || [])],
                    isSubscribing: data.ticket.isSubscribing,
                    subscribers: data.ticket.subscribers,
                    assignedTo:
                      user === undefined
                        ? null
                        : {
                            id: user.id,
                            userId: user.userId,
                            active: true,
                            __typename: 'User',
                            name: user.name,
                            isMe: user.isMe,
                            title: user.title
                          }
                  }
                : undefined
            }
          }
        });
      } catch (err: unknown) {
        message.error(
          intl.formatMessage({
            id: 'ticket_page.error_set_assigned_user',
            defaultMessage: 'Could not set assigned user'
          })
        );
      }
    },
    [ticketId, intl, setAssignedUser, data]
  );

  const handleChangeWatch = useCallback<SwitchChangeEventHandler>(
    async (checked) => {
      if (!ticketId) {
        return;
      }

      try {
        await setIsSubscribing({
          variables: {
            input: {
              ticketId: ticketId,
              watch: checked
            }
          },
          notifyOnNetworkStatusChange: true
        });
      } catch (err: unknown) {
        message.error(
          intl.formatMessage({
            id: 'ticket_page.error_set_subscribing',
            defaultMessage: 'Could not update ticket'
          })
        );
      }
    },
    [intl, setIsSubscribing, ticketId]
  );

  // NOTE: This is used to edit an empty title when ticket.title is null and rendered with the ticket type as title.
  const [overrideTicketTitleEmptyEdit, setOverrideTicketTitleEmptyEdit] = useState<
    string | undefined
  >(undefined);

  const { formatTicketTitleWithFallback } = useTicketFormatTools();
  const ticketTitleOrFallbackToI18n = formatTicketTitleWithFallback(data?.ticket);

  const ticketTitleWithFallback = useMemo(() => {
    if (overrideTicketTitleEmptyEdit !== undefined) {
      return overrideTicketTitleEmptyEdit;
    }

    return ticketTitleOrFallbackToI18n;
  }, [overrideTicketTitleEmptyEdit, ticketTitleOrFallbackToI18n]);

  const handleStartEditTitle = useCallback(() => {
    if (!data) {
      return;
    }

    if (data.ticket.title === null || data.ticket.title === undefined) {
      setOverrideTicketTitleEmptyEdit('');
    } else {
      setOverrideTicketTitleEmptyEdit(undefined);
    }
  }, [data, setOverrideTicketTitleEmptyEdit]);

  const handleCancelEditTitle = useCallback(() => {
    setOverrideTicketTitleEmptyEdit(undefined);
  }, [setOverrideTicketTitleEmptyEdit]);

  const handleEndEditTitle = useCallback(() => {
    setOverrideTicketTitleEmptyEdit(undefined);
  }, [setOverrideTicketTitleEmptyEdit]);

  const handleChangeTitle = useCallback(
    async (value: string) => {
      if (data && titleRequired && value.trim().length === 0) {
        message.error({
          key: 'error-title-required',
          content: intl.formatMessage(
            {
              id: 'ticket_page.error_title_required_ticket',
              defaultMessage: 'Title is required for ticket type {ticketType}'
            },
            {
              ticketType: intl.formatMsg({
                id: data.ticket.type.nameKeyI18n
              })
            }
          )
        });
        return;
      }

      setOverrideTicketTitleEmptyEdit(undefined);
      const updatedTitle = value.trim().length === 0 ? null : value.trim();

      try {
        if (!ticketId) {
          return;
        }
        if (updatedTitle === data?.ticket.title) {
          return;
        }

        await setTitle({
          variables: {
            input: {
              ticketId: ticketId,
              title: updatedTitle
            }
          },

          optimisticResponse: data
            ? {
                __typename: 'Mutation',
                setTicketTitle: {
                  id: data.ticket.id,
                  ticketId: data.ticket.ticketId,
                  title: updatedTitle,
                  __typename: 'Ticket',
                  comments: [...(data.ticket.comments || [])]
                }
              }
            : undefined
        });
      } catch (err: unknown) {
        message.error(
          intl.formatMessage({
            id: 'ticket_page.error_set_ticket_title',
            defaultMessage: 'Could not set ticket title'
          })
        );
      }
    },
    [setTitle, intl, ticketId, data, titleRequired]
  );

  const showAccountingWarningDot = data !== undefined && !data.ticket.hasOrderLines;

  const accountingTitle = (
    <div>
      {intl.formatMessage({
        id: 'ticket_page.ticket_tab_order_lines',
        defaultMessage: 'Order lines'
      })}
    </div>
  );

  const accountingTab = showAccountingWarningDot ? (
    <Badge dot={true} status={'error'} size={'small'}>
      {accountingTitle}
    </Badge>
  ) : (
    accountingTitle
  );

  if (!isValidTicketTab) {
    return <Navigate to={`/ticket/${ticketId}`} />;
  }

  const isAssignedToMe = data?.ticket.assignedUserId === data?.me.id;

  const showDifferentSerialNoWarning =
    data && data.ticket.serialNo !== data.ticket.machine.serialNo;

  const tabs: Tab[] = [
    {
      key: 'ticket',
      active: activeTab === 'ticket',
      label: intl.formatMessage({
        id: 'ticket_page.ticket_tab_ticket',
        defaultMessage: 'Ticket'
      }),
      children: (
        <PageTab style={{ paddingBottom: 64 }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={16}>
              <Row gutter={[16, 16]}>
                {showDifferentSerialNoWarning && (
                  <Col span={24}>
                    <TicketAlertDifferentSerialNo
                      ticketSerialNo={data?.ticket.serialNo || ''}
                      machineSerialNo={data?.ticket.machine.serialNo || ''}
                      style={{ marginBottom: 16 }}
                    />
                  </Col>
                )}

                {data?.ticket.hasForm && (
                  <Col span={24}>
                    <ResponsiveListCard
                      title={
                        data.ticket.type.nameKeyI18n
                          ? intl.formatMsg({
                              id: data.ticket.type.nameKeyI18n
                            })
                          : undefined
                      }
                    >
                      {data && <TicketForm ticketId={data.ticket.ticketId} />}
                    </ResponsiveListCard>
                  </Col>
                )}
                <Col span={24}>{ticketId && <TicketDiscussion ticketId={ticketId} />}</Col>
              </Row>
            </Col>
            <Col xs={24} lg={8}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <ResponsiveListCard>
                    <Form layout={'vertical'}>
                      <Form.Item
                        label={intl.formatMessage({
                          id: 'ticket_page.label_ticket_severity',
                          defaultMessage: 'Severity'
                        })}
                        tooltip={{
                          title: <TicketSeverityDescription />,
                          icon: <InfoCircleOutlined />
                        }}
                      >
                        <TicketSeveritySelectConnected ticketId={data?.ticket.ticketId} />
                      </Form.Item>

                      <Form.Item
                        label={intl.formatMsg({
                          id: 'ticket_page.label_ticket_due_date',
                          defaultMessage: 'Due date'
                        })}
                        extra={
                          !loading &&
                          data?.ticket.status === TicketStatus.Open && (
                            <TicketDueDateStatusText
                              date={data?.ticket.due ? parseISO(data.ticket.due) : undefined}
                              showNoDueDateText={true}
                            />
                          )
                        }
                      >
                        <TicketDueDateSelectConnected ticketId={data?.ticket.ticketId} />
                      </Form.Item>

                      <Form.Item
                        label={intl.formatMessage({
                          id: 'ticket_page.label_ticket_assignee',
                          defaultMessage: 'Assignee'
                        })}
                        extra={
                          isAssignedToMe ? null : (
                            <Button onClick={handleAssignToMe} size={'small'} type={'link'}>
                              {intl.formatMsg({
                                id: 'ticket_page.button_assign_to_me',
                                defaultMessage: 'Assign to me'
                              })}
                            </Button>
                          )
                        }
                      >
                        <UserSelect
                          value={data?.ticket.assignedUserId || undefined}
                          onChange={handleChangeAssignedUser}
                          placeholder={intl.formatMessage({
                            id: 'ticket_page.placeholder_assigned_user',
                            defaultMessage: 'Assign a user'
                          })}
                          loading={savingAssigned}
                          popupMatchSelectWidth={false}
                          allowClear={true}
                        />
                      </Form.Item>

                      <Form.Item
                        label={intl.formatMessage({
                          id: 'ticket_page.label_ticket_type',
                          defaultMessage: 'Type'
                        })}
                      >
                        <TicketTypeSelectConnected ticketId={data?.ticket.ticketId} />
                      </Form.Item>

                      <Form.Item
                        label={intl.formatMessage({
                          id: 'ticket_page.label_ticket_tags',
                          defaultMessage: 'Tags'
                        })}
                      >
                        <TicketTagsSelectConnected ticketId={ticketId} />
                      </Form.Item>
                    </Form>

                    <Divider />

                    <Space direction={'vertical'}>
                      <WatchForm layout={'vertical'}>
                        <Form.Item
                          label={intl.formatMessage({
                            id: 'ticket_page.label_ticket_watch',
                            defaultMessage: 'Watch'
                          })}
                        >
                          <Switch
                            checked={data?.ticket.isSubscribing}
                            onChange={handleChangeWatch}
                            loading={savingIsSubscribing || loading}
                            checkedChildren={intl.formatMessage(commonMessages.on)}
                            unCheckedChildren={intl.formatMessage(commonMessages.off)}
                          />
                        </Form.Item>
                      </WatchForm>
                      <Space direction={'vertical'}>
                        <Text>
                          {data && data.ticket.subscribers.length > 0 && (
                            <FormattedMessage
                              id={'ticket_page.ticket_participants_count'}
                              defaultMessage={'{count} watching'}
                              values={{
                                count: data.ticket.subscribers.length
                              }}
                            />
                          )}
                        </Text>
                        <Avatar.Group max={{ count: 4 }} size={'default'}>
                          {data?.ticket.subscribers.map((user, index) => (
                            <UserAvatar
                              key={'usr' + index}
                              user={user as UserListItemFragment | DeletedUser}
                              size={'default'}
                            />
                          ))}
                          {!data && <Skeleton.Avatar size={'default'} active={true} />}
                        </Avatar.Group>
                      </Space>
                    </Space>
                  </ResponsiveListCard>
                </Col>
              </Row>
            </Col>
          </Row>
        </PageTab>
      )
    },
    {
      key: 'accounting',
      label: accountingTab,
      active: activeTab === 'accounting',
      children: (
        <PageTab>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <OrderLineList ticketId={ticketId} />
            </Col>
          </Row>
        </PageTab>
      )
    }
  ];

  const ticketOpenOrCloseText = !data ? null : (
    <Text type={'secondary'}>
      {data.ticket.status === 'OPEN' && (
        <FormattedMessage
          id={'ticket_page.ticket_opened_by'}
          defaultMessage={'#{ticketId} opened by {name} {date}'}
          values={{
            name: formatUserName(data.ticket.openedBy),
            ticketId: ticketId,
            date: formatDate(parseISO(data.ticket.opened), {
              representation: 'complete',
              formatTime: 'HH:mm'
            })
          }}
        />
      )}
      {data.ticket.status === 'CLOSED' && (
        <FormattedMessage
          id={'ticket_page.ticket_closed'}
          defaultMessage={'#{ticketId} closed by {name} {date}'}
          values={{
            name: formatUserName(data.ticket.closedBy),
            ticketId: ticketId,
            date: formatDate(parseISO(data.ticket.opened), {
              representation: 'complete',
              formatTime: 'HH:mm'
            })
          }}
        />
      )}
    </Text>
  );

  const ticketIdString = data?.ticket.ticketId ? `#${data?.ticket.ticketId} ` : '';
  const pageTitle = `${ticketIdString}${ticketTitleWithFallback}`;

  return (
    <Page
      title={{
        pageTitle: pageTitle,
        headTitleParts: [pageTitle, machineTitle]
      }}
      showBreadcrumbs={true}
      header={
        <Flex gap={16} align={isMobile ? 'flex-start' : 'center'} vertical={isMobile}>
          <LogoAndTitleContainer $isMobile={isMobile}>
            {!data && loading && (
              <Skeleton
                title={false}
                active={true}
                paragraph={{ rows: 2, style: { width: 350 } }}
              />
            )}
            {error && <Alert message={error.message} type={'error'} />}
            {!isValidTicketId && <Alert message={'Invalid ticket id'} type={'error'} />}
            {data && !isMobile && (
              <TicketAndStatusContainer>
                <TicketTitle
                  level={1}
                  editable={{
                    onChange: handleChangeTitle,
                    triggerType: ['text', 'icon'],
                    onStart: handleStartEditTitle,
                    onCancel: handleCancelEditTitle,
                    onEnd: handleEndEditTitle
                  }}
                >
                  {ticketTitleWithFallback}
                </TicketTitle>

                <Space>
                  <TicketStatusIcon status={data.ticket.status} showText={true} />
                  {ticketOpenOrCloseText}
                </Space>
              </TicketAndStatusContainer>
            )}
            {data && isMobile && (
              <Space size={'small'}>
                <TicketStatusContainer $isMobile={isMobile}>
                  <TicketStatusIcon status={data.ticket.status} showText={false} />
                </TicketStatusContainer>
                <TicketTitle
                  level={3}
                  editable={{
                    triggerType: ['text'],
                    onChange: handleChangeTitle
                  }}
                >
                  {ticketTitleWithFallback}
                </TicketTitle>
              </Space>
            )}
          </LogoAndTitleContainer>
          <ServiceInfoTags machineId={machineId} direction={isMobile ? 'horizontal' : 'vertical'} />
        </Flex>
      }
      tabs={{
        onChange: handleTabChange,
        defaultActiveKey: activeTab,
        activeKey: activeTab,
        items: tabs
      }}
    />
  );
};

export default TicketPage;
