import { createBrowserRouter, useLocation } from 'react-router-dom';
import LoginPage from 'pages/auth/LoginPage/LoginPage';
import RequireAuthenticated from 'auth/RequireAuthenticated.tsx';
import MainLayout from 'layouts/MainLayout/MainLayout';
import PreferencesPage from 'pages/user/PreferencesPage/PreferencesPage';
import NotificationsPage from 'pages/user/NotificationsPage/NotificationsPage';
import RootPage from 'pages/matrix/RootPage/RootPage';
import RetailerPage from 'pages/matrix/RetailerPage/RetailerPage';
import MachinePage from 'pages/matrix/MachinePage/MachinePage';
import TicketPage from 'pages/operational/TicketPage/TicketPage';
import CreateTicketPage from 'pages/operational/CreateTicketPage/CreateTicketPage';
import CreateRetailerPage from 'pages/operational/CreateRetailerPage/CreateRetailerPage';
import Rainbow from 'layouts/components/Rainbow/Rainbow';
import React, { useEffect } from 'react';
import NotFoundPage from 'pages/util/NotFoundPage/NotFoundPage';
import * as Sentry from '@sentry/react';
import { matchRoutes } from '@remix-run/router';
const TranslationPage = React.lazy(
  () => import('pages/operational/TranslationPage/TranslationPage')
);
import { useNavigationType, createRoutesFromChildren } from 'react-router-dom';
import { appVersion } from 'common/appVersion.ts';
import { appConfig } from 'common/appConfig.ts';
import MePage from 'pages/auth/MePage/MePage.tsx';
import RequireRetailerAccess from 'auth/RequireRetailerAccess.tsx';
import RequireMachineAccess from 'auth/RequireMachineAccess.tsx';
import RequireRootPermission from 'auth/RequireRootPermission.tsx';
import LostPasswordPage from 'pages/auth/LostPasswordPage/LostPasswordPage.tsx';
import LoginLayout from 'layouts/LoginLayout/LoginLayout.tsx';
import RequireTicketAccess from 'auth/RequireTicketAccess';
import PredictionPage from 'pages/operational/PredictionPage/PredictionPage.tsx';
import Plausible from 'plausible-tracker';

console.log(`🕵️ sentry ${appVersion.enableSentry ? 'enabled' : 'disabled'}`);

Sentry.init({
  release: `connect-app@${appVersion.version}`,
  enabled: appVersion.enableSentry, // Only enabled in production, not staging or development
  environment: appConfig.ENVIRONMENT,
  tracePropagationTargets: ['localhost', 'https:api.connect.vendanor.com'],
  dsn: 'https://44909d7bb36e382d3e1e936ab8f1900c@o4505719174332416.ingest.sentry.io/4505719178723328',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, // 10% of transactions used for performance monitoring. 100% is not recommended in production
  replaysSessionSampleRate: 0, // we don't capture normal sessions
  replaysOnErrorSampleRate: 1 // we capture all sessions with errors
});

console.log('appConfig', appConfig, appVersion);
if (appConfig.ENABLE_PLAUSIBLE === 'true') {
  console.log('🕵️ Plausible enabled');
  const plausible = Plausible({
    domain: 'connect.vendanor.com',
    apiHost: 'https://plausible.vendanor.com'
  });
  plausible.enableAutoPageviews();
} else {
  console.log('🕵️ Plausible disabled');
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV6(createBrowserRouter);

/***
 * This is where we define our app routes.
 * NOTE: If we make changes here, check useMatrixNav.ts too.
 */
const router = sentryCreateBrowserRouter([
  {
    // Login layout route
    element: <LoginLayout />,
    children: [
      {
        path: 'login',
        element: <LoginPage />
      },
      {
        path: 'lost-password',
        element: <LostPasswordPage />
      },
      {
        path: 'reset-password/:token',
        element: <LostPasswordPage />
      }
    ]
  },
  {
    path: '*', // NOTE: this will never hit
    element: <NotFoundPage />
  },
  {
    // Protected Layout route, both in and outside matrix:
    element: (
      <RequireAuthenticated>
        <MainLayout />
      </RequireAuthenticated>
    ),
    children: [
      // OUTSIDE matrix
      {
        path: 'preferences',
        children: [
          {
            path: ':tab?',
            element: <PreferencesPage />
          }
        ]
      },
      {
        path: 'notifications',
        children: [
          {
            path: ':tab?',
            element: <NotificationsPage />
          }
        ]
      },
      {
        path: 'i18n',
        children: [
          {
            path: ':tab?',
            element: (
              <RequireRootPermission authorize={(permissions) => permissions.translationTool}>
                <TranslationPage />
              </RequireRootPermission>
            )
          }
        ]
      },
      {
        path: 'rainbow',
        element: (
          <RequireRootPermission authorize={(permissions) => permissions.canCreateRetailer}>
            <Rainbow />
          </RequireRootPermission>
        )
      },
      {
        path: 'me',
        element: <MePage />
      },
      // INSIDE matrix:
      {
        path: '/',
        element: <RootPage />,
        index: true
      },
      {
        path: '/:tab?', // <== NOTE: this will catch everything on root level, including invalid tabs
        element: <RootPage />
      },
      {
        path: '/retailer/:retailerId/:tab?',
        element: (
          <RequireRetailerAccess>
            <RetailerPage />
          </RequireRetailerAccess>
        )
      },
      {
        path: '/machine/:machineId/:tab?',
        element: (
          <RequireMachineAccess>
            <MachinePage />
          </RequireMachineAccess>
        )
      },
      {
        path: '/ticket/:ticketId/:tab?',
        element: (
          <RequireTicketAccess>
            <TicketPage />
          </RequireTicketAccess>
        )
      },
      {
        path: '/prediction/:predictionId/:tab?',
        element: <PredictionPage />
      },
      {
        path: '/tickets/new',
        element: <CreateTicketPage />
      },
      {
        path: 'retailer/:retailerId/tickets/new',
        element: <CreateTicketPage />
      },
      {
        path: 'machine/:machineId/tickets/new',
        element: <CreateTicketPage />
      },
      {
        path: 'retailer/new',
        element: (
          <RequireRootPermission authorize={(permissions) => permissions.canCreateRetailer}>
            <CreateRetailerPage />
          </RequireRootPermission>
        )
      }
    ]
  }
]);

export default router;
