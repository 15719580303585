import React from 'react';
import { Marker, MarkerProps, useGoogleMap } from '@react-google-maps/api';
import { Position } from 'components/lib/ConnectMap/position';

interface Props {
  position: Position;
  editable: boolean;
  dummy?: boolean;
  onChange?: (position: { lat: number; lng: number }) => void;
}

const svgPath =
  'M697.75 340.5C697.75 294.056 681.319 254.403 648.458 221.542C615.597 188.681 575.944 172.25 529.5 172.25C483.056 172.25 443.403 188.681 410.542 221.542C377.681 254.403 361.25 294.056 361.25 340.5C361.25 386.944 377.681 426.597 410.542 459.458C443.403 492.319 483.056 508.75 529.5 508.75C575.944 508.75 615.597 492.319 648.458 459.458C681.319 426.597 697.75 386.944 697.75 340.5ZM866 340.5C866 388.258 858.771 427.473 844.312 458.144L605.081 966.837C598.071 981.296 587.665 992.688 573.863 1001.01C560.061 1009.34 545.273 1013.5 529.5 1013.5C513.727 1013.5 498.939 1009.34 485.137 1001.01C471.335 992.688 461.148 981.296 454.576 966.837L214.688 458.144C200.229 427.473 193 388.258 193 340.5C193 247.612 225.861 168.307 291.584 102.584C357.307 36.8612 436.612 3.99991 529.5 3.99991C622.388 3.99991 701.693 36.8612 767.416 102.584C833.139 168.307 866 247.612 866 340.5Z';

/***
 * An editable marker that can be dragged around the map
 * @param position
 * @param onDragEnd
 * @constructor
 */
const MapMarker: React.FC<Props & MarkerProps> = ({
  position,
  editable,
  dummy,
  onChange,
  ...rest
}) => {
  const map = useGoogleMap();
  if (!map) {
    throw new Error('Cannot use outside map');
  }

  const defaultValues: Pick<
    google.maps.Symbol,
    'path' | 'strokeWeight' | 'fillOpacity' | 'scale' | 'anchor'
  > = {
    path: svgPath,
    strokeWeight: 2,
    fillOpacity: 1,
    scale: 0.032,
    anchor: new google.maps.Point(500, 1000)
  };

  // const MarkerIconYellow: google.maps.Symbol = {
  //   ...defaultValues,
  //   strokeColor: '#3f3f3f',
  //   fillColor: '#F6CE00',
  // };

  const MarkerIconGrey: google.maps.Symbol = {
    ...defaultValues,
    strokeColor: '#3f3f3f',
    fillColor: '#939393'
  };

  const MarkerIconRed: google.maps.Symbol = {
    ...defaultValues,
    strokeColor: '#3f3f3f',
    fillColor: '#E64753'
  };

  return (
    <Marker
      {...rest}
      position={position}
      title={`lat: ${position.lat} lng: ${position.lng}`}
      draggable={editable}
      cursor={editable ? 'pointer' : 'default'}
      onDragEnd={(e) => {
        if (!editable) return;
        if (e.latLng) {
          onChange?.({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        }
      }}
      icon={editable ? (dummy ? MarkerIconGrey : MarkerIconRed) : MarkerIconRed}
    />
  );
};

export default MapMarker;
