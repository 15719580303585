import Select, { Option } from 'components/lib/Select/Select';
import React from 'react';
import { SelectProps } from 'antd/es/select';
import { gql } from '@apollo/client/core';
import { useGetAllTicketTagsQuery } from 'generated/types';
import { useIntl } from 'react-intl';

gql`
  query GetAllTicketTags {
    allTicketTags
  }
`;

type OtherSelectProps = Omit<SelectProps<string[]>, 'options' | 'mode'>;

function TypedTicketTagsSelect<T extends string[]>({ children, ...props }: SelectProps<T>) {
  return <Select<T> {...props}>{children}</Select>;
}

const TicketTagsSelect: React.FC<OtherSelectProps> = (props) => {
  const { children, ...rest } = props;
  const intl = useIntl();

  const { data, loading } = useGetAllTicketTagsQuery();
  const allTags = data?.allTicketTags;

  return (
    <TypedTicketTagsSelect
      mode={'tags'}
      placeholder={intl.formatMessage({
        id: 'ticket_tags_select.no_tags',
        defaultMessage: 'Add a new tag'
      })}
      // notFoundContent={<div>Create a new tag not found message?</div>}
      maxTagCount={10}
      loading={loading}
      variant={'outlined'}
      maxTagTextLength={30}
      {...rest}
    >
      {allTags?.map((tag) => (
        <Option key={tag} value={tag}>
          {tag}
        </Option>
      ))}
    </TypedTicketTagsSelect>
  );
};

export default TicketTagsSelect;
