import React, { useCallback, useEffect } from 'react';
import { Button, Space, Typography } from 'antd';
import { defineMessages, useIntl } from 'react-intl';
import { RocketTwoTone } from '@ant-design/icons';
import { useRegisterSW } from 'virtual:pwa-register/react';
import styled from 'styled-components';
import Card from 'components/lib/Card/Card.tsx';
import { AnimatePresence, motion } from 'framer-motion';

const messages = defineMessages({
  title: {
    id: 'reload_prompt.title',
    defaultMessage: 'Vendanor Connect'
  },
  description: {
    id: 'reload_prompt.description',
    defaultMessage: `A new app version is ready, and it will now update automatically.`
  },
  install: {
    id: 'reload_prompt.install',
    defaultMessage: 'Update ({countdown})'
  },
  later: {
    id: 'reload_prompt.later',
    defaultMessage: 'Later'
  }
});

const OuterContainer = styled.div`
  padding: 0;
  margin: 0;
  width: 0;
  height: 0;
`;

const InnerContainer = styled(motion.div)`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1200;
  max-width: 384px;

  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: 8px;
`;

/***
 * This component will render a prompt to the user to reload the page when a new version of the app is available.
 * @constructor
 */
const ReloadPrompt: React.FC = () => {
  const intl = useIntl();
  const [countdown, setCountdown] = React.useState<number | undefined>(undefined);

  const {
    needRefresh: [needRefresh],
    updateServiceWorker
  } = useRegisterSW({
    onRegisteredSW(swUrl, registration) {
      if (registration) {
        setInterval(async () => {
          if (!(!registration.installing && navigator)) {
            return;
          }

          if ('connection' in navigator && !navigator.onLine) {
            return;
          }

          try {
            await registration.update();
          } catch (err) {
            console.log('Failed to update SW', err);
          }
        }, 1000 * 120); // NOTE: checking for a new version every 120 seconds
      }
    },
    onRegisterError(error) {
      console.error('SW registration error', error);
    }
  });

  const handleConfirmUpdate = useCallback(async () => {
    try {
      await updateServiceWorker(true);
    } catch (err) {
      console.log('Failed to update ServiceWorker', err);
    }
  }, [updateServiceWorker]);

  useEffect(() => {
    if (needRefresh) {
      setCountdown(20);
    }
  }, [needRefresh]);

  useEffect(() => {
    if (countdown === undefined) {
      return;
    }

    if (countdown === 0) {
      handleConfirmUpdate().catch((err) => console.log('Failed to update ServiceWorker', err));
      setCountdown(undefined);
      return;
    }

    const intervalId = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [countdown, handleConfirmUpdate]);
  return (
    <OuterContainer>
      <AnimatePresence initial={false}>
        {needRefresh && countdown !== undefined && countdown > 0 && (
          <InnerContainer
            key={'update-toast-animation'}
            initial={{ opacity: 0, y: 50, scale: 0.3 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
          >
            <Card bordered={false} style={{ marginBottom: 16 }}>
              <Space size={16} align={'start'}>
                <span style={{ fontSize: 24 }}>
                  <RocketTwoTone />
                </span>
                <Space direction={'vertical'}>
                  <Typography.Title level={5}>
                    {intl.formatMessage(messages.title)}
                  </Typography.Title>
                  <Typography.Text>{intl.formatMessage(messages.description)}</Typography.Text>
                  <ButtonContainer>
                    <Button onClick={handleConfirmUpdate} type={'primary'} style={{ flex: 1 }}>
                      {intl.formatMessage(messages.install, {
                        countdown
                      })}
                    </Button>
                  </ButtonContainer>
                </Space>
              </Space>
            </Card>
          </InnerContainer>
        )}
      </AnimatePresence>
    </OuterContainer>
  );
};

export default ReloadPrompt;
