import React, { SVGProps } from 'react';

// NOTE: this is not resizable because of strokeWidth...
const WfdIllustration: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width='80'
    height='80'
    viewBox='0 0 80 80'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='m19.515 69.191 5.39 3.723a3 3 0 0 0 1.867.528l37.98-2.055M19.516 69.19a1.192 1.192 0 0 1-.515-.98V13.057a3 3 0 0 1 .502-1.66l5.302-7.977a3 3 0 0 1 2.648-1.336l34.875 1.747a3 3 0 0 1 2.85 2.997v64.11a.449.449 0 0 1-.424.448M19.515 69.19v1.663a3 3 0 0 0 .947 2.189l4.343 4.072a3 3 0 0 0 2.234.807l34.896-2.12a3 3 0 0 0 2.818-2.995v-1.42M25.734 2v71.19'
      stroke='currentColor'
      strokeWidth='2'
    />
    <path
      d='M38.24 46.19V31.875a1 1 0 0 1 1.016-1l13.392.208a1 1 0 0 1 .985 1v13.76a1 1 0 0 1-.974 1l-13.393.345a1 1 0 0 1-1.026-1Z'
      stroke='currentColor'
      strokeWidth='2'
    />
    <path
      d='M32.468 30.533v36.275a.481.481 0 0 1-.962 0V30.533a.481.481 0 0 1 .962 0ZM56.883 9.673l-21.007-.798a.542.542 0 0 1 .04-1.083l21.01.73a.576.576 0 1 1-.043 1.151Z'
      fill='currentColor'
      stroke='currentColor'
    />
  </svg>
);

export default WfdIllustration;
