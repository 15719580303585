import { gql } from '@apollo/client/core';
import { ArticleListItemFragment, useCheckTicketServiceAgreementQuery } from 'generated/types';
import { useCallback } from 'react';

gql`
  query CheckTicketServiceAgreement($ticketId: Int!) {
    ticket(ticketId: $ticketId) {
      id
      ticketId
      machine {
        id
        machineId
        serviceAgreement
      }
    }
  }
`;

interface DiscountInfo {
  hasDiscount: boolean;
  discountPercentage?: number;
  factor: number;
  hasServiceAgreement: boolean;
}

function useArticleDiscountTools(ticketId?: number) {
  const { data } = useCheckTicketServiceAgreementQuery({
    notifyOnNetworkStatusChange: true,
    variables: ticketId ? { ticketId } : undefined,
    skip: !ticketId
  });

  const getArticleDiscount = useCallback(
    (option: ArticleListItemFragment): DiscountInfo => {
      if (!data) {
        return {
          hasDiscount: false,
          discountPercentage: 0,
          factor: 1,
          hasServiceAgreement: false
        };
      }

      const hasServiceAgreement = data.ticket.machine.serviceAgreement;

      if (option.group.hasStorage && hasServiceAgreement) {
        return {
          hasDiscount: true,
          discountPercentage: 15,
          factor: 0.85,
          hasServiceAgreement
        };
      }
      return {
        hasDiscount: false,
        discountPercentage: 0,
        factor: 1,
        hasServiceAgreement
      };
    },
    [data]
  );

  return {
    getArticleDiscount,
    hasServiceAgreement: data?.ticket.machine.serviceAgreement || false
  };
}

export default useArticleDiscountTools;
