import { CarOutlined, FormOutlined, LaptopOutlined } from '@ant-design/icons';
import React from 'react';
import { TicketTypeFullFragment, TicketTypeSymbol } from 'generated/types';
import { TruckOutlineIcon } from 'components/icons/Icons.tsx';

export const getTicketTypeIcon = (type: Pick<TicketTypeFullFragment, 'symbol'>) => {
  switch (type.symbol) {
    case TicketTypeSymbol.Car:
      return <CarOutlined />;
    case TicketTypeSymbol.Computer:
      return <LaptopOutlined />;
    case TicketTypeSymbol.Checklist:
      return <FormOutlined />;
    case TicketTypeSymbol.Parts:
      return <TruckOutlineIcon />;
    default:
      return <FormOutlined />;
  }
};
