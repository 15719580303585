import { defineMessages } from 'react-intl';

const predictionMessages = defineMessages({
  emptyChamber: {
    defaultMessage: 'Empty chamber',
    id: 'predictions.emptyChamber'
  },
  productFound: {
    defaultMessage: '{productName}',
    id: 'predictions.productFound'
  },
  unknownProduct: {
    defaultMessage: 'Not recognized',
    id: 'predictions.unknownProduct'
  },
  predictorInfo: {
    defaultMessage: 'Predictor v{version} ({ms} ms)',
    id: 'predictions.predictorInfo'
  },
  productLabel: {
    id: 'predictions.productLabel',
    defaultMessage: 'Product'
  },
  emptyChamberShort: {
    defaultMessage: 'Empty',
    id: 'predictions.emptyChamberShort'
  },
  unknownShort: {
    defaultMessage: 'Not recognized',
    id: 'predictions.unknownShort'
  },
  openPrediction: {
    defaultMessage: 'Prediction',
    id: 'predictions.openPrediction'
  },
  noClassificationsWarning: {
    defaultMessage: '🚨 No classifications found!',
    id: 'predictions.noClassificationsWarning'
  },
  predictionId: {
    defaultMessage: 'Prediction ID: {id}',
    id: 'predictions.predictionId'
  },
  sampleId: {
    defaultMessage: 'Sample ID: {id}',
    id: 'predictions.sampleId'
  },
  chamber: {
    defaultMessage: 'Chamber: {carousel} / {row} / {sector}',
    id: 'predictions.chamber'
  },
  pageTitle: {
    defaultMessage: 'Prediction',
    id: 'predictions.pageTitle'
  },
  tabPrediction: {
    defaultMessage: 'Prediction',
    id: 'predictions.tabPrediction'
  },
  elapsedTimeTitle: {
    defaultMessage: 'Elapsed time',
    id: 'predictions.elapsedTimeTitle'
  },
  elapsedTimeValue: {
    defaultMessage: '{ms} ms',
    id: 'predictions.elapsedTimeValue'
  },
  predictorVersionTitle: {
    defaultMessage: 'Predictor version',
    id: 'predictions.predictorVersionTitle'
  },
  timestampTitle: {
    defaultMessage: 'Timestamp',
    id: 'predictions.timestampTitle'
  },
  objectDetectionTitle: {
    defaultMessage: 'Object detection',
    id: 'predictions.objectDetectionTitle'
  },
  session: {
    defaultMessage: 'Session: {session}',
    id: 'predictions.session'
  },
  run: {
    defaultMessage: 'Run: {run}',
    id: 'predictions.run'
  },
  previous: {
    defaultMessage: 'Previous',
    id: 'predictions.previous'
  },
  next: {
    defaultMessage: 'Next',
    id: 'predictions.next'
  }
});

export default predictionMessages;
