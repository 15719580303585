import React, { useCallback, useEffect, useRef, useState } from 'react';
import { gql } from '@apollo/client/core';
import {
  RetailerDetailsFragmentDoc,
  UpdateRetailerDetailsInput,
  useGetRetailerDetailsQuery,
  useUpdateRetailerDetailsMutation
} from 'generated/types';
import { Button, Divider, Form, Input, message, Space, Tooltip, Typography } from 'antd';
import styled from 'styled-components';
import useQueryParam from 'hooks/useQueryParam';
import { useForm } from 'antd/es/form/Form';
import Card from 'components/lib/Card/Card';
import ConnectMap, { defaultCenter } from 'components/lib/ConnectMap/ConnectMap';
import { NavigateOutlinedIcon } from 'components/icons/Icons';
import {
  CloseOutlined,
  EditOutlined,
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined,
  SaveOutlined
} from '@ant-design/icons';
import commonMessages from 'components/i18n/commonMessages';
import { EditablePosition, Position } from 'components/lib/ConnectMap/position';
import MapMarker from 'components/lib/ConnectMap/MapMarker';
import retailerMessages from 'components/retailer/retailerMessages';
import Link from 'components/lib/Link/Link';
import useAddressLookupHandler from 'components/lib/ConnectMap/useAddressLookupHandler';
import useFormatTools from 'i18n/useFormatTools';
import InputNumber from 'components/lib/InputNumber/InputNumber';
import Language from 'components/i18n/Language/Language.tsx';
import { getFriendlyApolloErrorMessage } from 'graphql/apollo/apolloErrorUtil.ts';
import useRetailerDeployTools from 'components/retailer/useRetailerDeployTools.ts';
import useOnUnmount from 'hooks/useOnUnmount.ts';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import validationMessages from 'components/i18n/validationMessages.ts';

const { Text } = Typography;

interface Props {
  retailerId?: number;
}

gql`
  query GetRetailerDetails($retailerId: Int!) {
    retailer(retailerId: $retailerId) {
      ...RetailerDetails
      permissions {
        canEditRetailer
      }
    }
  }
  ${RetailerDetailsFragmentDoc}
`;

gql`
  mutation UpdateRetailerDetails($input: UpdateRetailerDetailsInput!) {
    updateRetailerDetails(input: $input) {
      ...RetailerDetails
    }
  }
  ${RetailerDetailsFragmentDoc}
`;

gql`
  subscription OnRetailerDetailsUpdated($retailerId: Int!) {
    onRetailerUpdated(retailerId: $retailerId) {
      ...RetailerDetails
    }
  }
  ${RetailerDetailsFragmentDoc}
`;

const LimitedSpace = styled(Space)`
  width: 100%;
  overflow: hidden;
`;

const RetailerMapCard: React.FC<Props> = ({ retailerId }) => {
  const editQueryParam = 'editRetailer';
  const { formatCountry } = useFormatTools();
  const intl = useConnectIntl();
  const lookupAddress = useAddressLookupHandler();
  const [isEditing, setIsEditing] = useQueryParam<boolean>(editQueryParam);
  const [storedPosition, setStoredPosition] = useState<Position | undefined>(undefined);
  const [editPosition, setEditPosition] = useState<EditablePosition | undefined>(undefined);
  const [center, setCenter] = useState<Position | undefined>(undefined);

  const { data } = useGetRetailerDetailsQuery({
    variables: {
      retailerId: retailerId || -1
    },
    skip: !retailerId,
    fetchPolicy: 'cache-and-network'
  });

  const { flushPendingRetailerChanges, addPendingRetailerChange } = useRetailerDeployTools();
  useOnUnmount(() => {
    flushPendingRetailerChanges();
  });

  const [updateRetailerDetails, { loading: saving }] = useUpdateRetailerDetailsMutation();

  const handleCancel = useCallback(() => {
    setIsEditing(false);
  }, [setIsEditing]);

  const [form] = useForm<UpdateRetailerDetailsInput>();

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setEditPosition(undefined);
    setStoredPosition(undefined);
  }, [retailerId]);

  useEffect(() => {
    if (!data) return;
    if (isEditing) {
      // console.log('init edit mode');
      setStoredPosition(undefined);
      form.setFieldsValue({
        name: data.retailer.name,
        address1: data.retailer.address1,
        web: data.retailer.web,
        email: data.retailer.email,
        address2: data.retailer.address2,
        phone: data.retailer.phone,
        postCity: data.retailer.postCity,
        postCode: data.retailer.postCode,
        regno1: data.retailer.regno1,
        regno2: data.retailer.regno2,
        regno1Name: data.retailer.regno1Name,
        regno2Name: data.retailer.regno2Name,
        geoLat: data.retailer.geoLat,
        geoLng: data.retailer.geoLng
      });

      if (data.retailer.geoLat && data.retailer.geoLng) {
        setEditPosition({
          lat: data.retailer.geoLat,
          lng: data.retailer.geoLng,
          dummy: false
        });
        setCenter({
          lat: data.retailer.geoLat,
          lng: data.retailer.geoLng
        });
      } else {
        // no position on retailer, but we need to set position to something to show the editable marker:
        if (data.retailer.country.nameEnglish) {
          lookupAddress({
            country: data.retailer.country.nameEnglish,
            hideMessages: true
          })
            .then((result) => {
              setEditPosition({ ...result.position, dummy: true });
              setCenter(result.position);
            })
            .catch(() => {
              setEditPosition({ ...defaultCenter, dummy: true });
              setCenter(defaultCenter);
            });
        } else {
          setEditPosition({ ...defaultCenter, dummy: true });
          setCenter(defaultCenter);
        }
      }
    } else {
      // console.log('init view mode');
      if (data.retailer.geoLat && data.retailer.geoLng) {
        setStoredPosition({
          lat: data.retailer.geoLat,
          lng: data.retailer.geoLng
        });
        setCenter({
          lat: data.retailer.geoLat,
          lng: data.retailer.geoLng
        });
      } else {
        // no position, but we could use country to lookup center
        if (data.retailer.country.nameEnglish) {
          // console.log('no position, lookup country');
          lookupAddress({
            country: data.retailer.country.nameEnglish,
            hideMessages: true
          })
            .then((res) => {
              setCenter(res.position);
            })
            .catch(() => {
              setCenter(defaultCenter);
            });
        } else {
          setCenter(defaultCenter);
        }
      }
    }
  }, [isEditing, data, retailerId, form, lookupAddress]);

  const handleFailedSubmit = async () => {
    message.error({
      content: intl.formatMsg({
        id: 'retailer_map_card.invalid_form',
        defaultMessage: 'Invalid form 😔'
      })
    });
  };

  const handleSubmit = async () => {
    if (!retailerId) return;

    try {
      message.loading({
        key: 'update_retailer',
        content: 'Updating retailer...'
      });

      const validatedValues = await form.validateFields();

      await updateRetailerDetails({
        variables: {
          input: {
            ...validatedValues,
            retailerId: retailerId
          }
        }
      });

      addPendingRetailerChange(retailerId);

      message.success({
        key: 'update_retailer',
        content: 'Retailer updated successfully.'
      });
      setIsEditing(false);
    } catch (e: unknown) {
      message.error({
        key: 'update_retailer',
        content: getFriendlyApolloErrorMessage(e, 'Failed to update retailer')
      });
    }
  };

  const handleLookupAddress = useCallback(async () => {
    const values = form.getFieldsValue();
    const { address1, postCode, postCity } = values;

    try {
      const result = await lookupAddress({
        address1: address1 as string | undefined,
        postCode: postCode as string | undefined,
        postCity: postCity as string | undefined
      });

      form.setFieldsValue({
        geoLat: result.position.lat,
        geoLng: result.position.lng
      });

      setEditPosition({
        lat: result.position.lat,
        lng: result.position.lng,
        dummy: false
      });
      setCenter(result.position);
    } catch (err) {
      message.error({
        content: 'Unable to lookup address for retailer'
      });
    }
  }, [form, lookupAddress]);

  const hasGeo = (data?.retailer.geoLat && data?.retailer.geoLng) || false;
  const navUrl = `https://www.google.com/maps/dir/?api=1&destination=${data?.retailer.geoLat},${data?.retailer.geoLng}`;
  const actions = [];
  if (hasGeo) {
    actions.push(
      <a key={'navigate'} href={navUrl} target={'_blank'} rel={'noreferrer'}>
        <Space>
          <NavigateOutlinedIcon />
          <span>{intl.formatMsg(commonMessages.navigate)}</span>
        </Space>
      </a>
    );
  }
  if (data?.retailer.permissions.canEditRetailer) {
    actions.push(
      <Link key={'edit'} to={`?${editQueryParam}=true`}>
        <Space>
          <EditOutlined />
          <span>{intl.formatMsg(commonMessages.edit)}</span>
        </Space>
      </Link>
    );
  }

  return (
    <Card
      size={'default'}
      loading={!data}
      styles={{
        body: {
          overflow: 'hidden'
        }
      }}
      actions={isEditing ? undefined : actions}
      cover={
        <ConnectMap
          basicControls={{
            locate: false,
            streetView: false,
            mapType: true,
            fullscreen: false
          }}
          style={{ height: 240 }}
          scrollwheel={false}
          center={center}
        >
          {storedPosition && !isEditing && data && (
            <MapMarker position={storedPosition} editable={false} dummy={false} />
          )}
          {editPosition && isEditing && data && (
            <MapMarker
              position={editPosition}
              editable={true}
              dummy={editPosition.dummy}
              onChange={(position) => {
                form.setFieldsValue({
                  geoLat: position.lat,
                  geoLng: position.lng
                });

                setEditPosition({
                  lat: position.lat,
                  lng: position.lng,
                  dummy: false
                });
              }}
            />
          )}
        </ConnectMap>
      }
    >
      {data && !isEditing && (
        <LimitedSpace direction={'vertical'} size={'large'}>
          <LimitedSpace direction={'vertical'} size={'small'}>
            <Typography.Text>{data.retailer.name}</Typography.Text>
            {data.retailer.regno1 && (
              <>
                <Typography.Text
                  ellipsis={true}
                  copyable={{ text: data.retailer.regno1 }}
                  style={{ width: '100%' }}
                >
                  {data.retailer.regno1Name} {data.retailer.regno1}
                </Typography.Text>
              </>
            )}
            {data.retailer.regno2 && (
              <>
                <Typography.Text
                  ellipsis={true}
                  copyable={{ text: data.retailer.regno2 }}
                  style={{ width: '100%' }}
                >
                  {data.retailer.regno2Name} {data.retailer.regno2}
                </Typography.Text>
              </>
            )}
          </LimitedSpace>
          <LimitedSpace direction={'vertical'} size={'small'}>
            {data.retailer.address1 && (
              <Typography.Text ellipsis={true} style={{ width: '100%' }}>
                {data.retailer.address1}
              </Typography.Text>
            )}
            {data.retailer.address2 && (
              <Typography.Text ellipsis={true} style={{ width: '100%' }}>
                {data.retailer.address2}
              </Typography.Text>
            )}
            {(data.retailer.postCode || data.retailer.postCity) && (
              <LimitedSpace>
                <Text>{data.retailer.postCode}</Text>
                <Text ellipsis={true}>{data.retailer.postCity}</Text>
              </LimitedSpace>
            )}
            {data.retailer.country.nameEnglish && (
              <Typography.Text ellipsis={true} style={{ width: '100%' }}>
                {formatCountry(data.retailer.country.nameEnglish)}
              </Typography.Text>
            )}
          </LimitedSpace>
          <LimitedSpace direction={'vertical'} size={'small'}>
            {data.retailer.web && (
              <Typography.Text ellipsis={true} style={{ width: '100%' }}>
                <a href={data.retailer.web} target={'_blank'} rel={'noreferrer'}>
                  <GlobalOutlined style={{ marginRight: '4px' }} />
                  {data.retailer.web}
                </a>
              </Typography.Text>
            )}
            {data.retailer.email && (
              <Typography.Text ellipsis={true} style={{ width: '100%' }}>
                <a href={`mailto:${data.retailer.email}`} target={'_blank'} rel={'noreferrer'}>
                  <MailOutlined style={{ marginRight: '4px' }} />
                  {data.retailer.email}
                </a>
              </Typography.Text>
            )}
            {data.retailer.phone && (
              <Typography.Text ellipsis={true} style={{ width: '100%' }}>
                <a href={`tel:${data.retailer.phone}`}>
                  <PhoneOutlined style={{ marginRight: '4px' }} />
                  {data.retailer.phone}
                </a>
              </Typography.Text>
            )}
          </LimitedSpace>

          <Space direction={'vertical'} size={'small'}>
            <div>
              {intl.formatMsg({
                id: 'retailer_map_card.languages_label',
                defaultMessage: 'Languages'
              })}
            </div>
            {data.retailer.languages.map((language) => (
              <Language key={language.id} language={language} showTooltip={true} />
            ))}
          </Space>
        </LimitedSpace>
      )}
      {isEditing && (
        <>
          <Form
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={handleFailedSubmit}
            layout={'vertical'}
            disabled={saving}
          >
            <Form.Item name={'name'} label={'Name'} rules={[{ required: true }]}>
              <Input style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item name={'regno1'} label={'RegNo 1'}>
              <Input
                style={{ width: '100%' }}
                placeholder={intl.formatMsg(retailerMessages.regno1Placeholder)}
              />
            </Form.Item>

            <Form.Item name={'regno1Name'} label={'RegNo 1 name'}>
              <Input placeholder={intl.formatMsg(retailerMessages.regno1NamePlaceholder)} />
            </Form.Item>

            <Form.Item name={'regno2'} label={'RegNo 2'}>
              <Input
                style={{ width: '100%' }}
                placeholder={intl.formatMsg(retailerMessages.regno2Placeholder)}
              />
            </Form.Item>

            <Form.Item name={'regno2Name'} label={'RegNo 2 name'}>
              <Input />
            </Form.Item>

            <Divider />

            <Form.Item name={'address1'} label={'Address 1'}>
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name={'address2'} label={'Address 2'}>
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name={'postCode'} label={'Postal code'}>
              <Input style={{ width: '180px' }} />
            </Form.Item>
            <Form.Item name={'postCity'} label={'City'}>
              <Input style={{ width: '100%' }} />
            </Form.Item>

            {/*<Divider />*/}

            <Form.Item label={'Coordinates'}>
              <Space direction={'vertical'} size={'middle'}>
                <Form.Item noStyle={true}>
                  <Tooltip title={'Use address to lookup coordinates'}>
                    <Button
                      type={'default'}
                      onClick={handleLookupAddress}
                      style={{ width: '100%' }}
                      disabled={saving}
                    >
                      Lookup address
                    </Button>
                  </Tooltip>
                </Form.Item>
                <Form.Item name={'geoLat'} noStyle={true}>
                  <InputNumber<number>
                    placeholder={'Latitude'}
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      if (value !== null && editPosition) {
                        setEditPosition({
                          ...editPosition,
                          lat: value
                        });
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item name={'geoLng'} noStyle={true}>
                  <InputNumber<number>
                    placeholder={'Longitude'}
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      if (value !== null && editPosition) {
                        setEditPosition({
                          ...editPosition,
                          lng: value
                        });
                      }
                    }}
                  />
                </Form.Item>
              </Space>
            </Form.Item>

            <Divider />

            <Form.Item
              name={'web'}
              label={'Web'}
              rules={[
                {
                  type: 'url',
                  message: intl.formatMsg(validationMessages.invalidUrl)
                }
              ]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              name={'email'}
              label={'Email'}
              rules={[
                {
                  type: 'email',
                  message: intl.formatMsg(validationMessages.invalidEmail)
                }
              ]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item name={'phone'} label={'Phone'}>
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Form>
          <Space>
            <Button
              type={'primary'}
              icon={<SaveOutlined />}
              onClick={handleSubmit}
              disabled={saving}
            >
              {intl.formatMsg(commonMessages.save)}
            </Button>
            <Button
              type={'default'}
              icon={<CloseOutlined />}
              onClick={handleCancel}
              disabled={saving}
            >
              {intl.formatMsg(commonMessages.cancel)}
            </Button>
          </Space>
        </>
      )}
    </Card>
  );
};

export default RetailerMapCard;
