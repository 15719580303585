import { Select } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useGetTimezonesQuery } from 'generated/types';
import { useIntl } from 'react-intl';
import { SelectProps } from 'antd/es/select';

function TypedTimezoneSelect<T extends string>({ children, ...props }: SelectProps<T>) {
  return <Select<T> {...props}>{children}</Select>;
}

type OtherSelectProps = Omit<SelectProps<string>, 'options'>;

const TimezoneSelect: React.FC<OtherSelectProps> = (props) => {
  const { loading, value, ...rest } = props;
  const intl = useIntl();
  const {
    data,
    loading: timezonesLoading,
    fetchMore
  } = useGetTimezonesQuery({
    variables: {
      cursor: undefined
    }
  });
  const hasNextPage = data?.allTimezones?.pageInfo.hasNextPage || false;
  const nextCursor = data?.allTimezones?.pageInfo.endCursor;

  useEffect(() => {
    async function fetchMoreTimezones() {
      await fetchMore({
        variables: {
          cursor: nextCursor
        }
      });
    }

    if (hasNextPage) {
      fetchMoreTimezones();
    }
  }, [fetchMore, hasNextPage, nextCursor]);

  const options = useMemo(() => {
    return data?.allTimezones?.edges?.map((c) => ({
      label: c.node.name,
      value: c.node.id
    }));
  }, [data]);

  return (
    <TypedTimezoneSelect
      style={{ minWidth: 200 }}
      options={options}
      loading={loading || timezonesLoading}
      placeholder={intl.formatMessage({
        id: 'timezone_select.placeholder',
        defaultMessage: 'Timezone'
      })}
      value={timezonesLoading ? undefined : value}
      {...rest}
    />
  );
};

export default TimezoneSelect;
