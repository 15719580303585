import React, { useCallback, useEffect, useState } from 'react';
import Select, { Option } from 'components/lib/Select/Select';
import { MachineListItemFragment, useGetMachineListQuery } from 'generated/types';
import { DefaultOptionType, FilterFunc } from 'rc-select/lib/Select';
import { Space } from 'antd';
import { SelectProps } from 'antd/es/select';
import getMachineIcon from 'components/icons/getMachineIcon';
import DeactivatedMachineTag from 'components/machine/DeactivatedMachineTag/DeactivatedMachineTag.tsx';

interface Props {
  retailerId?: number;
  includeInactiveMachineInstances?: boolean;
}

interface MachineOption extends DefaultOptionType {
  machine: MachineListItemFragment;
}

type OtherSelectProps = Omit<SelectProps<number>, 'options'>;

const MachineSelect: React.FC<Props & OtherSelectProps> = (props) => {
  const { children, retailerId, includeInactiveMachineInstances, value, ...rest } = props;

  const { data, fetchMore, loading } = useGetMachineListQuery({
    variables: {
      cursor: undefined,
      input: {
        active: includeInactiveMachineInstances ? undefined : true,
        search: '',
        retailerId
      }
    },
    notifyOnNetworkStatusChange: true
  });

  const hasNextPage = data?.allMachines?.pageInfo.hasNextPage || false;
  const nextCursor = data?.allMachines?.pageInfo.endCursor;

  useEffect(() => {
    if (hasNextPage) {
      fetchMore({
        variables: {
          cursor: nextCursor,
          input: {
            active: true,
            search: '',
            retailerId
          }
        }
      });
    }
  }, [hasNextPage, nextCursor, fetchMore, retailerId]);

  const [searchTerm, setSearchTerm] = useState('');

  const handleFilterOption = useCallback<FilterFunc<MachineOption>>((inputValue, option) => {
    if (option && option.machine) {
      const machineTitle = (option.machine.location || option.machine.serialNo || '').toLowerCase();

      return machineTitle.indexOf(inputValue.toLowerCase()) > -1;
    }
    return true;
  }, []);

  return (
    <Select
      value={loading ? undefined : value}
      loading={loading}
      showSearch={true}
      searchValue={searchTerm}
      onSearch={(value) => setSearchTerm(value)}
      filterOption={handleFilterOption}
      {...rest}
    >
      {data?.allMachines?.edges
        ?.map((c) => c.node)
        .map((c) => {
          const machineTitle = c.location || c.serialNo || undefined;

          return (
            <Option key={c.machineId} value={c.machineId} machine={c}>
              <Space>
                <div>{getMachineIcon(c.machineType)}</div>
                <div>{machineTitle}</div>
                {!c.active && <DeactivatedMachineTag />}
              </Space>
            </Option>
          );
        })}
    </Select>
  );
};

export default MachineSelect;
