import React from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';
import TicketListFilter from 'components/ticket/TicketList/TicketListFilter.tsx';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo.ts';

const Container = styled.div<{ $open: boolean }>`
  overflow: hidden;
  border-radius: ${(props) => props.theme.ant.borderRadius}px;
  display: flex;
  gap: 8px;
  background: ${(props) => props.theme.ant.colorBgContainer}};

`;

const TicketMapFilters: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const { screenMap } = useScreenInfo();

  return (
    <Container $open={open}>
      <Collapse
        size={'small'}
        activeKey={open ? 'ticketMapFilters' : undefined}
        onChange={(keys) => setOpen(keys.includes('ticketMapFilters'))}
        items={[
          {
            key: 'ticketMapFilters',
            label: 'Filters',
            children: (
              <TicketListFilter
                style={{
                  minWidth: screenMap.xl ? 700 : 'unset'
                }}
              />
            )
          }
        ]}
      />
    </Container>
  );
};

export default TicketMapFilters;
