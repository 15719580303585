import { defineMessages, MessageDescriptor } from 'react-intl';
import { Message } from 'typescript-react-intl';
import { TicketSeverity } from 'generated/types';

type SeverityMessages = { [key in TicketSeverity]: Message };

const labels: SeverityMessages = {
  [TicketSeverity.A]: {
    id: 'ticket_severity_a',
    defaultMessage: 'Critical'
  },
  [TicketSeverity.B]: {
    id: 'ticket_severity_b',
    defaultMessage: 'Major'
  },
  [TicketSeverity.C]: {
    id: 'ticket_severity_c',
    defaultMessage: 'Medium'
  },
  [TicketSeverity.D]: {
    id: 'ticket_severity_d',
    defaultMessage: 'Minor'
  }
};

const descriptions: SeverityMessages = {
  [TicketSeverity.A]: {
    id: 'ticket_severity_a_description',
    defaultMessage: 'Must be fixed immediately'
  },
  [TicketSeverity.B]: {
    id: 'ticket_severity_b_description',
    defaultMessage: 'Should be fixed within days'
  },
  [TicketSeverity.C]: {
    id: 'ticket_severity_c_description',
    defaultMessage: 'Resolve as soon as possible'
  },
  [TicketSeverity.D]: {
    id: 'ticket_severity_d_description',
    defaultMessage: 'Resolve when suitable'
  }
};

export const ticketSeverityMessages = defineMessages<
  TicketSeverity,
  MessageDescriptor,
  Record<TicketSeverity, MessageDescriptor>
>(labels);

export const ticketSeverityDescriptionMessages = defineMessages(descriptions);
