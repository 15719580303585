import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Empty, Row, Space } from 'antd';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import {
  MinimalPageInfoFragmentDoc,
  ProductCategory,
  RetailerProductListItemFullFragment,
  RetailerProductListItemFullFragmentDoc,
  useGetEnabledRetailerProductsQuery,
  useSetRetailerProductSortMutation
} from 'generated/types';
import groupItemsBy from 'components/util/groupItemsBy';
import enumKeys from 'components/util/enumKeys';
import RetailerProductSortableList from 'components/retailer/RetailerProductCard/RetailerProductSortableList';
import { useIntl } from 'react-intl';
import {
  productCategoryMessages,
  retailerProductMessages
} from 'components/retailer/RetailerProductCard/retailerProductMessages';
import SelectRetailerProductDrawer from 'components/retailer/RetailerProductCard/SelectRetailerProductDrawer';
import ResponsiveListCard from 'components/lib/List/ResponsiveListCard';
import Spinner from 'components/lib/Spinner/Spinner.tsx';
import useMessageApi from 'components/global/useMessageApi.ts';
import { getFriendlyApolloErrorMessage } from 'graphql/apollo/apolloErrorUtil.ts';
import useOnUnmount from 'hooks/useOnUnmount.ts';
import useRetailerDeployTools from 'components/retailer/useRetailerDeployTools.ts';

const ActionCol = styled(Col)`
  &&& {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
`;

const StyledCard = styled(ResponsiveListCard)`
  && {
    .ant-empty-image {
      height: 75px;
    }
    .ant-empty-description {
      color: ${(props) => props.theme.ant.colorTextSecondary};
    }
  }
`;

gql`
  query GetEnabledRetailerProducts(
    $retailerId: Int!
    $cursor: String
    $filter: RetailerProductFilterInput!
  ) {
    retailer(retailerId: $retailerId) {
      id
      retailerId
      name
      products(sortField: CATEGORY_AND_SORT, first: 50, after: $cursor, filter: $filter) {
        totalCount
        pageInfo {
          ...MinimalPageInfo
        }
        edges {
          cursor
          node {
            ...RetailerProductListItemFull
          }
        }
      }
    }
  }
  ${MinimalPageInfoFragmentDoc}
  ${RetailerProductListItemFullFragmentDoc}
`;

gql`
  mutation SetRetailerProductSort($input: SetRetailerProductSortInput!) {
    setRetailerProductSort(input: $input) {
      retailerProduct {
        id
        sort
      }
    }
  }
`;

interface Props {
  retailerId?: number;
}

// Used to compute a new sort value on the frontend for optimistic updates
function computeSortValue(
  movedProduct: RetailerProductListItemFullFragment,
  prevProduct: RetailerProductListItemFullFragment | undefined,
  nextProduct: RetailerProductListItemFullFragment | undefined
) {
  if (prevProduct && nextProduct) {
    return (prevProduct.sort + nextProduct.sort) / 2;
  } else if (prevProduct) {
    return prevProduct.sort + 1;
  } else if (nextProduct) {
    return nextProduct.sort - 1;
  } else {
    return 0;
  }
}

const RetailerProductCard: React.FC<Props> = ({ retailerId }) => {
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const message = useMessageApi();

  const { flushPendingRetailerChanges, addPendingRetailerChange } = useRetailerDeployTools();
  useOnUnmount(() => {
    flushPendingRetailerChanges();
  });

  const { data, loading, fetchMore, refetch } = useGetEnabledRetailerProductsQuery({
    variables: retailerId
      ? {
          cursor: undefined,
          retailerId,
          filter: {
            enabled: true
          }
        }
      : undefined,
    notifyOnNetworkStatusChange: true,
    skip: !retailerId
  });

  const [setSort] = useSetRetailerProductSortMutation();

  const groupedData = useMemo(() => {
    const allEdges = data?.retailer.products?.edges;
    if (!allEdges) {
      return undefined;
    }

    // NOTE: We need to sort on frontend too for optimistic updates to work
    // (since we're only updating one item, not the entire list)
    const items = allEdges
      .map((c) => ({
        category: c.node.product.category,
        node: c.node,
        sort: c.node.sort
      }))
      .sort((c1, c2) => {
        if (c1.sort > c2.sort) {
          return 1;
        }
        if (c1.sort < c2.sort) {
          return -1;
        }
        return 0;
      });

    return groupItemsBy(items, 'category', ProductCategory);
  }, [data?.retailer.products?.edges]);

  const isEmpty = useMemo(() => {
    const productCount = data?.retailer.products?.edges?.length || 0;
    return productCount <= 0 && !loading;
  }, [data, loading]);

  const handleMoveProduct = useCallback(
    async (
      movedProduct: RetailerProductListItemFullFragment,
      prevProduct: RetailerProductListItemFullFragment | undefined,
      nextProduct: RetailerProductListItemFullFragment | undefined
    ) => {
      try {
        if (!retailerId) {
          return;
        }
        addPendingRetailerChange(retailerId);
        await setSort({
          variables: {
            input: {
              productId: movedProduct.product.productId,
              previousProductId: prevProduct?.product.productId,
              nextProductId: nextProduct?.product.productId,
              retailerId
            }
          },
          optimisticResponse: {
            __typename: 'Mutation',
            setRetailerProductSort: {
              __typename: 'SetRetailerProductSortPayload',
              retailerProduct: {
                id: movedProduct.id,
                __typename: 'RetailerProduct',
                sort: computeSortValue(movedProduct, prevProduct, nextProduct)
              }
            }
          }
        });
      } catch (err: unknown) {
        message.error(getFriendlyApolloErrorMessage(err, 'Failed to move product'));
      }
    },
    [retailerId, addPendingRetailerChange, setSort, message]
  );

  const handleCloseDrawer = useCallback(async () => {
    setOpen(false);
    await refetch(
      retailerId
        ? {
            cursor: undefined,
            retailerId,
            filter: {
              enabled: true
            }
          }
        : undefined
    );
  }, [refetch, retailerId]);

  const hasNextPage = data?.retailer.products?.pageInfo.hasNextPage || false;
  const nextCursor = data?.retailer.products?.pageInfo.endCursor;

  useEffect(() => {
    if (hasNextPage) {
      fetchMore({
        variables: {
          cursor: nextCursor,
          retailerId,
          filter: {
            enabled: true
          }
        }
      });
    }
  }, [hasNextPage, nextCursor, fetchMore, retailerId]);

  return (
    <>
      <SelectRetailerProductDrawer
        open={open}
        onClose={handleCloseDrawer}
        retailerId={retailerId}
        key={retailerId}
      />
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <ActionCol xs={24}>
          <Space>
            {loading && <Spinner />}
            <Button type={'primary'} onClick={() => setOpen(true)}>
              {intl.formatMessage(retailerProductMessages.selectProducts)}
            </Button>
          </Space>
        </ActionCol>
      </Row>

      <Row gutter={[16, 16]} wrap={true} style={{ paddingBottom: 64 }}>
        {(isEmpty || loading) && (
          <Col xs={24}>
            <StyledCard
              title={intl.formatMessage(retailerProductMessages.noEnabledProductsTitle)}
              loading={loading}
            >
              {isEmpty && (
                <Empty
                  description={intl.formatMessage(retailerProductMessages.noEnabledProducts)}
                />
              )}
            </StyledCard>
          </Col>
        )}
        {/*{loading && <Col xs={24}><Spin /></Col>}*/}
        {!loading &&
          enumKeys(ProductCategory)
            .map((key) => ProductCategory[key])
            .map((category) => {
              const categoryData = groupedData
                ? groupedData[category].map((c) => c.node)
                : undefined;

              if (!categoryData || categoryData.length === 0) {
                return null;
              }

              return (
                <Col xs={24} key={category}>
                  <StyledCard title={intl.formatMessage(productCategoryMessages[category])}>
                    <RetailerProductSortableList
                      category={category}
                      key={category}
                      data={categoryData}
                      loading={loading}
                      onMoveProduct={handleMoveProduct}
                    />
                  </StyledCard>
                </Col>
              );
            })}
      </Row>
    </>
  );
};

export default RetailerProductCard;
