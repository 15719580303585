import axios from 'axios';
import { endsWith } from 'lodash-es';
import SHA from 'sha.js';
import { appConfig } from 'common/appConfig';
import getAccessToken from 'auth/getAccessToken';

let BASE_URL = appConfig.API_REST_URL;
if (endsWith(BASE_URL, '/')) {
  BASE_URL = BASE_URL.slice(0, -1);
}

// Simple redux compatible wrapper around AxiosError
export interface ApiError {
  code?: string;
  message: string;
  status?: number;
}

export function isApiError(error: unknown): error is ApiError {
  return typeof error === 'object' && error !== null && 'message' in error && 'status' in error;
}

export const axiosInstance = axios.create({
  baseURL: BASE_URL
});

// Add a request interceptor to attach bearer token to requests
// NOTE: We could add a 401 interceptor here, but should be handled in most cases by getAccessToken
// https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token
axiosInstance.interceptors.request.use(async function (config) {
  config.withCredentials = true;
  const token = await getAccessToken('axios-interceptor', {
    skipRefresh: true
  });

  if (token?.bearerToken) {
    config.headers.setAuthorization(`Bearer ${token.bearerToken}`);
  }

  if (token?.antiForgeryToken) {
    config.headers.set('X-XSRF', token.antiForgeryToken);
  }

  return config;
});

export interface AccessToken {
  bearerToken: string;
  expires: string;
  antiForgeryToken: string;
}

export interface LoginResponse {
  accessToken: AccessToken;
  userLocale: string;
  authenticationType: string;
  userId: number;
  remember: boolean;
  expires?: string;
}

export interface LoginRequest {
  email: string;
  password: string;
  remember: boolean;
  publicSalt: string;
}

// interface to our REST API
const api = {
  refreshToken: async (antiForgeryToken?: string) => {
    return await axiosInstance.post<LoginResponse>(
      '/auth/refresh-token',
      {},
      {
        headers: antiForgeryToken
          ? {
              'X-XSRF': antiForgeryToken
            }
          : {}
      }
    );
  },
  login: async (request: LoginRequest) => {
    const { publicSalt, ...rest } = request;
    return await axiosInstance.post<LoginResponse>('/auth/login', {
      ...rest,
      password: SHA('sha256')
        .update(request.publicSalt + ':' + request.password)
        .digest('hex')
    });
  },
  getPublicSalt: async () => {
    const result = await axiosInstance.get<string>('/auth/getPublicSalt', {
      responseType: 'text'
    });
    return result.data;
  },
  logout: async (): Promise<void> => {
    await axiosInstance.post('/auth/logout');
  },
  ping: async (): Promise<string> => {
    return await axiosInstance.get('/ping');
  }
};

export default api;
