import React from 'react';
import { Breadcrumb, Space } from 'antd';
import styled from 'styled-components';
import useMatrixNav from 'layouts/matrix/useMatrixNav';
import { useGetMachineListItemQuery, useGetRetailerListItemQuery } from 'generated/types';
import getMachineIcon from 'components/icons/getMachineIcon';
import { useIntl } from 'react-intl';
import Flag from 'components/i18n/Flag/Flag';
import Link from 'components/lib/Link/Link';
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { GlobalOutlined } from '@ant-design/icons';
import { useMatrixLevelNumber } from 'layouts/matrix/useMatrixLevel.ts';
import PageHeaderRow from 'components/lib/page/rows/PageHeaderRow.tsx';

const TextSkeleton = styled.span`
  height: 10px;
  width: 80px;
  background-color: ${(props) => props.theme.ant.Skeleton?.color};
  border-radius: ${(props) => props.theme.ant.borderRadius}px;
  display: inline-flex;
`;

interface Props {
  overrideItems?: BreadcrumbItemType[];
}

const Breadcrumbs: React.FC<Props> = ({ overrideItems }) => {
  const { retailerId, machineId, getUrlToRoot, getUrlToMachine, getUrlToRetailer } = useMatrixNav();
  const intl = useIntl();
  const levelNumber = useMatrixLevelNumber();
  const urlRetailer =
    levelNumber && levelNumber >= 2 && retailerId ? getUrlToRetailer(retailerId) : undefined;
  const urlMachine =
    levelNumber && levelNumber >= 3 && machineId ? getUrlToMachine(machineId) : undefined;
  const urlRoot = getUrlToRoot();

  const { data: dataRetailer } = useGetRetailerListItemQuery({
    skip: retailerId === undefined,
    variables: {
      retailerId: retailerId || -1
    }
  });

  const { data: dataMachine } = useGetMachineListItemQuery({
    skip: !machineId,
    variables: machineId ? { id: machineId } : undefined
  });

  if (overrideItems !== undefined) {
    return <Breadcrumb items={overrideItems} />;
  }

  const items: BreadcrumbItemType[] = [];
  const globalText = levelNumber === 1 ? 'Global' : undefined;

  if (levelNumber && levelNumber >= 1) {
    items.push({
      key: urlRoot,
      title: (
        <Link to={urlRoot}>
          {!globalText && <GlobalOutlined />}
          {globalText && (
            <Space>
              <GlobalOutlined />
              <div>
                {intl.formatMessage({
                  id: 'matrix_breadcrumbs.global',
                  defaultMessage: 'Global'
                })}
              </div>
            </Space>
          )}
        </Link>
      )
    });
  }

  if (levelNumber && levelNumber >= 2 && urlRetailer) {
    items.push({
      key: urlRetailer,
      title: (
        <Link to={urlRetailer}>
          {' '}
          <Space size={'small'}>
            {dataRetailer && dataRetailer.retailer.country.countryCode && (
              <Flag country={dataRetailer.retailer.country} showTooltip={true} />
            )}
            {dataRetailer && <span>{dataRetailer.retailer.name}</span>}
            {!dataRetailer && <TextSkeleton />}
          </Space>
        </Link>
      )
    });
  }

  if (levelNumber && levelNumber >= 3 && urlMachine) {
    items.push({
      key: urlMachine,
      title: (
        <Link to={urlMachine}>
          <Space size={'small'}>
            {dataMachine && getMachineIcon(dataMachine.machine.machineType)}
            {dataMachine && (
              <span>
                <span>{dataMachine.machine.location || dataMachine.machine.serialNo}</span>
                <span> ({dataMachine.machine.serialNo})</span>
              </span>
            )}
            {!dataMachine && <TextSkeleton />}
          </Space>
        </Link>
      )
    });
  }

  return <Breadcrumb items={items} />;
};

export default Breadcrumbs;

export const BreadcrumbRow = styled(PageHeaderRow)<{ $isMobile: boolean }>`
  flex: 0 0 ${(props) => props.theme.connect.layout.breadcrumbHeight};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  position: ${(props) => (props.$isMobile ? 'relative' : 'sticky')};
  top: ${(props) => (props.$isMobile ? 'unset' : '0px')};
  z-index: 4;
`;
