import { useMemo } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

type CustomTranslationsUntyped = {
  [key: string | number]: MessageDescriptor;
};

type CustomTranslationsTyped<TEnum extends string | number> = {
  [keys in TEnum]: MessageDescriptor;
};

export type CustomTranslations<T extends string | number> = CustomTranslationsTyped<T> &
  CustomTranslationsUntyped;

export interface EnumOptionData<TEnum extends string | number> {
  rawValue: string | number;
  typedValue: TEnum;
  label: string;
  translationKey: string;
  translationMessage?: MessageDescriptor;
  value?: string | number | null;
}

export interface EnumOptionsArgs<TEnum extends string | number> {
  enumObject: { [key: string]: TEnum };
  keyPrefix?: string;
  customLabelMessages?: CustomTranslations<TEnum>;
}

/***
 * A hook to get translated option data from enum
 * @param options
 */
function useEnumOptions<TEnum extends string | number>(
  options: EnumOptionsArgs<TEnum>
): EnumOptionData<TEnum>[] {
  const { enumObject, keyPrefix, customLabelMessages } = options;

  const intl = useIntl();

  return useMemo(() => {
    const result: EnumOptionData<TEnum>[] = [];
    let translationMessage: undefined | MessageDescriptor = undefined;
    Object.keys(enumObject)
      .filter((key) => typeof enumObject[key] === 'number' || typeof enumObject[key] === 'string')
      .forEach((enumKey) => {
        const translationKey = `${keyPrefix || 'enum'}.${enumKey}`;
        const typedValue: TEnum = enumObject[enumKey];
        const rawValue: string | number = typedValue as unknown as string | number; // works??

        let label: string | number | undefined = typedValue;
        if (customLabelMessages) {
          translationMessage = customLabelMessages[rawValue];
          if (translationMessage !== undefined) {
            label = intl.formatMessage(translationMessage);
          }
        } else {
          label = intl.formatMessage({
            id: translationKey,
            defaultMessage: rawValue.toString()
          });
        }
        result.push({
          translationKey,
          translationMessage,
          label: label.toString(),
          rawValue,
          typedValue,
          value: rawValue
        });
      });

    return result;
  }, [enumObject, keyPrefix, customLabelMessages, intl]);
}

export default useEnumOptions;
