import React, { CSSProperties, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { OverlayViewF, useGoogleMap } from '@react-google-maps/api';
import { Position } from 'components/lib/ConnectMap/position.ts';
import { AnimatePresence, motion } from 'framer-motion';
import { Flex } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

interface Props {
  position: Position;
  children?: React.ReactNode;
  style?: CSSProperties;
  onClose?: () => void;
  title?: React.ReactNode;
}

/**
 * A custom InfoWindow since we cannot style the InfoWindow from Google Maps
 */
const MapInfoWindow: React.FC<Props> = ({ position, children, style, onClose, title }) => {
  const map = useGoogleMap();
  if (map === null) {
    throw new Error('MapInfoWindow cannot be used outside a google map context');
  }

  const hasCloseButton = onClose !== undefined;
  const myRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (myRef.current) {
      google.maps.OverlayView.preventMapHitsAndGesturesFrom(myRef.current);
    }
  }, [myRef]);

  return (
    <OverlayViewF
      mapPaneName={'overlayMouseTarget'}
      position={{
        lat: position.lat,
        lng: position.lng
      }}
      key={position.lat + position.lng}
      getPixelPositionOffset={() => ({
        x: -2,
        y: 6
      })}
    >
      <ParentPositionBox
        ref={myRef}
        onContextMenu={(event) => {
          event.stopPropagation();
        }}
      >
        <AnimatePresence>
          <Frame
            key={'frame'}
            style={style}
            initial={{
              height: 0,
              opacity: 0
            }}
            animate={{
              height: 'auto',
              opacity: 1
            }}
            exit={{
              height: 0,
              opacity: 0
            }}
            transition={{ duration: 0.2 }}
          >
            {(hasCloseButton || title) && (
              <Flex justify={'space-between'} align={'start'}>
                <div>{title}</div>
                {hasCloseButton && (
                  <CloseButton onClick={onClose}>
                    <CloseOutlined />
                  </CloseButton>
                )}
              </Flex>
            )}
            <Content ref={myRef}>{children}</Content>
          </Frame>
          <Cursor
            initial={{
              height: 0,
              opacity: 0
            }}
            animate={{
              height: 'auto',
              opacity: 1
            }}
            exit={{
              height: 0,
              opacity: 0
            }}
            transition={{ duration: 0.2 }}
          />
        </AnimatePresence>
      </ParentPositionBox>
    </OverlayViewF>
  );
};

// This box top left is at the given position
const ParentPositionBox = styled.div`
  background-color: transparent;
  position: relative;
  cursor: auto;
`;

const Frame = styled(motion.div)`
  background-color: ${(props) => props.theme.ant.colorBgContainer};
  width: 375px;
  position: absolute;
  bottom: 28px;
  left: 0;
  transform: translate(-50%, 0);
  border-radius: ${(props) => props.theme.ant.borderRadius}px;
  padding: ${(props) => props.theme.ant.padding}px;

  // NOTE: overriding google fonts. We have to load google fonts because of event bug, but can override here...
  && {
    font-family:
      -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5715;
  }

  display: flex;
  flex-direction: column;
  gap: 4px;

  box-shadow: ${(props) => props.theme.ant.boxShadow};
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
`;

const Cursor = styled(motion.div)`
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);

  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent ${(props) => props.theme.ant.colorBgContainer} transparent;
`;

const CloseButton = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.ant.colorIcon};
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${(props) => props.theme.ant.colorIconHover};
  }
`;

export default MapInfoWindow;
