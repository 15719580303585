import React, { useState } from 'react';
import { Button, Empty, List, Popconfirm, Space } from 'antd';
import { v4 } from 'uuid';
import styled from 'styled-components';
import { gql } from '@apollo/client/core';
import {
  useGetRetailerBlockedCardsQuery,
  useRemoveRetailerBlockedPaymentCardMutation
} from 'generated/types';
import { CreditCardOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Card from 'components/lib/Card/Card';
import commonMessages from 'components/i18n/commonMessages';
import AddBlockedCard from 'components/retailer/RetailerBlockedPaymentCardsCard/AddBlockedCard';
import useMessageApi from 'components/global/useMessageApi';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { useRetailerPermissions } from 'auth/RequireRetailerAccess.tsx';

interface Props {
  retailerId?: number;
}

gql`
  query GetRetailerBlockedCards($retailerId: Int!) {
    retailer(retailerId: $retailerId) {
      id
      retailerId
      blockedPaymentCards
    }
  }
`;

gql`
  mutation AddRetailerBlockedPaymentCard($input: AddRetailerBlockedPaymentCardInput!) {
    addRetailerBlockedPaymentCard(input: $input) {
      retailer {
        id
        retailerId
        blockedPaymentCards
      }
    }
  }
`;

gql`
  mutation RemoveRetailerBlockedPaymentCard($input: RemoveRetailerBlockedPaymentCardInput!) {
    removeRetailerBlockedPaymentCard(input: $input) {
      retailer {
        id
        retailerId
        blockedPaymentCards
      }
    }
  }
`;

const StyledCard = styled(Card)`
  && {
    .ant-empty-image {
      height: 75px;
    }
    .ant-empty-description {
      color: ${(props) => props.theme.ant.colorTextSecondary};
    }
  }
`;

const StyledList = styled(List<string>)`
  width: 100%;
  && {
    .ant-list-header {
      //border-bottom: none;
    }
    .ant-list-empty-text {
      //padding: 0;
    }
  }
`;

// truncated PAN
const RetailerPvmSettingsCard: React.FC<Props> = ({ retailerId }) => {
  const { data, loading } = useGetRetailerBlockedCardsQuery({
    variables: {
      retailerId: retailerId || -1
    },
    skip: retailerId === undefined,
    fetchPolicy: 'cache-and-network'
  });

  const intl = useConnectIntl();
  const { canEditRetailer } = useRetailerPermissions();
  const message = useMessageApi();

  const [remove] = useRemoveRetailerBlockedPaymentCardMutation();

  const [addRows, setAddRows] = useState<string[]>([]);

  const handleAddRow = () => {
    const tempId = v4();
    setAddRows([...addRows, tempId]);
  };

  const handleCancelAddRow = (tempId: string) => {
    const temp = [...addRows];
    temp.splice(temp.indexOf(tempId), 1);
    setAddRows(temp);
  };
  const handleRowAdded = async (tempId: string) => {
    const temp = [...addRows];
    temp.splice(temp.indexOf(tempId), 1);
    setAddRows(temp);
  };

  const handleDeleteRow = async (pan: string) => {
    if (!retailerId) return;

    try {
      message.loading({
        key: 'delete-' + pan,
        content: 'Deleting...'
      });

      await remove({
        variables: {
          input: {
            retailerId,
            cardNumber: pan
          }
        }
      });

      message.success({
        key: 'delete-' + pan,
        content: 'Deleted'
      });
    } catch (err) {
      message.error({
        key: 'delete-' + pan,
        content: 'Error deleting'
      });
    }
  };

  const isEmpty = data?.retailer.blockedPaymentCards.length === 0;

  return (
    <StyledCard title={'Blocked payment cards'} loading={loading}>
      <Space direction={'vertical'} style={{ width: '100%' }} size={'large'}>
        {!loading && isEmpty && <Empty />}
        {!loading && !isEmpty && (
          <StyledList
            size={'small'}
            // header={<Title level={4}>Blocked payment cards</Title>}
            // header={<Text type={'secondary'}>Blocked payment cards</Text>}
            loading={loading}
            bordered={false}
            dataSource={data?.retailer.blockedPaymentCards}
            renderItem={(item: string) => (
              <List.Item
                actions={
                  canEditRetailer
                    ? [
                        <Popconfirm
                          key={'delete-' + item}
                          title={intl.formatMessage(commonMessages.confirm_delete_item)}
                          onConfirm={() => handleDeleteRow(item)}
                          okText={intl.formatMessage(commonMessages.yes)}
                          cancelText={intl.formatMessage(commonMessages.no)}
                        >
                          <Button
                            key={'delete'}
                            icon={<DeleteOutlined />}
                            size={'small'}
                            type={'link'}
                          >
                            {intl.formatMsg(commonMessages.delete)}
                          </Button>
                        </Popconfirm>
                      ]
                    : undefined
                }
              >
                <Space>
                  <CreditCardOutlined /> <span>{item}</span>
                </Space>
              </List.Item>
            )}
          />
        )}
        {addRows.map((id) => (
          <AddBlockedCard
            key={id}
            retailerId={retailerId}
            id={id}
            onCancel={handleCancelAddRow}
            onSaved={handleRowAdded}
          />
        ))}
        {canEditRetailer && (
          <Button
            type={'dashed'}
            style={{ width: '100%' }}
            onClick={handleAddRow}
            disabled={loading || !canEditRetailer}
          >
            <Space size={'small'}>
              <PlusCircleOutlined />
              <span>Add blocked card</span>
            </Space>
          </Button>
        )}
      </Space>
    </StyledCard>
  );
};

export default RetailerPvmSettingsCard;
