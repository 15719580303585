import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client/core';
import {
  GetLastPredictionsQuery,
  OnPredictionCreatedDocument,
  OnPredictionCreatedSubscription,
  OnPredictionCreatedSubscriptionVariables,
  PredictionItemFragmentDoc,
  useGetLastPredictionsQuery
} from 'generated/types.tsx';
import styled from 'styled-components';
import { Alert, Button, Col, Divider, Empty, Flex, Row, Space, Statistic, Typography } from 'antd';
import {
  BlockOutlined,
  ClockCircleOutlined,
  ExportOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  TagsOutlined
} from '@ant-design/icons';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import Flag from 'components/i18n/Flag/Flag.tsx';
import RetailerLink from 'components/retailer/RetailerLink/RetailerLink.tsx';
import MachineLink from 'components/machine/MachineLink/MachineLink.tsx';
import useMatrixNav from 'layouts/matrix/useMatrixNav.ts';
import Spinner from 'components/lib/Spinner/Spinner.tsx';
import useDateFormatTools from 'i18n/useDateFormatTools.ts';
import { parseISO } from 'date-fns';
import ClassificationPercentage from 'components/prediction/ClassificationPercentage.tsx';
import Tag from 'components/lib/Tag/Tag.tsx';
import ResponsiveListCard from 'components/lib/List/ResponsiveListCard.tsx';
import PredictionSampleImage from 'components/prediction/PredictionSampleImage.tsx';
import { useMatrixLevelNumber } from 'layouts/matrix/useMatrixLevel.ts';
import predictionMessages from 'components/prediction/predictionMessages.ts';
import PredictionResult from 'components/prediction/PredictionResult.tsx';
import PredictionHistoryItem from 'components/prediction/PredictionHistoryItem.tsx';
import PredictorVersion from 'components/prediction/PredictorVersion.tsx';
import { AnimatePresence, motion } from 'framer-motion';
import getObjectModelColor from 'components/prediction/getObjectModelColor.ts';
import usePredictionResultMessage from 'components/prediction/usePredictionResultMessage.ts';
import Link from 'components/lib/Link/Link';
import useMessageApi from 'components/global/useMessageApi.ts';

gql`
  query GetLastPredictions($machineId: Int, $retailerId: Int, $first: Int) {
    allPredictions(
      first: $first
      sortDirection: DESCENDING
      sortField: DATE
      filter: { machineId: $machineId, retailerId: $retailerId }
    ) {
      edges {
        node {
          ...PredictionItem
        }
      }
      totalCount
    }
  }
  ${PredictionItemFragmentDoc}
`;

gql`
  subscription OnPredictionCreated($machineId: Int, $retailerId: Int) {
    onPredictionCreated(machineId: $machineId, retailerId: $retailerId) {
      ...PredictionItem
    }
  }
  ${PredictionItemFragmentDoc}
`;

interface Props {
  machineId?: number;
  retailerId?: number;
}

const LastPredictionCard: React.FC<Props> = ({ machineId, retailerId }) => {
  const intl = useConnectIntl();
  const { resolving } = useMatrixNav();
  const { formatDate } = useDateFormatTools();
  const countInitial = 4;
  const countMax = 4;
  const messageApi = useMessageApi();

  const { data, error, loading, subscribeToMore } = useGetLastPredictionsQuery({
    variables: {
      machineId,
      retailerId: machineId ? undefined : retailerId,
      first: countInitial
    },
    skip: resolving,
    notifyOnNetworkStatusChange: true,
    pollInterval: 1000 * 60 * 5 // 5 minutes
  });

  useEffect(() => {
    if (resolving) {
      return;
    }
    const unsubscribe = subscribeToMore<
      OnPredictionCreatedSubscription,
      OnPredictionCreatedSubscriptionVariables
    >({
      document: OnPredictionCreatedDocument,
      variables: {
        machineId,
        retailerId: machineId ? undefined : retailerId
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }
        const newPrediction = subscriptionData.data.onPredictionCreated;
        const newEdges = [
          {
            node: newPrediction
          },
          ...(prev.allPredictions?.edges || [])
        ];

        // if newEdges has more than countMax elements, remove the last one
        if (newEdges.length > countMax) {
          newEdges.pop();
        }

        const temp: GetLastPredictionsQuery = {
          ...prev,
          allPredictions: {
            ...prev.allPredictions,
            totalCount: prev.allPredictions?.totalCount ? prev.allPredictions.totalCount + 1 : 1,
            edges: newEdges
          }
        };
        return temp;
      },
      onError: (err) => {
        console.log('subscription error', err);
      }
    });

    return () => unsubscribe();
  }, [machineId, messageApi, resolving, retailerId, subscribeToMore]);

  const predictionCurrent = data?.allPredictions?.edges?.[0]?.node || undefined;

  const multiClassList = React.useMemo(() => {
    if (!predictionCurrent || !predictionCurrent.multiClassList) {
      return [];
    }

    return predictionCurrent.multiClassList
      .filter((c) => c.score > 0.01)
      .sort((a, b) => b.score - a.score);
  }, [predictionCurrent]);

  const objectClassList = React.useMemo(() => {
    if (!predictionCurrent || !predictionCurrent.objectClassList) {
      return [];
    }

    return predictionCurrent.objectClassList.filter((c) => c.score > 0.3);
  }, [predictionCurrent]);

  const hasZeroClassifications = multiClassList.length === 0 && objectClassList.length === 0;
  const isEmptyResult = data?.allPredictions?.edges?.length === 0;

  const resultMessageName = usePredictionResultMessage(predictionCurrent, 'full');
  const resultMessageSuffix =
    predictionCurrent?.result.__typename === 'ProductFoundPredictionResult'
      ? `(${predictionCurrent.result.predictedProductId})`
      : '';
  const resultMessage = `${resultMessageName} ${resultMessageSuffix}`;

  const classifications = (
    <Flex vertical={true} wrap={'wrap'} gap={'small'}>
      {hasZeroClassifications && (
        <Flex>
          <Alert
            message={intl.formatMsg(predictionMessages.noClassificationsWarning)}
            type={'warning'}
          />
        </Flex>
      )}
      {multiClassList
        .sort((a, b) => {
          if (a.model.modelTypeName === b.model.modelTypeName) {
            return b.score - a.score;
          }
          return a.model.modelTypeName.localeCompare(b.model.modelTypeName);
        })
        .map((c) => (
          <Space key={c.id} size={'small'} wrap={true}>
            <ClassificationPercentage score={c.score} minScore={c.minScore} />
            <Tag icon={<TagsOutlined />}>{`${c.model.modelTypeName}: ${c.label}`}</Tag>
          </Space>
        ))}

      {objectClassList
        .sort((a, b) => a.score - b.score)
        .reverse()
        .map((c) => {
          const color = getObjectModelColor(c.label);

          return (
            <Space key={c.id} size={'small'} wrap={true}>
              <ClassificationPercentage score={c.score} minScore={c.minScore} />

              <Tag
                icon={<BlockOutlined />}
                color={color}
              >{`${c.model.modelTypeName}: ${c.label}`}</Tag>
            </Space>
          );
        })}
    </Flex>
  );

  const cardRef = React.useRef<HTMLDivElement>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const element = cardRef.current;

    if (!element) {
      return;
    }

    const handleFull = () => {
      if (document.fullscreenElement) {
        setIsFullscreen(true);
      } else {
        setIsFullscreen(false);
      }
    };
    element.addEventListener('fullscreenchange', handleFull);
    return () => {
      element.removeEventListener('fullscreenchange', handleFull);
    };
  }, []);

  const handleToggleFullscreen = async () => {
    const element = cardRef.current;
    if (!element) {
      return;
    }

    if (isFullscreen) {
      await document.exitFullscreen();
    } else {
      await element.requestFullscreen();
    }
  };

  const levelNumber = useMatrixLevelNumber();
  const infoItems: React.ReactNode[] = [];
  if (predictionCurrent) {
    if (levelNumber !== undefined && levelNumber < 2 && predictionCurrent.machine) {
      infoItems.push(
        <MachineLink
          machine={predictionCurrent.machine}
          key={'machine'}
          appendSerialNumber={true}
        />
      );
    }
    if (levelNumber !== undefined && levelNumber < 1 && predictionCurrent.machine.retailer) {
      infoItems.push(
        <RetailerLink retailer={predictionCurrent.machine.retailer} key={'retailer'} />
      );
    }
    if (
      levelNumber !== undefined &&
      levelNumber < 1 &&
      predictionCurrent.machine.retailer?.__typename === 'Retailer' &&
      predictionCurrent.machine.retailer.country.countryCode
    ) {
      infoItems.push(
        <Space size={4} key={'country'}>
          <Flag country={predictionCurrent.machine.retailer.country} showTooltip={false} />
          <div>{predictionCurrent.machine.retailer.country.nameEnglish}</div>
        </Space>
      );
    }
  }

  return (
    <ResponsiveListCard
      title={'Live prediction feed'}
      extra={
        <Button
          icon={isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
          onClick={handleToggleFullscreen}
        />
      }
      ref={cardRef}
      style={{
        overflow: 'hidden'
      }}
    >
      {loading && !data && (
        <Row justify={'center'}>
          <Col span={4}>
            <Spinner />
          </Col>
        </Row>
      )}
      {error && <Alert message={error.message} type={'error'} style={{ marginBottom: 16 }} />}
      {isEmptyResult && <Empty />}
      {predictionCurrent && (
        <Row gutter={[16, 16]} wrap={true}>
          <Col xs={12} xl={6}>
            <SampleImageContainer>
              <PredictionSampleImage
                image={{
                  src: predictionCurrent.sample.dataUrl,
                  alt: `Sample image ${predictionCurrent.sample.id}`,
                  width: predictionCurrent.sample.width,
                  height: predictionCurrent.sample.height
                }}
                detectedObjects={predictionCurrent.objectClassList.map((c) => ({
                  naturalX: c.x,
                  naturalY: c.y,
                  naturalWidth: c.width,
                  naturalHeight: c.height,
                  color: getObjectModelColor(c.label)
                }))}
              />
            </SampleImageContainer>
          </Col>
          <Col xs={12} xl={6} style={{ transition: 'height 0.3s ease-in-out' }}>
            <PredictionResultContainer>
              <PredictionResult prediction={predictionCurrent} size={'normal'} />
            </PredictionResultContainer>
          </Col>
          <InfoCol xs={24} xl={12}>
            <AnimatePresence mode={'wait'}>
              <InfoContainer
                key={predictionCurrent.predictionId}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.15 }}
              >
                <Statistic
                  title={intl.formatMsg(predictionMessages.productLabel)}
                  value={resultMessage}
                />
                <Divider style={{ margin: 8 }} />
                {classifications}
                <Divider style={{ margin: 8 }} />
                <PredictorVersion prediction={predictionCurrent} />
                <Typography.Text>
                  <ClockCircleOutlined />
                  <span style={{ marginLeft: 4 }}>
                    {formatDate(parseISO(predictionCurrent.timestamp), {
                      representation: 'complete'
                    })}
                  </span>
                </Typography.Text>
                <Link to={`/prediction/${predictionCurrent.predictionId}`}>
                  <ExportOutlined />
                  <span style={{ marginLeft: 4 }}>
                    {intl.formatMsg(predictionMessages.openPrediction, {
                      predictionId: predictionCurrent.predictionId
                    })}
                  </span>
                </Link>
                {infoItems}
              </InfoContainer>
            </AnimatePresence>
          </InfoCol>
          <HistoryCol xs={24}>
            <AnimatePresence mode={'popLayout'}>
              {data?.allPredictions?.edges
                ?.map((c) => c.node)
                .map((prediction, index) => {
                  if (index === 0) {
                    return null;
                  }
                  return (
                    <motion.div
                      key={prediction.predictionId}
                      layout={true}
                      initial={{ opacity: 0, x: -100, scale: 0.6 }}
                      animate={{ opacity: 1, x: 0, scale: 1 }}
                      exit={{ opacity: 0, x: 100, scale: 1.2 }}
                      transition={{ duration: 0.5, type: 'spring' }}
                      style={{
                        flex: '0 0 300px'
                      }}
                    >
                      <Link to={`/prediction/${prediction.predictionId}`}>
                        <PredictionHistoryItem prediction={prediction} />
                      </Link>
                    </motion.div>
                  );
                })}
            </AnimatePresence>
          </HistoryCol>
        </Row>
      )}
    </ResponsiveListCard>
  );
};

const SampleImageContainer = styled.div`
  background: ${(props) => props.theme.ant.colorSuccessBg};
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;

  border-radius: 6px;
  overflow: hidden;
`;

const PredictionResultContainer = styled.div`
  background: ${(props) => props.theme.ant.colorSuccessBg};
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;

  border-radius: 6px;
  overflow: hidden;
`;

const InfoCol = styled(Col)`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  @media all and (display-mode: fullscreen) {
    justify-content: center;
    align-items: center;
  }
  transition: height 0.3s ease-in-out;
`;

const HistoryCol = styled(Col)`
  display: flex;
  gap: 16px;
  @media all and (display-mode: fullscreen) {
    justify-content: center;
    align-items: center;
  }
  flex-wrap: wrap;
`;

const InfoContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
`;

export default LastPredictionCard;
