import React, { useContext } from 'react';

const PositionContext = React.createContext<GeolocationPosition | undefined>(undefined);

export const PositionProvider = PositionContext.Provider;

/***
 * Hook to get current position from context
 */
export default function useMapPosition() {
  return useContext(PositionContext);
}
