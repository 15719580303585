import { defineMessages } from 'react-intl';

export const ticketAccountingMessages = defineMessages({
  cannot_edit_accounted: {
    id: 'ticket.accounting.cannot_edit_accounted',
    defaultMessage:
      'Set as accounted by {user} {timestamp}. Accounted order lines are locked and cannot be edited.'
  },
  accounted: {
    id: 'ticket.accounting.accounted',
    defaultMessage: 'Accounted'
  },
  col_article: {
    id: 'ticket.accounting.col_article',
    defaultMessage: 'Article'
  },
  col_account: {
    id: 'ticket.accounting.col_account',
    defaultMessage: 'Account'
  },
  col_storage: {
    id: 'ticket.accounting.col_storage',
    defaultMessage: 'Storage'
  },
  col_user: {
    id: 'ticket.accounting.col_user',
    defaultMessage: 'User'
  },
  col_quantity: {
    id: 'ticket.accounting.col_quantity',
    defaultMessage: 'Quantity'
  },
  col_unit_price: {
    id: 'ticket.accounting.col_unit_price',
    defaultMessage: 'Unit price'
  },
  col_discount: {
    id: 'ticket.accounting.col_discount',
    defaultMessage: 'Discount'
  },
  col_amount: {
    id: 'ticket.accounting.col_amount',
    defaultMessage: 'Amount'
  },
  col_actions: {
    id: 'ticket.accounting.col_actions',
    defaultMessage: 'Actions'
  },
  col_description: {
    id: 'ticket.accounting.col_description',
    defaultMessage: 'Note'
  },
  col_date: {
    id: 'ticket.accounting.col_date',
    defaultMessage: 'Date'
  },
  save_line_error: {
    id: 'ticket.accounting.save_line_error',
    defaultMessage: 'Could not save ticket accounting line'
  },
  col_price_out: {
    id: 'ticket.accounting.col_price_out',
    defaultMessage: 'Price'
  },
  col_sum: {
    id: 'ticket.accounting.col_sum',
    defaultMessage: 'Sum'
  },
  col_ticket: {
    id: 'ticket.accounting.col_ticket',
    defaultMessage: 'Ticket'
  },
  col_ticket_type: {
    id: 'ticket.accounting.col_ticket_type',
    defaultMessage: 'Type'
  },
  col_machine: {
    id: 'ticket.accounting.col_machine',
    defaultMessage: 'Machine'
  },
  col_retailer: {
    id: 'ticket.accounting.col_retailer',
    defaultMessage: 'Retailer'
  },
  col_mark: {
    id: 'ticket.accounting.col_mark',
    defaultMessage: 'Set accounted'
  },
  deletedArticle: {
    id: 'ticket.accounting.deleted_article',
    defaultMessage: 'Deleted article'
  },
  article: {
    id: 'ticket.accounting.article',
    defaultMessage: '{productId} {productName}'
  },
  articleVendor: {
    id: 'ticket.accounting.article_vendor',
    defaultMessage: '({vendor} {vendorId})'
  },
  accountTip: {
    id: 'ticket.accounting.order_line.account_tip',
    defaultMessage: 'Suggested: Service agreement'
  },
  priceOutTip: {
    id: 'ticket.accounting.order_line.price_out_tip',
    defaultMessage: '({price})'
  },
  placeholderStorageSelect: {
    id: 'ticket.accounting.order_line.placeholder_storage_select',
    defaultMessage: 'Storage'
  }
});
