import { defineMessages } from 'react-intl';

const retailerMessages = defineMessages({
  regno1NamePlaceholder: {
    id: 'retailer.fields.regno1_name_placeholder',
    defaultMessage: 'Org. nr'
  },
  regno1Placeholder: {
    id: 'retailer.fields.regno1_placeholder',
    defaultMessage: 'NO-987654321MVA'
  },
  regno2Placeholder: {
    id: 'retailer.fields.regno2_placeholder',
    defaultMessage: 'Norway: Foretaksregisteret'
  },
  submit: {
    id: 'retailer.button.submit',
    defaultMessage: 'Add retailer',
    description: 'Create retailer button'
  },
  submitting: {
    id: 'retailer.msg.submitting',
    defaultMessage: 'Creating retailer...'
  },
  errorSubmit: {
    id: 'retailer.error.error_submit',
    defaultMessage: 'Error creating retailer: {msg}'
  },
  submitSuccess: {
    id: 'retailer.msg.submit_success',
    defaultMessage: 'Retailer created. We enabled inactive retailers in the main menu for you.'
  },
  name: {
    id: 'retailer.fields.name',
    defaultMessage: 'Name'
  },
  address1: {
    id: 'retailer.fields.address',
    defaultMessage: 'Address 1'
  },
  address2: {
    id: 'retailer.fields.address2',
    defaultMessage: 'Address 2'
  },
  city: {
    id: 'retailer.fields.city',
    defaultMessage: 'City'
  },
  postalCode: {
    id: 'retailer.fields.postal_code',
    defaultMessage: 'Postal Code'
  },
  country: {
    id: 'retailer.fields.country',
    defaultMessage: 'Country'
  },
  phone: {
    id: 'retailer.fields.phone',
    defaultMessage: 'Phone'
  },
  email: {
    id: 'retailer.fields.email',
    defaultMessage: 'Email'
  },
  website: {
    id: 'retailer.fields.website',
    defaultMessage: 'Website'
  },
  currency: {
    id: 'retailer.fields.currency',
    defaultMessage: 'Currency'
  },
  timezone: {
    id: 'retailer.fields.timezone',
    defaultMessage: 'Timezone'
  },
  cylinderRemoveCapMessage: {
    id: 'retailer.fields.cylinder_remove_cap_message',
    defaultMessage: 'Cylinder remove cap message'
  },
  operatorInspection: {
    id: 'retailer.fields.operator_inspection',
    defaultMessage: 'Operator inspection'
  },
  operatorInspectionPlaceholder: {
    id: 'retailer.fields.operator_inspection_placeholder',
    defaultMessage: 'Select a option'
  },
  returnCommerce: {
    id: 'retailer.fields.return_commerce',
    defaultMessage: 'Return commerce'
  },
  uniqueRetailerReferences: {
    id: 'retailer.fields.unique_retailer_references',
    defaultMessage: 'Unique retailer references'
  },
  webOverrideHmi: {
    id: 'retailer.fields.web_override_hmi',
    defaultMessage: 'Web override HMI'
  },
  vat: {
    id: 'retailer.fields.vat',
    defaultMessage: 'VAT'
  },
  countryCode: {
    id: 'retailer.fields.country_code',
    defaultMessage: 'Country code'
  },
  invalidRetailerId: {
    id: 'retailer.error.invalid_retailer_id',
    defaultMessage: 'Invalid retailer id'
  },
  redeployWarningAll: {
    id: 'retailer.redeploy_warning_all',
    defaultMessage:
      'This will redeploy all machine parameters to all machines for all retailers! Use with caution!'
  },
  redeployWarningRetailer: {
    id: 'retailer.redeploy_warning_retailer',
    defaultMessage:
      'This will redeploy parameters to all machines for this retailer! Use with caution!'
  },
  pvmSettings: {
    id: 'retailer.fields.pvm_settings',
    defaultMessage: 'PVM Settings'
  }
});

export default retailerMessages;
