import { StopOutlined } from '@ant-design/icons';
import Tag from 'components/lib/Tag/Tag.tsx';
import React from 'react';
import useConnectIntl from 'i18n/useConnectIntl.ts';

const DeactivatedMachineTag: React.FC = () => {
  const intl = useConnectIntl();

  return (
    <Tag icon={<StopOutlined />} color={'black'}>
      {intl.formatMessage({
        id: 'deactivated_machine_tag.deactivated',
        defaultMessage: 'Deactivated'
      })}
    </Tag>
  );
};

export default DeactivatedMachineTag;
