import React, { useEffect, useState } from 'react';
import { DeletedUserListItemFragment, UserListItemFragment } from 'generated/types.tsx';
import useDateFormatTools from 'i18n/useDateFormatTools.ts';
import useFormatTools from 'i18n/useFormatTools.ts';
import { useIntl } from 'react-intl';
import UserAvatar from 'components/user/UserAvatar/UserAvatar.tsx';
import { Space, Typography } from 'antd';
const { Text } = Typography;

const ChangedByUser: React.FC<{
  user: UserListItemFragment | DeletedUserListItemFragment;
  changed: Date;
  showText?: boolean;
}> = (props) => {
  const { user, changed, showText } = props;
  const { formatDistanceToNow, formatDate } = useDateFormatTools();
  const { formatUserName } = useFormatTools();
  const intl = useIntl();

  const tooltip = intl.formatMessage(
    {
      id: 'changed_by_user.tooltip',
      defaultMessage: 'Last changed by {user} {ago} {date}'
    },
    {
      user: formatUserName(user),
      ago: changed
        ? formatDistanceToNow(changed, {
            addSuffix: true,
            includeSeconds: true
          })
        : undefined,
      date: changed ? (
        <>
          <br />
          {formatDate(changed, { representation: 'complete' })}
        </>
      ) : undefined
    }
  );

  const [, setNow] = useState(new Date());
  const tick = () => {
    setNow(new Date());
  };

  useEffect(() => {
    const interval = setInterval(() => {
      tick();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const avatar = <UserAvatar user={user} tooltip={<>tooltip</>} />;

  if (showText) {
    return (
      <Space direction={'horizontal'}>
        {avatar}
        <Text type={'secondary'}>{tooltip}</Text>
      </Space>
    );
  }

  return avatar;
};

export default ChangedByUser;
