import React, { useCallback, useEffect, useRef } from 'react';
import { useGetMachineListQuery, useGetMachinePermissionsLazyQuery } from 'generated/types';
import useMatrixNav from 'layouts/matrix/useMatrixNav';
import { Empty } from 'antd';
import Menu from 'components/lib/Menu/Menu';
import MenuItem, { MenuItemSkeleton } from 'components/lib/Menu/MenuItem';
import getMachineIcon from 'components/icons/getMachineIcon';
import DeactivatedMachineTag from 'components/machine/DeactivatedMachineTag/DeactivatedMachineTag.tsx';
import { useAppDispatch, useAppSelector } from 'redux/store.ts';
import {
  closeMobileSearchDrawer,
  selectMachineNavFilter,
  selectSearchTerm
} from 'redux/machineNavSlice.ts';

interface Props {
  retailerId: number;
  machineCountHint?: number;
}

type MachineRefDict = { [machineId: number]: HTMLLIElement | null };

const MachineSearchResult: React.FC<Props> = ({ retailerId, machineCountHint }) => {
  const dispatch = useAppDispatch();
  const { showInactiveMachines } = useAppSelector(selectMachineNavFilter);
  const searchTerm = useAppSelector(selectSearchTerm);
  const { machineId, getUrlToMachine, resolving, selectedLevel } = useMatrixNav();

  const { data, loading, fetchMore } = useGetMachineListQuery({
    variables: {
      cursor: undefined,
      input: {
        search: searchTerm,
        retailerId,
        active: showInactiveMachines ? undefined : true
      }
    }
  });

  const hasNextPage = data?.allMachines?.pageInfo.hasNextPage || false;
  const nextCursor = data?.allMachines?.pageInfo.endCursor;

  const machineRef = useRef<MachineRefDict>({});

  useEffect(() => {
    async function fetchMoreMachines() {
      await fetchMore({
        variables: {
          cursor: nextCursor,
          input: {
            search: searchTerm,
            retailerId,
            active: showInactiveMachines ? undefined : true
          }
        }
      });
    }

    if (hasNextPage) {
      fetchMoreMachines();
    }
  }, [fetchMore, hasNextPage, nextCursor, retailerId, searchTerm, showInactiveMachines, machineId]);

  useEffect(() => {
    if (
      (selectedLevel === 'machine' || selectedLevel === 'ticket') &&
      !resolving &&
      !loading &&
      !hasNextPage &&
      machineId !== undefined
    ) {
      const machineDiv = machineRef.current[machineId];

      if (machineDiv) {
        // NOTE: if selected machine is in viewport we don't scroll to view
        const top = machineDiv.getBoundingClientRect().top;
        const offset = 0;
        const isVisible = top + offset >= 0 && top - offset <= window.innerHeight;

        if (!isVisible) {
          machineDiv.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
      }
    }
  }, [selectedLevel, resolving, loading, hasNextPage, machineId]);

  const handleSelectMachine = useCallback(() => {
    dispatch(closeMobileSearchDrawer());
  }, [dispatch]);

  const [getMachinePermissions] = useGetMachinePermissionsLazyQuery();

  const preloadMachine = useCallback(
    async (machineId: number) => {
      await getMachinePermissions({
        variables: {
          machineId
        },
        fetchPolicy: 'cache-first'
      });
    },
    [getMachinePermissions]
  );

  if (loading) {
    return <MenuItemSkeleton rows={machineCountHint} />;
  }

  return (
    <Menu isSubMenu={true}>
      {data?.allMachines?.edges?.length === 0 && (
        <Empty description={'No machines yet!'} style={{ padding: 16 }} />
      )}
      {data?.allMachines?.edges?.map((c) => {
        const machine = c?.node;
        if (!machine) return null;
        const title = machine.location || machine.serialNo;
        const icon = getMachineIcon(machine.machineType);

        return (
          <MenuItem
            key={c.node.id}
            ref={(el) => (machineRef.current[c.node.machineId] = el)}
            title={title}
            leftIcon={icon}
            isSelected={machineId === c.node.machineId}
            to={getUrlToMachine(c.node.machineId)}
            rightIcon={!machine.active ? <DeactivatedMachineTag /> : null} // <== temporary solution..
            indent={1}
            onClick={handleSelectMachine}
            onHoverIntent={() => preloadMachine(c.node.machineId)}
          />
        );
      })}
    </Menu>
  );
};

export default MachineSearchResult;
