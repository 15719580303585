import React, { CSSProperties } from 'react';
import { Col, Row, Space } from 'antd';
import TicketStatusFilterRadio from 'components/ticket/TicketFilter/TicketStatusFilterRadio.tsx';
import TicketSeverityFilterSelect from 'components/ticket/TicketFilter/TicketSeverityFilterSelect.tsx';
import TicketSearchFilterInput from 'components/ticket/TicketFilter/TicketSearchFilterInput.tsx';
import TicketTypeFilterSelect from 'components/ticket/TicketForm/TicketTypeFilterSelect.tsx';
import TicketAssigneeFilterSelect from 'components/ticket/TicketFilter/TicketAssigneeFilterSelect.tsx';
import useIsMobile from 'layouts/responsive/useIsMobile.ts';
import TicketTagsFilterSelect from 'components/ticket/TicketFilter/TicketTagsFilterSelect.tsx';
import TicketDueBeforeFilterSelect from 'components/ticket/TicketFilter/TicketDueBeforeFilterSelect.tsx';
import TicketCreatedByFilterSelect from 'components/ticket/TicketFilter/TicketCreatedByFilterSelect.tsx';

interface Props {
  style?: CSSProperties;
}

const TicketListFilter: React.FC<Props> = ({ style }) => {
  const isMobile = useIsMobile();
  return (
    <>
      <Row gutter={[16, 16]} wrap={true} style={{ marginBottom: 16, ...style }}>
        <Col xs={24} xl={16}>
          <TicketStatusFilterRadio style={{ width: '100%' }} />
        </Col>
        <Col xs={24} xl={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <TicketCreatedByFilterSelect
            size={'middle'}
            style={{ width: '100%' }}
            maxTagCount={isMobile ? 1 : undefined}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} wrap={true} style={style}>
        <Col xs={24} xl={8}>
          <Space direction={'vertical'} style={{ width: '100%' }}>
            <TicketSeverityFilterSelect size={'middle'} style={{ width: '100%' }} />

            <TicketTypeFilterSelect
              size={'middle'}
              style={{ width: '100%' }}
              maxTagCount={isMobile ? 1 : undefined}
            />
          </Space>
        </Col>
        <Col xs={24} xl={8}>
          <Space direction={'vertical'} style={{ width: '100%', flexWrap: 'wrap' }}>
            <TicketSearchFilterInput />

            <TicketTagsFilterSelect
              size={'middle'}
              style={{ width: '100%' }}
              maxTagCount={isMobile ? 1 : undefined}
            />
          </Space>
        </Col>
        <Col xs={24} xl={8}>
          <Space direction={'vertical'} style={{ width: '100%' }}>
            <TicketAssigneeFilterSelect
              size={'middle'}
              style={{ width: '100%' }}
              maxTagCount={isMobile ? 1 : undefined}
            />
            <TicketDueBeforeFilterSelect />
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default TicketListFilter;
