import React, { SVGProps } from 'react';

export const NotFoundIllustration: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 252 294' {...props}>
    <g clipPath='url(#clip0_1805_118)'>
      <path
        fill='#4B7F99'
        fillOpacity='.11'
        fillRule='evenodd'
        d='M-1 127.332v-2.095C-1 56.291 54.892.399 123.838.399h2.096c68.946 0 124.838 55.892 124.838 124.838v2.096c0 68.946-55.892 124.838-124.838 124.838h-2.096C54.892 252.172-1 196.278-1 127.332Z'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M38.755 130.84a8.269 8.269 0 0 1-2.98 5.625 8.27 8.27 0 0 1-6.09 1.846 8.268 8.268 0 0 1-5.601-3.025 8.265 8.265 0 0 1-1.797-6.106 8.277 8.277 0 0 1 16.468 1.66Z'
        clipRule='evenodd'
      />
      <path
        stroke='#fff'
        strokeWidth='2'
        d='m35.975 134.297 10.482 5.943m.916 6.268-12.648 10.788'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M38.875 159.352a5.669 5.669 0 0 1-6.206 5.07 5.664 5.664 0 0 1-5.071-6.206 5.674 5.674 0 0 1 2.053-3.821 5.674 5.674 0 0 1 4.153-1.25 5.68 5.68 0 0 1 3.821 2.054 5.66 5.66 0 0 1 1.25 4.153Zm17.713-16.105a5.703 5.703 0 0 1-4.028 4.939 5.695 5.695 0 0 1-4.37-.44 5.715 5.715 0 0 1-2.763-3.413 5.713 5.713 0 0 1 1.885-6.045 5.704 5.704 0 0 1 7.997.806 5.707 5.707 0 0 1 1.279 4.153Zm41.43-116.372 29.82-.014a4.586 4.586 0 0 0 4.586-4.589 4.579 4.579 0 0 0-1.345-3.243 4.59 4.59 0 0 0-3.244-1.343l-29.82.013a4.587 4.587 0 0 0-1.753 8.827c.557.23 1.153.35 1.756.349Zm11.406 18.336 29.82-.013a4.59 4.59 0 0 0 4.518-4.59 4.586 4.586 0 0 0-4.522-4.585l-29.82.013a4.586 4.586 0 0 0-4.585 4.59 4.58 4.58 0 0 0 2.833 4.237c.557.23 1.154.348 1.756.348Z'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M111.798 26.861v-.002l15.784-.006a4.585 4.585 0 0 0-4.654 4.589 4.584 4.584 0 0 0 4.657 4.586l-15.783.007v-.002a4.585 4.585 0 1 0-.004-9.172Zm71.725 108.807c-.553 5.485-5.447 9.483-10.931 8.93-5.485-.553-9.483-5.448-8.93-10.932.552-5.485 5.447-9.483 10.932-8.93 5.485.553 9.483 5.447 8.93 10.932'
        clipRule='evenodd'
      />
      <path stroke='#fff' strokeWidth='2' d='m178.26 141.75 12.64 7.167m1.106 7.56-15.255 13.011' />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M183.668 170.057a6.831 6.831 0 0 1-4.82 5.934 6.827 6.827 0 0 1-8.551-4.629 6.838 6.838 0 0 1 2.271-7.229 6.835 6.835 0 0 1 11.1 5.924Zm18.672-16.732a6.896 6.896 0 0 1-2.478 4.68 6.895 6.895 0 0 1-5.069 1.536 6.883 6.883 0 0 1-6.153-7.598 6.887 6.887 0 0 1 12.159-3.63 6.89 6.89 0 0 1 1.541 5.012Z'
        clipRule='evenodd'
      />
      <path
        stroke='#fff'
        strokeWidth='2'
        d='M150.931 192.324a2.222 2.222 0 1 1-4.443 0 2.222 2.222 0 0 1 4.443 0Zm73.339-76.268a2.224 2.224 0 0 1-3.794 1.572 2.224 2.224 0 0 1 1.571-3.794 2.222 2.222 0 0 1 2.222 2.222h.001Zm-8.89 35.024c0 .292-.058.581-.169.851a2.222 2.222 0 0 1-2.905 1.202 2.21 2.21 0 0 1-1.203-1.203 2.22 2.22 0 0 1 .482-2.423 2.222 2.222 0 0 1 3.795 1.572v.001Zm-39.463-43.444a2.222 2.222 0 0 1-3.811 1.602 2.222 2.222 0 1 1 3.811-1.602Zm18.374-15.471a2.227 2.227 0 0 1-3.084 2.096 2.222 2.222 0 0 1 .861-4.272 2.225 2.225 0 0 1 2.223 2.176Zm6.767 88.546a2.222 2.222 0 1 1-4.444.001 2.222 2.222 0 0 1 4.444-.001Z'
        clipRule='evenodd'
      />
      <path
        stroke='#fff'
        strokeWidth='2'
        d='m213.404 153.302-1.912 20.184-10.928 5.99m-27.903-4.684-6.356 9.814h-11.36l-4.508 6.484m23.504-65.922v-15.804m45.883 7.886-12.84 7.901-15.31-7.902V94.39'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M165.588 65.936h-3.951a4.757 4.757 0 0 1-4.743-4.742 4.753 4.753 0 0 1 4.743-4.743h3.951a4.756 4.756 0 0 1 4.743 4.743 4.757 4.757 0 0 1-4.743 4.742Z'
        clipRule='evenodd'
      />
      <path
        fill='#0A747E'
        fillRule='evenodd'
        d='M173.823 30.03c0-16.281 13.198-29.48 29.48-29.48 16.28 0 29.48 13.199 29.48 29.48 0 16.28-13.2 29.48-29.48 29.48-16.282 0-29.48-13.2-29.48-29.48Z'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M204.952 38.387c.5.5.785 1.142.785 1.928s-.286 1.465-.785 1.964a2.9 2.9 0 0 1-2 .75c-.785 0-1.429-.285-1.929-.785-.572-.5-.82-1.143-.82-1.929s.248-1.428.82-1.928c.5-.5 1.144-.75 1.93-.75.785 0 1.462.25 1.999.75Zm4.285-19.463c1.428 1.249 2.143 2.963 2.143 5.142 0 1.712-.427 3.13-1.219 4.25-.067.096-.137.18-.218.265-.416.429-1.41 1.346-2.956 2.699a5.06 5.06 0 0 0-1.428 1.75 5.204 5.204 0 0 0-.536 2.357v.5h-4.107v-.5c0-1.357.215-2.536.714-3.5.464-.964 1.857-2.464 4.178-4.536l.43-.5c.643-.785.964-1.643.964-2.535 0-1.18-.358-2.108-1-2.785-.678-.68-1.643-1.001-2.858-1.001-1.536 0-2.642.464-3.357 1.43-.37.5-.621 1.135-.76 1.904a1.999 1.999 0 0 1-1.971 1.63h-.004c-1.277 0-2.257-1.183-1.98-2.43.337-1.518 1.02-2.78 2.073-3.784 1.536-1.5 3.607-2.25 6.25-2.25 2.32 0 4.214.607 5.642 1.894Z'
        clipRule='evenodd'
      />
      <path
        fill='#FFB594'
        fillRule='evenodd'
        d='M51.04 76.131s21.81 5.36 27.307 15.945c5.575 10.74-6.352 9.26-15.73 4.935-10.86-5.008-24.7-11.822-11.577-20.88Z'
        clipRule='evenodd'
      />
      <path
        fill='#FFC6A0'
        fillRule='evenodd'
        d='m89.483 67.504-.449 2.893c-.753.49-4.748-2.663-4.748-2.663l-1.645.748-1.346-5.684s6.815-4.589 8.917-5.018c2.452-.501 9.884.94 10.7 2.278 0 0 1.32.486-2.227.69-3.548.203-5.043.447-6.79 3.132-1.747 2.686-2.412 3.624-2.412 3.624Z'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M127.055 111.367c-2.627-7.724-6.15-13.18-8.917-15.478-3.5-2.906-9.34-2.225-11.366-4.187-1.27-1.231-3.215-1.197-3.215-1.197s-14.98-3.158-16.828-3.479c-2.37-.41-2.124-.714-6.054-1.405-1.57-1.907-2.917-1.122-2.917-1.122l-7.11-1.383c-.853-1.472-2.423-1.023-2.423-1.023l-2.468-.897c-1.645 9.976-7.74 13.796-7.74 13.796 1.795 1.122 15.703 8.3 15.703 8.3l5.107 37.11s-3.321 5.694 1.346 9.109c0 0 19.883-3.743 34.921-.329 0 0 3.047-2.546.972-8.806.523-3.01 1.394-8.263 1.736-11.622.385.772 2.019 1.918 3.14 3.477 0 0 9.407-7.365 11.052-14.012-.832-.723-1.598-1.585-2.267-2.453-.567-.736-.358-2.056-.765-2.717-.669-1.084-1.804-1.378-1.907-1.682Z'
        clipRule='evenodd'
      />
      <path
        fill='#CBD1D1'
        fillRule='evenodd'
        d='M100.09 289.998s4.295 2.041 7.354 1.021c2.821-.94 4.53.668 7.08 1.178 2.55.51 6.874 1.1 11.686-1.26-.103-5.51-6.889-3.98-11.96-6.713-2.563-1.38-3.784-4.722-3.598-8.799h-9.402s-1.392 10.52-1.16 14.573Z'
        clipRule='evenodd'
      />
      <path
        fill='#2B0849'
        fillRule='evenodd'
        d='M100.067 289.826s2.428 1.271 6.759.653c3.058-.437 3.712.481 7.423 1.031 3.712.55 10.724-.069 11.823-.894.413 1.1-.343 2.063-.343 2.063s-1.512.603-4.812.824c-2.03.136-5.8.291-7.607-.503-1.787-1.375-5.247-1.903-5.728-.241-3.918.95-7.355-.286-7.355-.286l-.16-2.647Z'
        clipRule='evenodd'
      />
      <path
        fill='#A4AABA'
        fillRule='evenodd'
        d='M107.341 276.044h3.094s-.103 6.702 4.536 8.558c-4.64.618-8.558-2.303-7.63-8.558Z'
        clipRule='evenodd'
      />
      <path
        fill='#CBD1D1'
        fillRule='evenodd'
        d='M56.542 272.401s-2.107 7.416-4.485 12.306c-1.798 3.695-4.225 7.492 5.465 7.492 6.648 0 8.953-.48 7.423-6.599-1.53-6.12.266-13.199.266-13.199h-8.669Z'
        clipRule='evenodd'
      />
      <path
        fill='#2B0849'
        fillRule='evenodd'
        d='M50.476 289.793s2.097 1.169 6.633 1.169c6.083 0 8.249-1.65 8.249-1.65s.602 1.114-.619 2.165c-.993.855-3.597 1.591-7.39 1.546-4.145-.048-5.832-.566-6.736-1.168-.825-.55-.687-1.58-.137-2.062Z'
        clipRule='evenodd'
      />
      <path
        fill='#A4AABA'
        fillRule='evenodd'
        d='M57.419 274.304s.033 1.519-.314 2.93c-.349 1.42-1.078 3.104-1.13 4.139-.058 1.151 4.537 1.58 5.155.034.62-1.547 1.294-6.427 1.913-7.252.619-.825-4.903-2.119-5.624.15'
        clipRule='evenodd'
      />
      <path
        fill='#0A747E'
        fillRule='evenodd'
        d='m98.66 278.514 13.378.092s1.298-54.52 1.853-64.403c.554-9.882 3.776-43.364 1.002-63.128l-12.547-.644-22.849.78s-.434 3.966-1.195 9.976c-.063.496-.682.843-.749 1.365-.075.585.423 1.354.32 1.966-2.364 14.08-6.377 33.104-8.744 46.677-.116.666-1.234 1.009-1.458 2.691-.04.302.211 1.525.112 1.795-6.873 18.744-10.949 47.842-14.277 61.885l14.607-.014s2.197-8.57 4.03-16.97c2.811-12.886 23.111-85.01 23.111-85.01l3.016-.521 1.043 46.35s-.224 1.234.337 2.02c.56.785-.56 1.123-.392 2.244l.392 1.794s-.449 7.178-.898 11.89c-.448 4.71-.092 39.165-.092 39.165Z'
        clipRule='evenodd'
      />
      <path
        stroke='#272932'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.051'
        d='M75.085 221.626c1.153.094 4.038-2.019 6.955-4.935m23.32 8.451s2.774-1.11 6.103-3.883'
      />
      <path
        stroke='#272932'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M106.275 222.1s2.773-1.11 6.102-3.884'
      />
      <path
        stroke='#272932'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.051'
        d='M73.74 224.767s2.622-.591 6.505-3.365m4.785-69.768c-.27 3.106.3 8.525-4.336 9.123m21.931-10.877s.11 14.012-1.293 15.065c-2.219 1.664-2.99 1.944-2.99 1.944m.448-16.451s.035 12.88-1.196 24.377m-4.921 1.096s7.212-1.664 9.431-1.664M73.31 205.861a211.18 211.18 0 0 1-.979 4.56s-1.458 1.832-1.009 3.776c.449 1.944-.947 2.045-4.985 15.355-1.696 5.59-4.49 18.591-6.348 27.597l-.231 1.12m14.931-60.462a320.528 320.528 0 0 1-.882 4.754m7.784-50.328L80.395 162.7s-1.097.15-.5 2.244c.113 1.346-2.674 15.775-5.18 30.43M55.12 274.418h13.31'
      />
      <path
        fill='#192064'
        fillRule='evenodd'
        d='M115.241 148.22s-17.047-3.104-35.893.2c.158 2.514-.003 4.15-.003 4.15s14.687-2.818 35.67-.312c.252-2.355.226-4.038.226-4.038Z'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='m105.322 151.165.003-4.911a.813.813 0 0 0-.778-.815 110.21 110.21 0 0 0-7.836-.014.816.816 0 0 0-.789.815l-.003 4.906a.814.814 0 0 0 .514.757.8.8 0 0 0 .317.056c2.385-.06 4.973-.064 7.73.017a.81.81 0 0 0 .592-.227.814.814 0 0 0 .25-.583'
        clipRule='evenodd'
      />
      <path
        fill='#192064'
        fillRule='evenodd'
        d='m104.207 150.233.002-3.076a.647.647 0 0 0-.178-.449.646.646 0 0 0-.441-.197 94.816 94.816 0 0 0-5.866-.01.652.652 0 0 0-.63.647v3.072a.635.635 0 0 0 .19.46.648.648 0 0 0 .464.184c1.931-.043 3.863-.039 5.794.011.362.01.665-.28.665-.642Z'
        clipRule='evenodd'
      />
      <path
        stroke='#272932'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.051'
        d='M99.263 275.415h12.338m-11.165-4.885c.006 3.387.042 5.79.111 6.506m-.096-12.488c-.008 1.446-.013 2.891-.015 4.337m-.45-93.92.898 44.642s.673 1.57-.225 2.692c-.897 1.122 2.468.673.898 2.243-1.57 1.57.897 1.122 0 3.365-.596 1.489-.994 21.1-1.096 35.146'
      />
      <path
        fill='#FFC6A0'
        fillRule='evenodd'
        d='M45.876 83.427s-.516 6.045 7.223 5.552c11.2-.712 9.218-9.345 31.54-21.655-.786-2.708-2.447-4.744-2.447-4.744s-11.068 3.11-22.584 8.046c-6.766 2.9-13.395 6.352-13.732 12.801Zm57.584 7.63.941-5.372-8.884-11.43-5.037 5.372-1.74 7.834a.32.32 0 0 0 .108.32c.965.8 6.5 5.013 14.347 3.544a.336.336 0 0 0 .265-.268Z'
        clipRule='evenodd'
      />
      <path
        fill='#FFC6A0'
        fillRule='evenodd'
        d='M92.942 79.387s-4.533-2.853-2.432-6.855c1.623-3.09 4.513 1.133 4.513 1.133s.52-3.642 3.121-3.642c.52-1.04 1.561-4.162 1.561-4.162s11.445 2.601 13.526 3.121c0 5.203-2.304 19.424-7.84 19.861-8.892.703-12.449-9.456-12.449-9.456Z'
        clipRule='evenodd'
      />
      <path
        fill='#845207'
        fillRule='evenodd'
        d='M112.874 73.446c2.601-2.081 3.47-9.722 3.47-9.722s-2.479-.49-6.64-2.05c-4.683-2.081-12.798-4.747-17.48.976-9.668 3.223-2.05 19.823-2.05 19.823l2.713-3.021s-3.935-3.287-2.08-6.243c2.17-3.462 3.92 1.073 3.92 1.073s.637-2.387 3.581-3.342c.355-.71 1.036-2.674 1.432-3.85a1.071 1.071 0 0 1 1.263-.704c2.4.558 8.677 2.019 11.356 2.662.522.125.871.615.82 1.15l-.305 3.248Z'
        clipRule='evenodd'
      />
      <path
        fill='#552950'
        fillRule='evenodd'
        d='M103.977 76.064c-.103.61-.582 1.038-1.07.956-.489-.083-.801-.644-.698-1.254.103-.61.582-1.038 1.07-.956.488.082.8.644.698 1.254Zm7.155 1.63c-.103.61-.582 1.038-1.07.956-.488-.083-.8-.644-.698-1.254.103-.61.582-1.038 1.07-.956.488.082.8.643.698 1.254Z'
        clipRule='evenodd'
      />
      <path
        stroke='#DB836E'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.118'
        d='m109.13 74.84-.896 1.61-.298 4.357h-2.228'
      />
      <path
        stroke='#5C2552'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.118'
        d='M109.846 74.481s1.79-.716 2.506.537'
      />
      <path
        stroke='#DB836E'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.118'
        d='M91.386 74.282s.477-1.114 1.113-.716c.637.398 1.274 1.433.558 1.99-.717.556.159 1.67.159 1.67'
      />
      <path
        stroke='#5C2552'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.118'
        d='M102.287 72.93s1.83 1.113 4.137.954'
      />
      <path
        stroke='#DB836E'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.118'
        d='M102.685 81.762s2.227 1.193 4.376 1.193m-3.421 1.353s.954.398 1.511.318m-11.458-3.421s2.308 7.4 10.424 7.639'
      />
      <path
        stroke='#E4EBF7'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.101'
        d='M80.45 89.384s.45 5.647-4.935 12.787M68 82.654s-.726 9.282-8.204 14.206'
      />
      <path
        stroke='#E4EBF7'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.051'
        d='M128.405 122.865s-5.272 7.403-9.422 10.768'
      />
      <path
        stroke='#E4EBF7'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.101'
        d='M118.306 107.329s.452 4.366-2.127 32.062'
      />
      <path
        fill='#F2D7AD'
        fillRule='evenodd'
        d='M149.028 151.232H99.191a1.008 1.008 0 0 1-1.01-1.01v-31.688c0-.557.452-1.01 1.01-1.01h49.837c.558 0 1.01.453 1.01 1.01v31.688a1.006 1.006 0 0 1-1.01 1.01Z'
        clipRule='evenodd'
      />
      <path
        fill='#F4D19D'
        fillRule='evenodd'
        d='M149.29 151.232h-19.863v-33.707h20.784v32.786a.924.924 0 0 1-.269.651.924.924 0 0 1-.651.269'
        clipRule='evenodd'
      />
      <path
        fill='#F2D7AD'
        fillRule='evenodd'
        d='M122.554 127.896H91.917a.522.522 0 0 1-.518-.552.52.52 0 0 1 .093-.264l6.38-9.113c.193-.277.51-.442.85-.442h31.092l-7.26 10.371Z'
        clipRule='evenodd'
      />
      <path
        fill='#CC9B6E'
        fillRule='evenodd'
        d='M122.689 128.447H98.25v-.519h24.169l7.183-10.26.424.298-7.337 10.481Z'
        clipRule='evenodd'
      />
      <path
        fill='#F4D19D'
        fillRule='evenodd'
        d='M157.298 127.896h-18.669a2.076 2.076 0 0 1-1.659-.83l-7.156-9.541h19.965c.49 0 .95.23 1.244.622l6.69 8.92a.511.511 0 0 1 .101.265.513.513 0 0 1-.516.564Z'
        clipRule='evenodd'
      />
      <path
        fill='#CC9B6E'
        fillRule='evenodd'
        d='M156.847 128.479h-19.384l-7.857-10.475.415-.31 7.7 10.266h19.126v.519Zm-27.293 22.206-.032-8.177.519-.002.032 8.177-.519.002Z'
        clipRule='evenodd'
      />
      <path
        fill='#CC9B6E'
        fillRule='evenodd'
        d='m129.511 139.783-.08-21.414.519-.002.08 21.414-.519.002Zm-18.635 1.149-.498-.143 1.479-5.167.498.143-1.479 5.167Zm-3.439.128-2.679-2.935 2.665-3.434.41.318-2.397 3.089 2.384 2.612-.383.35Zm8.17 0-.383-.35 2.383-2.612-2.397-3.089.41-.318 2.665 3.434-2.678 2.935Z'
        clipRule='evenodd'
      />
      <path
        fill='#FFC6A0'
        fillRule='evenodd'
        d='m153.316 131.892-3.114-1.96.038 3.514-1.043.092c-1.682.115-3.634.23-4.789.23-1.902 0-2.693 2.258 2.23 2.648l-2.645-.596s-2.168 1.317.504 2.3c0 0-1.58 1.217.561 2.58-.584 3.504 5.247 4.058 7.122 3.59 1.876-.47 4.233-2.359 4.487-5.16.28-3.085-.89-5.432-3.35-7.238'
        clipRule='evenodd'
      />
      <path
        stroke='#DB836E'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.051'
        d='M152.686 133.577s-6.522.47-8.36.372c-1.836-.098-1.904 2.19 2.359 2.264 3.739.15 5.451-.044 5.451-.044'
      />
      <path
        stroke='#DB836E'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.051'
        d='M144.16 135.877c-1.85 1.346.561 2.355.561 2.355s3.478.898 6.73.617'
      />
      <path
        stroke='#DB836E'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.051'
        d='M150.89 141.71s-6.28.111-6.73-2.132c-.223-1.346.45-1.402.45-1.402m.504 2.692s-1.103 3.16 5.44 3.533m-.352-14.469v3.477M51.838 89.286c3.533-.337 8.423-1.248 13.582-7.754'
      />
      <path
        fill='#78B154'
        fillRule='evenodd'
        d='M167.567 248.318a6.644 6.644 0 0 1-6.647-6.647v-66.466a6.651 6.651 0 0 1 4.103-6.141 6.651 6.651 0 0 1 7.244 1.441 6.65 6.65 0 0 1 1.947 4.7v66.466a6.644 6.644 0 0 1-6.647 6.647Z'
        clipRule='evenodd'
      />
      <path
        fill='#548733'
        fillRule='evenodd'
        d='M175.543 247.653a6.646 6.646 0 0 1-6.646-6.647v-33.232a6.647 6.647 0 1 1 13.293 0v33.232a6.644 6.644 0 0 1-6.647 6.647Z'
        clipRule='evenodd'
      />
      <path
        fill='#0A747E'
        fillRule='evenodd'
        d='M185.443 293.613H157.92a3.191 3.191 0 0 1-2.254-.933 3.191 3.191 0 0 1-.933-2.254v-46.134c0-.845.336-1.656.933-2.254a3.191 3.191 0 0 1 2.254-.933h27.524c.845 0 1.656.336 2.254.933.597.598.933 1.409.933 2.254v46.134c0 .845-.336 1.656-.933 2.254a3.191 3.191 0 0 1-2.254.933'
        clipRule='evenodd'
      />
      <path
        stroke='#E4EBF7'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.101'
        d='M87.979 89.48s7.776 5.384 16.6 2.842'
      />
    </g>
    <defs>
      <clipPath id='clip0_1805_118'>
        <path fill='#fff' d='M0 0h252v294H0z' />
      </clipPath>
    </defs>
  </svg>
);

export default NotFoundIllustration;
