import useConnectIntl from 'i18n/useConnectIntl.ts';
import { TicketListItemFragment } from 'generated/types.tsx';

type TicketTitleProps = Pick<TicketListItemFragment, 'title' | 'type'>;

function useTicketFormatTools() {
  const intl = useConnectIntl();

  const formatTicketTitleWithFallback = (ticket: TicketTitleProps | undefined) => {
    if (!ticket) {
      return undefined;
    }

    if (ticket.title) {
      return ticket.title;
    }

    if (ticket.type.nameKeyI18n) {
      return intl.formatMsg({
        id: ticket.type.nameKeyI18n
      });
    }

    return undefined;
  };

  return {
    formatTicketTitleWithFallback
  };
}

export default useTicketFormatTools;
