import React, { useEffect } from 'react';
import { defineMessages } from 'react-intl';
import Counter from 'components/lib/Counter/Counter.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';

const messages = defineMessages({
  no_tickets: {
    id: 'ticket_count.no_tickets',
    defaultMessage: '{count} tickets 👌'
  },
  one_ticket: {
    id: 'ticket_count.one_ticket',
    defaultMessage: '{count} ticket'
  },
  many_tickets: {
    id: 'ticket_count.many_tickets',
    defaultMessage: '{count} tickets'
  }
});

const TicketCount: React.FC<{ totalCount?: number }> = (props) => {
  const intl = useConnectIntl();
  const { totalCount } = props;
  const [from, setFrom] = React.useState(0);
  const [to, setTo] = React.useState<number | undefined>(undefined);

  useEffect(() => {
    if (totalCount !== undefined) {
      if (to !== undefined) {
        setFrom(to);
      }
      setTo(totalCount);
    }
  }, [to, totalCount]);

  if (to === undefined) return null; // still loading

  return (
    <>
      {to === 0 && (
        <div>
          {intl.formatMsg(messages.no_tickets, {
            count: <Counter from={from} to={to} />
          })}
        </div>
      )}
      {to === 1 && (
        <div>
          {intl.formatMsg(messages.one_ticket, {
            count: <Counter from={from} to={to} />
          })}
        </div>
      )}
      {to > 1 && (
        <div>
          {intl.formatMsg(messages.many_tickets, {
            count: <Counter from={from} to={to} />
          })}
        </div>
      )}
    </>
  );
};

export default TicketCount;
