import React, { CSSProperties, useCallback } from 'react';
import { Space } from 'antd';
import { getTicketTypeIcon } from 'components/ticket/TicketType/getTicketTypeIcon.tsx';
import Tag from 'components/lib/Tag/Tag.tsx';
import { TicketTypeFullFragment } from 'generated/types.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { useScreenInfo } from 'layouts/responsive/useScreenInfo.ts';

interface Props {
  onClick?: (ticketTypeId: number) => void;
  ticketType: Pick<TicketTypeFullFragment, 'nameKeyI18n' | 'symbol' | 'ticketTypeId'>;
  style?: CSSProperties;
}
const TicketTypeTag: React.FC<Props> = ({ onClick, style, ticketType }) => {
  const intl = useConnectIntl();
  const { screenMap: breakpoint } = useScreenInfo();
  const handleClick = useCallback(() => {
    onClick?.(ticketType.ticketTypeId);
  }, [onClick, ticketType.ticketTypeId]);

  return (
    <Tag style={{ cursor: onClick ? 'pointer' : 'unset', ...style }} onClick={handleClick}>
      <Space>
        {getTicketTypeIcon(ticketType)}
        {breakpoint.lg && (
          <div>
            {intl.formatMsg({
              id: ticketType.nameKeyI18n
            })}
          </div>
        )}
      </Space>
    </Tag>
  );
};

export default TicketTypeTag;
