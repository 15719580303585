import React from 'react';
import { SelectProps } from 'antd/es/select';
import Select, { Option } from 'components/lib/Select/Select';
import { Space } from 'antd';
import { getTicketTypeIcon } from 'components/ticket/TicketType/getTicketTypeIcon';
import { MachineType, TicketTypeFullFragment, useGetTicketTypesQuery } from 'generated/types';
import useConnectIntl from 'i18n/useConnectIntl.ts';

function TypedTicketTypeSelect<T extends number>({ children, ...props }: SelectProps<T>) {
  return <Select<T> {...props}>{children}</Select>;
}

type OtherSelectProps = Omit<SelectProps<number>, 'options'>;

interface Props {
  machineType?: MachineType;
}

const TicketTypeSelect: React.FC<Props & OtherSelectProps> = ({
  children,
  machineType,
  ...rest
}) => {
  const intl = useConnectIntl();

  const { data } = useGetTicketTypesQuery({
    variables: {
      machineType: machineType
    },
    notifyOnNetworkStatusChange: true
  });
  const getTicketTypeName = (ticketType: Pick<TicketTypeFullFragment, 'nameKeyI18n'>) => {
    return intl.formatMsg({
      id: ticketType.nameKeyI18n
    });
  };

  return (
    <TypedTicketTypeSelect
      style={{ width: '100%' }}
      placeholder={intl.formatMessage({
        id: 'ticket_type_select.placeholder',
        defaultMessage: 'Ticket type'
      })}
      popupMatchSelectWidth={false}
      {...rest}
    >
      {data?.allTicketTypes.map((c) => {
        return (
          <Option value={c.ticketTypeId} key={c.ticketTypeId} label={getTicketTypeName(c)}>
            <Space>
              <div>{getTicketTypeIcon(c)}</div>
              <div>{getTicketTypeName(c)}</div>
            </Space>
          </Option>
        );
      })}
    </TypedTicketTypeSelect>
  );
};

export default TicketTypeSelect;
