import React from 'react';
import Card from 'components/lib/Card/Card.tsx';
import { gql } from '@apollo/client/core';
import { useGetMeAndAllQuery } from 'generated/types.tsx';
import PageContentRow from 'components/lib/page/rows/PageContentRow.tsx';
import Page from 'components/lib/page/Page/Page.tsx';

gql`
  query GetMeAndAll {
    me {
      id
      name
      title
      emailAddress
      active
      eulaAccepted
      retailer {
        id
        retailerId
        name
      }
      permissions {
        canCreateRetailer
        canRedeployParameters
        canViewTicketOrderLines
        translationTool
        tabs {
          operational {
            users
            tickets
            integrations
            events
            properties
          }
        }
      }
      acl {
        role
        resourceType
        resourceId
        allow
      }
      connectAppSettings {
        appearance {
          theme
        }
        filters {
          showActiveMachines
          showInactiveRetailers
        }
      }
    }
  }
`;

const MePage: React.FC = () => {
  const { data } = useGetMeAndAllQuery();
  return (
    <Page
      title={{
        pageTitle: 'Me'
      }}
    >
      <PageContentRow style={{ paddingTop: 16, paddingBottom: 16 }}>
        <Card title={'Me'}>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </Card>
      </PageContentRow>
    </Page>
  );
};

export default MePage;
