import React from 'react';
import { parseJSON } from 'date-fns';
import { Tooltip } from 'antd';
import useDateFormatTools from 'i18n/useDateFormatTools';
interface Props {
  // Date as raw string
  dateRaw: string;
  hideTooltip?: boolean;
}
const RelativeTime: React.FC<Props> = (props) => {
  const { dateRaw, hideTooltip } = props;
  const { formatDistanceToNow, formatDate } = useDateFormatTools();
  const date = parseJSON(dateRaw);
  const content = formatDistanceToNow(date, {
    addSuffix: true,
    includeSeconds: true
  });

  return hideTooltip ? (
    <>{content}</>
  ) : (
    <Tooltip title={formatDate(date, { representation: 'complete' })}>{content}</Tooltip>
  );
};

export default RelativeTime;
