import React from 'react';
import styled from 'styled-components';
import { FullLanguageFragment } from 'generated/types.tsx';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';

const Container = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  grid-gap: 8px;
`;

const Box = styled.div`
  border-radius: 4px;
  background-color: ${(props) => props.theme.ant.colorInfoBg};
  color: ${(props) => props.theme.ant.colorTextLightSolid};
  padding: 0 6px;
  font-size: 12px;
  min-width: 24px;
  text-align: center;
`;

interface Props {
  language: Pick<FullLanguageFragment, 'code' | 'nameEnglish' | 'nameNative'>;
  showTooltip?: boolean;
}

const Language: React.FC<Props> = ({ language, showTooltip }) => {
  const intl = useIntl();
  const content = (
    <Container>
      <div>
        <Box>{language.code}</Box>
      </div>
      <div>{language.nameNative}</div>
    </Container>
  );
  return showTooltip ? (
    <Tooltip
      title={intl.formatMessage({
        id: language.nameEnglish,
        defaultMessage: language.nameEnglish
      })}
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
};

export default Language;
