import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import {
  ExtensionPriority,
  IdentifierSchemaAttributes,
  InvalidContentHandler,
  isDocNodeEmpty,
  RemirrorEventListener
} from 'remirror';

import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  CodeBlockExtension,
  EmojiExtension,
  GapCursorExtension,
  HardBreakExtension,
  ImageExtension,
  ItalicExtension,
  LinkExtension,
  MentionAtomExtension,
  OrderedListExtension,
  PlaceholderExtension,
  ShortcutsExtension,
  StrikeExtension,
  TaskListExtension,
  TrailingNodeExtension,
  UnderlineExtension
} from 'remirror/extensions';
import data from 'svgmoji/emoji.json';
import {
  EditorComponent,
  EmojiPopupComponent,
  Remirror,
  ThemeProvider,
  useRemirror
} from '@remirror/react';
import { AllStyledComponent } from '@remirror/styles/styled-components';
import { TopToolbar } from 'components/lib/ConnectEditor/TopToolbar';
import { useEditorTheme } from 'themes/useEditorTheme';
import styled from 'styled-components';
import AutoSave, { OnAutoSaveCallback } from 'components/lib/ConnectEditor/AutoSave';
import {
  VendanorEditorDef,
  VendanorUsedExtensions
} from 'components/lib/ConnectEditor/vendanorEditorDef';
import RawDefaultValueLoader from 'components/lib/ConnectEditor/RawDefaultValueLoader';
import { Typography, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { UploadRequestOption } from 'rc-upload/es/interface';
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import { VnFile } from 'components/lib/upload/VnFile';
import UploadList from 'components/lib/upload/UploadList/UploadList';

const extraAttributes: IdentifierSchemaAttributes[] = [
  {
    identifiers: ['mention', 'emoji'],
    attributes: { role: { default: 'presentation' } }
  },
  { identifiers: ['mention'], attributes: { href: { default: null } } }
];

export interface Props {
  /***
   * If false Editor will render a read only version that cannot be edited
   */
  editable: boolean;

  /***
   * Text to render as a placeholder when the document is empty
   */
  placeholder?: string;

  /***
   * This can be raw HTMl, a plain string, or RemirrorJSON.
   * Format will be detected automatically
   */
  defaultValueRaw?: string;

  /***
   * Nest your own Remirror components here to customize the editor
   */
  children?: React.ReactNode;

  /***
   * Will trigger when document is auto saving. Will contain json useful for storing
   * @param document
   */
  onAutoSave?: OnAutoSaveCallback;

  /***
   * isEmpty is True if the current document is empty.
   * Will only trigger if status changes
   * @param isEmpty
   */
  onIsEmptyChange?: (isEmpty: boolean) => void;

  enableUpload?: boolean;
  fileList?: Array<UploadFile<VnFile>>;
  onUploadFile?: (options: UploadRequestOption, signal?: AbortSignal) => void;

  onUploadStateChange?: (info: UploadChangeParam) => void;
  onCancelUpload?: (file: UploadFile<VnFile>) => void;

  onRemoveFile?: (file: UploadFile) => void;
}

const EditorBorderContainer = styled.div<{
  $editable: boolean;
  $enableUpload: boolean;
}>`
  border: ${(props) => (props.$editable ? '1px solid ' + props.theme.ant.colorBorder : 'none')};
  border-radius: ${(props) => props.theme.ant.borderRadius}px;
  margin-top: ${(props) => (props.$editable ? '8px' : 0)};
  transition: all 0.3s;

  &:hover {
    border-color: ${(props) => props.theme.ant.colorPrimary};
  }
`;

const UploadDragger = styled(Upload.Dragger)`
  &&& {
    border: none;
    padding: 0;

    .ant-upload {
      border-right: none;
      border-bottom: none;
      border-left: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-color: ${(props) => props.theme.ant.colorBorder};
      padding: 4px 0;
    }
  }
`;

// #id-1-1
const Container = styled.div<{ $readonly: boolean }>`
  //background-color: palegreen;

  &&& {
    .remirror-editor-wrapper {
      padding-top: ${(props) =>
        props.$readonly ? 0 : '12px'}; // Removed padding on top when editable=false

      padding-bottom: ${(props) => (props.$readonly ? 0 : '12px')};

      .remirror-is-empty {
        height: 0; // dont take any height when empty?
      }

      img {
        max-width: 100%;
        min-width: 16px;
        object-fit: contain;
      }

      ul {
        padding-inline-start: 24px;
      }

      ol {
        padding-inline-start: 24px;
      }

      .remirror-list-item-marker-container {
        display: inline-flex;
        height: 100%;
        justify-content: flex-end;
        align-items: center;
      }

      .remirror-editor {
        // In addition to setting theme variables, we can override styles here
        padding: ${(props) => (props.$readonly ? 0 : '4px 11px')};
        border: 0; // moved outside
        transition: all 0.3s;
        box-shadow: none;
        min-height: ${(props) => (props.$readonly ? 'unset' : '120px')};

        overflow: auto; // <== perhaps fix scrollbar issue on windows?
      }
      .remirror-is-empty {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
      }
      .ProseMirror:hover {
        border-color: unset;
      }
      .ProseMirror:focus {
        border-color: unset;
        box-shadow: none;
      }
    }
    .remirror-floating-popover {
      z-index: 1; // Fixes emoji popup z-index
    }
  }
`;

/***
 * Editor
 * This is our main WYSIWYG editor component, based on reMirror.
 */
const ConnectEditor = forwardRef<VendanorEditorDef | undefined, Props>((props, ref) => {
  const {
    placeholder,
    defaultValueRaw,
    children,
    editable,
    onAutoSave,
    onIsEmptyChange,
    onUploadFile,
    onUploadStateChange,
    onRemoveFile,
    onCancelUpload,
    enableUpload,
    fileList
  } = props;

  const intl = useIntl();
  const extensions = useCallback(
    () => [
      // new UploadButtonExtension(),
      new LinkExtension({
        autoLink: true
      }),
      new GapCursorExtension(),
      new HardBreakExtension(),
      // new HorizontalRuleExtension(), // 3 --- => insert HR
      new ImageExtension({
        enableResizing: false,
        priority: ExtensionPriority.High
      }),
      new ItalicExtension(),
      new StrikeExtension(),
      new UnderlineExtension(),
      new BlockquoteExtension(),
      // new CodeExtension(),
      new BoldExtension({}),
      new CodeBlockExtension({}), /// markdown style code! (or codemirror?)
      // new DropCursorExtension(), // render cursor when dropping files, or moving image around
      // new HeadingExtension(), // no headings needed yet...
      // new SearchExtension(), // search for text, not needed yet?
      new TrailingNodeExtension({}), // need this for hard break extension?
      // new IframeExtension(), // no need for embedded YouTube etc. yet
      new BulletListExtension({}),
      new OrderedListExtension(),
      new TaskListExtension(),
      new ShortcutsExtension(), // replace (c) with copyright symbol..
      new PlaceholderExtension({ placeholder }),
      // new TableExtension(),
      new MentionAtomExtension({
        matchers: [
          { name: 'at', char: '@' },
          { name: 'tag', char: '#' }
        ]
      }),
      new EmojiExtension({ plainText: false, moji: 'openmoji', data })
    ],
    [placeholder]
  );

  const handleAutoSave = useCallback<OnAutoSaveCallback>(
    (data) => {
      onAutoSave?.(data);
    },
    [onAutoSave]
  );

  const handleError: InvalidContentHandler = useCallback(
    ({ json, invalidContent, transformers }) => {
      // Automatically remove all invalid nodes and marks.
      return transformers.remove(json, invalidContent);
    },
    []
  );

  const { manager, getContext, state, setState } = useRemirror({
    extensions,
    extraAttributes,
    defaultSelection: 'end',
    stringHandler: 'html', // <== if STRING is passed to initialContent below, it will be parsed as HTML
    onError: handleError
  });

  // const [isFocused] = useEditorFocus(); // NOTE: there is also a setFocus here if needed

  // Expose content handling to outside via ref:
  useImperativeHandle(ref, () => getContext(), [getContext]);
  const { theme } = useEditorTheme();

  const handleChange = useCallback<RemirrorEventListener<VendanorUsedExtensions>>(
    (params) => {
      const nextState = params.state;

      // custom controlled logic here...
      if (params.tr) {
        if (params.tr.docChanged) {
          // document HAS changed in this transaction. Let's see if document is empty:
          // NOTE: This might be heavy, optimize later?
          const isNextEmpty = isDocNodeEmpty(params.tr.doc);
          onIsEmptyChange?.(isNextEmpty);
        }
      }

      setState(nextState);
    },
    [onIsEmptyChange, setState]
  );

  const hasAttachments = fileList && fileList.length > 0;

  return (
    <Container $readonly={!editable}>
      <AllStyledComponent>
        <ThemeProvider theme={theme}>
          <Remirror
            manager={manager}
            label={'Text editor'}
            editable={editable}
            // initialContent={initialContent} // <== NOTE: doesn't work with controlled mode, I guess we have to load initial data ourselves?
            onChange={handleChange}
            state={state}
          >
            <RawDefaultValueLoader defaultValueRaw={defaultValueRaw} />
            {onAutoSave !== undefined && (
              <AutoSave
                saveOnBlur={true}
                saveOnShortcut={true}
                saveOnInactivity={5000}
                onSave={handleAutoSave}
              />
            )}
            {editable && <TopToolbar />}
            <EmojiPopupComponent />
            {/*<MentionComponent users={users} tags={tags} />*/}
            {/*{editable && <BubbleMenu />}*/}
            {children}

            <EditorBorderContainer $editable={editable} $enableUpload={enableUpload || false}>
              <EditorComponent />
              {editable && enableUpload && (
                <UploadDragger
                  multiple={true}
                  openFileDialogOnClick={true}
                  onRemove={onRemoveFile}
                  customRequest={onUploadFile}
                  onChange={onUploadStateChange}
                  fileList={fileList}
                  showUploadList={false} // hide default inline file list
                >
                  <Typography.Text type={'secondary'}>
                    {intl.formatMessage(
                      {
                        id: 'connect_editor.upload_hint',
                        defaultMessage:
                          '{icon} Attach files by dragging & dropping or selecting them.'
                      },
                      {
                        icon: <UploadOutlined />
                      }
                    )}
                  </Typography.Text>
                </UploadDragger>
              )}
            </EditorBorderContainer>
            {enableUpload && hasAttachments && (
              <UploadList
                fileList={fileList}
                style={{ marginTop: '12px' }}
                editable={editable}
                onRemove={onRemoveFile}
                onCancelUpload={onCancelUpload}
              />
            )}
          </Remirror>
        </ThemeProvider>
      </AllStyledComponent>
    </Container>
  );
});

ConnectEditor.displayName = 'Editor';

export default ConnectEditor;
