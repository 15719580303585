import React from 'react';
import { Result } from 'antd';
import { defineMessages } from 'react-intl';
import Link from 'components/lib/Link/Link';
import styled from 'styled-components';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import NotAuthorizedIllustration from 'components/illustrations/NotAuthorizedIllustration.tsx';
import Page from 'components/lib/page/Page/Page.tsx';

const messages = defineMessages({
  title: {
    id: 'not_authorized_page.title',
    defaultMessage: 'Unauthorized'
  },
  subtitle: {
    id: 'not_authorized_page.subtitle',
    defaultMessage:
      'You dont have access to this page or resource.{br} Please contact your administrator. 👮'
  },
  mainPageLink: {
    id: 'not_authorized_page.main_page_link',
    defaultMessage: 'Go to main page'
  }
});

const ResultContainer = styled.div`
  flex: 1 1 auto;
  padding-top: 128px;
`;

interface Props {
  title?: string;
  subtitle?: React.ReactNode;
  extra?: React.ReactNode;
}

const NotAuthorizedPage: React.FC<Props> = ({ title, extra, subtitle }) => {
  const intl = useConnectIntl();
  const defaultExtra = <Link to={'/'}>{intl.formatMsg(messages.mainPageLink)}</Link>;

  return (
    <Page
      title={{
        pageTitle: title || intl.formatMsg(messages.title)
      }}
    >
      <ResultContainer>
        <Result
          icon={<NotAuthorizedIllustration width={300} />}
          extra={extra || defaultExtra}
          subTitle={subtitle || intl.formatMsg(messages.subtitle, { br: <br /> })}
          title={title || intl.formatMsg(messages.title)}
        />
      </ResultContainer>
    </Page>
  );
};

export default NotAuthorizedPage;
