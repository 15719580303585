import { defineMessages } from 'react-intl';

const loginMessages = defineMessages({
  emailMissing: {
    id: 'login.email_missing',
    defaultMessage: 'Please input your email address'
  },
  loginFailed: {
    id: 'login.login_failed',
    defaultMessage: 'Login failed'
  },
  login401WrongPassword: {
    id: 'login.wrong_password',
    defaultMessage:
      'Wrong password. If you cannot remember your password, try the reset password function.'
  },
  login429TooManyAttempts: {
    id: 'login.too_many_attempts',
    defaultMessage:
      'Too many failed attempts. You are locked out. Come back later or contact support.'
  },
  login423AccountLocked: {
    id: 'login.account_locked',
    defaultMessage: 'Your account is locked. Come back later or contact support.'
  },
  login403Unauthorized: {
    id: 'login.unauthorized',
    defaultMessage: 'Unauthorized'
  },
  login500InternalServerError: {
    id: 'login.internal_server_error',
    defaultMessage: 'An unknown error occurred on the server. Please try again later.'
  },
  login412MissingAudience: {
    id: 'login.missing_audience',
    defaultMessage:
      'You are not allowed to log in to this application yet (missing audience). {br}Please contact support for assistance ☎️'
  },
  emailValidationError: {
    id: 'login.email_validation_error',
    defaultMessage: 'Please input a valid email address'
  },
  emailPlaceholder: {
    id: 'login.email_placeholder',
    defaultMessage: 'Email'
  },
  passwordMissing: {
    id: 'login.password_missing',
    defaultMessage: 'Please input your password'
  },
  passwordPlaceholder: {
    id: 'login.password_placeholder',
    defaultMessage: 'Password'
  },
  rememberMe: {
    id: 'login.remember_me',
    defaultMessage: 'Remember me'
  },
  loggedInRedirecting: {
    id: 'login.logged_in_redirecting',
    defaultMessage: 'Logged in, redirecting...'
  },
  login: {
    id: 'login.login',
    defaultMessage: 'Login'
  },
  loginPageTitle: {
    id: 'login.page_title',
    defaultMessage: 'Vendanor Connect'
  },
  resetPassword: {
    id: 'login.reset_password',
    defaultMessage: 'Reset password'
  },
  backToLogin: {
    id: 'login.back_to_login',
    defaultMessage: 'Back to login'
  },
  alreadyAuthenticated: {
    id: 'login.already_authenticated',
    defaultMessage: 'You are already authenticated'
  },
  resetPasswordDescription: {
    id: 'login.reset_password_description',
    defaultMessage: 'Enter your email address and we will send you a link to reset your password.'
  },
  loginFetchSaltError: {
    id: 'login.fetch_salt_error',
    defaultMessage: 'Error fetching salt. '
  },
  loginFetchSaltNetworkError: {
    id: 'login.fetch_salt_network_error',
    defaultMessage: 'Could not connect to server. Please check your internet connection.'
  }
});

export default loginMessages;
