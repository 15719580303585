import { UploadRequestOption } from 'rc-upload/es/interface';
import { FullAssetUploadTokenFragment } from 'generated/types';
import axios, { AxiosProgressEvent } from 'axios';
import { VnFile } from 'components/lib/upload/VnFile';
import useMessageApi from 'components/global/useMessageApi';

/***
 * Helper to reduce boilerplate when uploading assets
 * You still need to provide functions for getting a signed upload token and confirming the upload
 */
function useAssetUploadHandler() {
  const message = useMessageApi();
  return async (input: {
    options: UploadRequestOption;
    getUploadAccessToken: (file: File) => Promise<FullAssetUploadTokenFragment | undefined | null>;
    confirmUpload: (assetId: string) => Promise<string | undefined | null>;
    showProgress?: boolean;
    signal?: AbortSignal;
  }) => {
    console.log('📂 uploading file', input.options.file);
    const { options, getUploadAccessToken, confirmUpload, showProgress } = input;
    const { file, onError, onProgress, onSuccess } = options;
    const reportError = (message: string) => {
      onError?.({
        message,
        name: message
      });
    };

    try {
      if (typeof file === 'string') {
        reportError('Invalid file, string not supported');
        return;
      }
      if (!(file instanceof File)) {
        reportError('Invalid file, not a File');
        return;
      }

      if (showProgress) {
        message.loading({
          content: `Uploading ${file.name}...`,
          key: 'upload_' + file.name
        });
      }

      // 1. request signed upload url and asset id
      const uploadAccessToken = await getUploadAccessToken(file);
      if (!uploadAccessToken) {
        reportError('Failed to get upload access token');
        return;
      }

      const { assetId, signedUploadUrl } = uploadAccessToken;

      if (!signedUploadUrl) {
        reportError('Missing signed upload url');
        return;
      }

      // 2. upload file to signed url
      await axios.put(signedUploadUrl, file, {
        headers: {
          'Content-Type': file.type,
          'x-ms-date': new Date().toUTCString(),
          'x-ms-blob-type': 'BlockBlob'
        },
        signal: input.signal,
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          onProgress?.({
            ...progressEvent,
            percent: progressEvent.total ? (progressEvent.loaded / progressEvent.total) * 100.0 : 0
          });
        }
      });

      const assetUrl = await confirmUpload(assetId);

      if (!assetUrl) {
        reportError('Missing asset url');
        return;
      }

      const vnFile: VnFile = {
        assetId,
        url: assetUrl
      };

      console.log('📂 upload successful');
      if (showProgress) {
        message.success({
          content: `Uploaded ${file.name}`,
          key: 'upload_' + file.name
        });
      }
      onSuccess?.(vnFile);
    } catch (err) {
      console.log('📂 upload failed', err);
      if (showProgress && file instanceof File) {
        message.error({
          content: 'Error uploading file',
          key: 'upload_' + file.name
        });
      }

      if (err instanceof Error) {
        reportError(err.message);
      } else {
        reportError('unknown upload error');
      }
    }
  };
}

export default useAssetUploadHandler;
