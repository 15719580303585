import React from 'react';
import { Tooltip, Typography } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { differenceInCalendarDays } from 'date-fns';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { defineMessages } from 'react-intl';
import useDateFormatTools from 'i18n/useDateFormatTools.ts';

const Container = styled.div<{ $isOverdue: boolean; $isSoonOverdue: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  gap: 4px;

  color: ${(props) =>
    props.$isOverdue
      ? props.theme.ant.colorError
      : props.$isSoonOverdue
        ? props.theme.ant.colorWarning
        : 'inherit'};

  && {
    .ant-typography {
      color: ${(props) =>
        props.$isOverdue
          ? props.theme.ant.colorError
          : props.$isSoonOverdue
            ? props.theme.ant.colorWarning
            : 'inherit'};
    }
  }
`;
interface Props {
  date?: Date;
  showNoDueDateText?: boolean;
}

const messages = defineMessages({
  overdue: {
    id: 'ticket_due_date_status_text.overdue',
    defaultMessage: '{days} days overdue'
  },
  no_due_date_set: {
    id: 'ticket_due_date_status_text.no_due_date_set',
    defaultMessage: 'No due date set'
  },
  due_in: {
    id: 'ticket_due_date_status_text.due_in',
    defaultMessage: 'Due in {days} days'
  },
  due_today: {
    id: 'ticket_due_date_status_text.due_today',
    defaultMessage: 'Due today'
  }
});
const TicketDueDateStatusText: React.FC<Props> = ({ date, showNoDueDateText }) => {
  const intl = useConnectIntl();

  const { formatDate } = useDateFormatTools();
  if (!date) {
    if (showNoDueDateText) {
      return (
        <Container $isOverdue={false} $isSoonOverdue={false}>
          <ClockCircleOutlined />
          <Typography.Text type={'secondary'} ellipsis={true}>
            {intl.formatMsg(messages.no_due_date_set)}
          </Typography.Text>
        </Container>
      );
    }
    return null;
  }

  const today = new Date();
  const diff = differenceInCalendarDays(date, today);
  const isOverdue = diff < 0;
  const isSoonOverdue = diff < 3;

  let label = '';
  if (diff === 0) {
    label = intl.formatMsg(messages.due_today);
  } else if (diff < 0) {
    label = intl.formatMsg(messages.overdue, {
      days: Math.abs(diff)
    });
  } else if (diff > 0) {
    label = intl.formatMsg(messages.due_in, {
      days: diff
    });
  }

  return (
    <Container $isOverdue={isOverdue} $isSoonOverdue={isSoonOverdue}>
      <ClockCircleOutlined />
      <Tooltip title={formatDate(date)}>
        <Typography.Text ellipsis={true}>{label}</Typography.Text>
      </Tooltip>
    </Container>
  );
};

export default TicketDueDateStatusText;
