import { useContext } from 'react';
import { ResponsiveContext, ScreenInfo } from 'layouts/responsive/responsiveContext';

/***
 * This will replace useBreakpoint()
 * - always return screen info on first render
 * - no support for SSR (which we don't use anyway)
 */
export function useScreenInfo(): ScreenInfo {
  const contextData = useContext(ResponsiveContext);
  if (contextData === undefined) {
    throw new Error('cannot use outside context');
  }
  return contextData;
}
