import { useGetRetailerListQuery } from 'generated/types';
import { useCallback, useEffect, useMemo } from 'react';
import useMessageApi from 'components/global/useMessageApi';
import { useAppSelector } from 'redux/store.ts';
import { selectMachineNavFilter, selectSearchTerm } from 'redux/machineNavSlice.ts';

function useGetAllRetailers(includeMachineCount: boolean) {
  const message = useMessageApi();
  const { showRetailersWithoutMachines, showInactiveMachines } =
    useAppSelector(selectMachineNavFilter);
  const searchTerm = useAppSelector(selectSearchTerm);

  const {
    data,
    fetchMore,
    loading: loadingRetailers,
    error,
    refetch
  } = useGetRetailerListQuery({
    variables: {
      cursor: undefined,
      searchTerm,
      showRetailersWithoutMachines,
      includeMachineCount,
      showActiveMachines: showInactiveMachines ? undefined : true
    }
  });

  const hasNextPage = data?.allRetailers?.pageInfo.hasNextPage || false;
  const nextCursor = data?.allRetailers?.pageInfo.endCursor;

  const retry = useCallback(async () => {
    try {
      await refetch({
        cursor: undefined,
        searchTerm,
        showRetailersWithoutMachines,
        includeMachineCount,
        showActiveMachines: showInactiveMachines ? undefined : true
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        message.error('Error when refetching retailers: ' + error.message);
      }
    }
  }, [
    refetch,
    searchTerm,
    showRetailersWithoutMachines,
    includeMachineCount,
    showInactiveMachines,
    message
  ]);

  useEffect(() => {
    if (hasNextPage) {
      fetchMore({
        variables: {
          cursor: nextCursor,
          searchTerm,
          showRetailersWithoutMachines,
          showActiveMachines: showInactiveMachines ? undefined : true
        }
      });
    }
  }, [
    hasNextPage,
    nextCursor,
    fetchMore,
    loadingRetailers,
    searchTerm,
    showInactiveMachines,
    showRetailersWithoutMachines
  ]);

  const allRetailers = useMemo(() => {
    return data?.allRetailers?.edges?.map((c) => c.node) || undefined;
  }, [data?.allRetailers?.edges]);

  return {
    isLoading: loadingRetailers,
    retailers: allRetailers,
    hasNextPage,
    error,
    retry,
    totalCount: data?.allRetailers?.totalCount
  };
}

export default useGetAllRetailers;
