import React from 'react';
import styled from 'styled-components';

const StyledUl = styled.ul<{ $isSubMenu: boolean }>`
  width: 100%;
  box-sizing: border-box;
  display: block;
  list-style: none;
  padding: 0;
  padding-inline-start: 0;
  outline: none;
  margin: 0;

  transition:
    background 0.3s,
    width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s,
    height 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;

  color: ${(props) => props.theme.ant.colorText};
  font-size: ${(props) => props.theme.ant.fontSize}px;
  font-family: ${(props) => props.theme.ant.fontFamily};
  background-color: ${(props) =>
    props.$isSubMenu ? props.theme.ant.colorFillAlter : props.theme.ant.colorBgContainer};
`;

interface MenuProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  isSubMenu?: boolean;
}

const Menu: React.FC<MenuProps> = ({ children, style, isSubMenu }) => {
  return (
    <StyledUl style={style} role={'menu'} $isSubMenu={isSubMenu || false}>
      {children}
    </StyledUl>
  );
};

export default Menu;
