import { Message } from 'typescript-react-intl';
import { defineMessages } from 'react-intl';
import React, { ReactNode } from 'react';
import { DashboardOutlined, DollarCircleOutlined } from '@ant-design/icons';
import { TruckOutlineIcon } from 'components/icons/Icons';

export const allCategories = ['operational', 'logistics', 'commercial'] as const;
export type Category = (typeof allCategories)[number];

type CategoryMessages = { [key in Category]: Message };
const messages: CategoryMessages = {
  commercial: {
    id: 'connect_category.commercial',
    defaultMessage: 'Commercial'
  },
  logistics: {
    id: 'connect_category.logistics',
    defaultMessage: 'Logistics'
  },
  operational: {
    id: 'connect_category.operational',
    defaultMessage: 'Operational'
  }
};

export const categoryMessages = defineMessages(messages);

export const categoryIcons: { [key in Category]: ReactNode } = {
  operational: <DashboardOutlined />,
  logistics: <TruckOutlineIcon />,
  commercial: <DollarCircleOutlined />
};
