import React from 'react';
import { InputNumber as InputNumberComp, InputNumberProps } from 'antd';
import { useGetUserAndSettingsQuery } from 'generated/types';

type ValueType = string | number;

function GenericInputNumber<T extends ValueType = ValueType>({
  children,
  ...props
}: InputNumberProps<T>) {
  const { data } = useGetUserAndSettingsQuery({
    fetchPolicy: 'cache-first'
  });
  const { decimalSeparator, ...rest } = props;

  return (
    <InputNumberComp<T> decimalSeparator={data?.me.commaSeparator || decimalSeparator} {...rest}>
      {children}
    </InputNumberComp>
  );
}

export default GenericInputNumber;
