import React, { useEffect, useMemo } from 'react';
import { gql } from '@apollo/client/core';
import { FormattedMessage } from 'react-intl';
import useQueryParam from 'hooks/useQueryParam';
import {
  DataTransferMessageFullFragmentDoc,
  DataTransferMessageListItemFragment,
  DataTransferMessageListItemFragmentDoc,
  FullPageInfoFragmentDoc,
  GetDataTransferMessagesQueryVariables,
  MachineListItemFragmentDoc,
  OnDataTransferMessageCreatedDocument,
  OnDataTransferMessageCreatedSubscription,
  OnDataTransferMessageCreatedSubscriptionVariables,
  RetailerListItemFragmentDoc,
  useConfirmDataTransferMessageMutation,
  useDeleteDataTransferMessageMutation,
  useGetDataTransferMessagesQuery,
  useOnDataTransferMessageUpdatedSubscription,
  useResetDataTransferMessageMutation
} from 'generated/types';
import { ColumnsType } from 'antd/es/table';
import {
  Button,
  Col,
  message,
  Popconfirm,
  Radio,
  Result,
  Row,
  Space,
  Table,
  Typography
} from 'antd';
import commonMessages from 'components/i18n/commonMessages';
import { InfoCircleOutlined, MailOutlined } from '@ant-design/icons';
import useDateFormatTools from 'i18n/useDateFormatTools';
import { parseISO } from 'date-fns';
import RetailerLink from 'components/retailer/RetailerLink/RetailerLink';
import MachineLink from 'components/machine/MachineLink/MachineLink';
import DataTransferMessage from 'components/datatransfer/DataTransferMessage';
import styled from 'styled-components';
import useMatrixNav from 'layouts/matrix/useMatrixNav';
import ErrorIllustration from 'components/illustrations/ErrorIllustration.tsx';
import { getFriendlyApolloErrorMessages, getStatusCode } from 'graphql/apollo/apolloErrorUtil.ts';
import NotAuthorizedIllustration from 'components/illustrations/NotAuthorizedIllustration.tsx';
import NotFoundIllustration from 'components/illustrations/NotFoundIllustration.tsx';
import Link from 'components/lib/Link/Link.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import Popover from 'components/lib/Popover/Popover.tsx';
import useOnWebsocketReconnected from 'components/lib/List/useOnWebsocketReconnected.ts';

gql`
  fragment DataTransferMessageListItem on DataTransferMessage {
    id
    dataTransferMessageId
    subject
    sequence
    serviceName
    sessionId
    confirmed
    timestamp
    stages {
      timestamp
      stage
      trace
    }
    machine {
      ...MachineListItem
    }
    retailer {
      ...RetailerListItem
    }
  }
  ${MachineListItemFragmentDoc}
  ${RetailerListItemFragmentDoc}
`;

gql`
  query GetDataTransferMessages($filter: AllDataTransferMessagesFilterInput!, $cursor: String) {
    allDataTransferMessages(
      sortField: ID
      sortDirection: DESCENDING
      first: 50
      after: $cursor
      filter: $filter
    ) {
      totalCount
      pageInfo {
        ...FullPageInfo
      }
      edges {
        node {
          ...DataTransferMessageListItem
        }
      }
    }
  }
  ${DataTransferMessageListItemFragmentDoc}
  ${FullPageInfoFragmentDoc}
`;

gql`
  mutation ResetDataTransferMessage($input: ResetDataTransferMessageInput!) {
    resetDataTransferMessage(input: $input) {
      dataTransferMessage {
        ...DataTransferMessageFull
      }
    }
  }
  ${DataTransferMessageFullFragmentDoc}
`;

gql`
  mutation ConfirmDataTransferMessage($input: ConfirmDataTransferMessageInput!) {
    confirmDataTransferMessage(input: $input) {
      dataTransferMessage {
        ...DataTransferMessageFull
      }
    }
  }
  ${DataTransferMessageFullFragmentDoc}
`;

gql`
  mutation DeleteDataTransferMessage($input: DeleteDataTransferMessageInput!) {
    deleteDataTransferMessage(input: $input) {
      deletedMessagePayload {
        id
      }
    }
  }
`;

gql`
  subscription OnDataTransferMessageUpdated($machineId: Int, $retailerId: Int) {
    onDataTransferMessageUpdated(machineId: $machineId, retailerId: $retailerId) {
      ...DataTransferMessageFull
    }
    ${DataTransferMessageFullFragmentDoc}
  }
`;

gql`
  subscription OnDataTransferMessageCreated(
    $machineId: Int
    $retailerId: Int
    $confirmed: Boolean
  ) {
    onDataTransferMessageCreated(
      machineId: $machineId
      retailerId: $retailerId
      confirmed: $confirmed
    ) {
      ...DataTransferMessageFull
    }
  }
  ${DataTransferMessageFullFragmentDoc}
`;

const MySpace = styled(Space)`
  && {
    .ant-popover-disabled-compatible-wrapper {
      width: 100%;
    }

    button {
      width: 100%;
    }
  }
`;

const ActionCol = styled(Col)`
  &&& {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
`;

const IconContainer = styled.div<{ $success: boolean }>`
  color: ${(props) => (props.$success ? props.theme.ant.colorSuccess : props.theme.ant.colorError)};
`;

interface Props {
  retailerId?: number;
  machineId?: number;
}

interface DataTransferListFilter {
  confirmed?: boolean;
}

const defaultFilter: DataTransferListFilter = {
  confirmed: undefined
};

function useDataTransferFilter() {
  const [filterParam, setFilterParam] = useQueryParam<DataTransferListFilter>('dtf');
  const filter = filterParam || defaultFilter;
  const setFilter = (value: Partial<DataTransferListFilter>) => {
    setFilterParam({
      ...defaultFilter,
      ...filterParam,
      ...value
    });
  };

  return { filter, setFilter };
}

const MessageStage = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
`;

const TraceMessage = styled.pre`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.85em;

  && {
    margin: 0;
  }
`;

const StackTrace = styled.pre`
  white-space: pre-wrap;
  overflow: auto;
  font-size: 0.85em;

  max-width: 400px;

  && {
    margin: 0;
  }
`;

const DataTransferList: React.FC<Props> = ({ retailerId, machineId }) => {
  const intl = useConnectIntl();
  const { filter, setFilter } = useDataTransferFilter();
  const { formatDate } = useDateFormatTools();
  const { resolving } = useMatrixNav();
  const variables = useMemo(() => {
    const v: GetDataTransferMessagesQueryVariables = {
      filter: {
        retailerId,
        machineId,
        confirmed: filter.confirmed
      },
      cursor: undefined
    };
    return v;
  }, [filter.confirmed, machineId, retailerId]);

  const { data, loading, error, fetchMore, refetch, subscribeToMore } =
    useGetDataTransferMessagesQuery({
      variables,
      skip: resolving,
      fetchPolicy: 'cache-and-network'
    });

  useOnDataTransferMessageUpdatedSubscription({
    variables: {
      machineId,
      retailerId
    },
    skip: resolving
  });

  useOnWebsocketReconnected({
    callback: refetch,
    skip: !data
  });

  useEffect(() => {
    if (resolving) return;

    return subscribeToMore<
      OnDataTransferMessageCreatedSubscription,
      OnDataTransferMessageCreatedSubscriptionVariables
    >({
      variables: {
        machineId: machineId,
        retailerId: retailerId,
        confirmed: filter.confirmed
      },
      document: OnDataTransferMessageCreatedDocument,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        if (!prev.allDataTransferMessages) return prev;
        const newMessage = subscriptionData.data.onDataTransferMessageCreated;

        return {
          ...prev,
          allDataTransferMessages: {
            ...prev.allDataTransferMessages,
            edges: [
              {
                node: newMessage,
                __typename: 'AllDataTransferMessagesEdge'
              },
              ...(prev.allDataTransferMessages?.edges || [])
            ],
            totalCount: (prev.allDataTransferMessages?.totalCount || 0) + 1,
            pageInfo: {
              ...prev.allDataTransferMessages.pageInfo
            }
          }
        };
      }
    });
  }, [machineId, retailerId, subscribeToMore, filter.confirmed, resolving]);

  const [deleteMessage] = useDeleteDataTransferMessageMutation();
  const [resetMessage] = useResetDataTransferMessageMutation();
  const [confirmMessage] = useConfirmDataTransferMessageMutation();

  const handleDeleteMessage = async (msg: DataTransferMessageListItemFragment) => {
    try {
      message.loading({
        key: 'deleteMessage_' + msg.id,
        content: 'Deleting message...'
      });
      await deleteMessage({
        variables: {
          input: {
            sequence: msg.sequence,
            serviceName: msg.serviceName,
            subject: msg.subject
          }
        }
      });
      // NOTE: This will refetch the entire list to update local cache.
      await refetch({
        filter: {
          retailerId,
          machineId,
          confirmed: filter.confirmed
        },
        cursor: undefined
      });
      message.success({
        key: 'deleteMessage_' + msg.id,
        content: 'Message deleted!'
      });
    } catch (err) {
      message.error({
        key: 'deleteMessage_' + msg.id,
        content: 'Error deleting message'
      });
    }
  };

  const handleResetMessage = async (msg: DataTransferMessageListItemFragment) => {
    try {
      message.loading({
        key: 'resetMessage_' + msg.id,
        content: 'Resetting message...'
      });
      await resetMessage({
        variables: {
          input: {
            sequence: msg.sequence,
            serviceName: msg.serviceName,
            subject: msg.subject
          }
        }
      });
      message.success({
        key: 'resetMessage_' + msg.id,
        content: 'Message reset'
      });
    } catch (err) {
      message.error({
        key: 'resetMessage_' + msg.id,
        content: 'Error resetting message'
      });
    }
  };

  const handleResetMessageNoAwait = (msg: DataTransferMessageListItemFragment) => {
    handleResetMessage(msg);
  };

  const handleConfirmMessage = async (msg: DataTransferMessageListItemFragment) => {
    try {
      message.loading({
        key: 'confirmMessage_' + msg.id,
        content: 'Confirming message...'
      });
      await confirmMessage({
        variables: {
          input: {
            sequence: msg.sequence,
            serviceName: msg.serviceName,
            subject: msg.subject
          }
        }
      });
      message.success({
        key: 'confirmMessage_' + msg.id,
        content: 'Message confirmed'
      });
    } catch (err) {
      message.error({
        key: 'confirmMessage_' + msg.id,
        content: 'Error confirming message'
      });
    }
  };

  const [openKey, setOpenKey] = React.useState<string | undefined>(undefined);

  const setIsTraceOpen = React.useCallback(
    (messageId: string, stageIndex: number, value: boolean) => {
      if (value) {
        setOpenKey(messageId + '_' + stageIndex);
      } else {
        setOpenKey(undefined);
      }
    },
    []
  );

  const isTraceOpen = React.useCallback(
    (messageId: string, stageIndex: number) => {
      return openKey === messageId + '_' + stageIndex;
    },
    [openKey]
  );

  const hasNextPage = data?.allDataTransferMessages?.pageInfo.hasNextPage || false;
  const nextCursor = data?.allDataTransferMessages?.pageInfo.endCursor;

  const columns: ColumnsType<DataTransferMessageListItemFragment> = [
    {
      key: 'message',
      title: 'Id',
      fixed: 'left',
      render: (value, record) => {
        return (
          <Space direction={'horizontal'} size={'small'}>
            <IconContainer $success={record.confirmed}>
              <MailOutlined />
            </IconContainer>
            {record.dataTransferMessageId}
          </Space>
        );
      }
    },
    {
      key: 'service',
      title: 'Service',
      render: (value, record) => {
        return record.serviceName;
      }
    }
  ];

  if (!resolving && !retailerId) {
    columns.push({
      key: 'retailer',
      title: 'Retailer',
      render: (value, record) => {
        return record.retailer ? (
          <RetailerLink retailer={record.retailer} allowWrap={true} hideIcon={false} />
        ) : (
          <div>Not found</div>
        );
      }
    });
  }

  if (!resolving && !machineId) {
    columns.push({
      key: 'machine',
      title: 'Machine',
      render: (value, record) => (
        <MachineLink machine={record.machine} allowWrap={true} appendSerialNumber={true} />
      )
    });
  }

  const restColumns: ColumnsType<DataTransferMessageListItemFragment> = [
    {
      key: 'session',
      title: 'Session',
      render: (value, record) => {
        return <Typography.Text>{record.sessionId?.toString()}</Typography.Text>;
      }
    },
    {
      key: 'subject',
      title: 'Subject',
      render: (value, record) => {
        return record.subject;
      }
    },
    {
      key: 'sequence',
      title: 'Sequence',
      render: (value, record) => {
        return record.sequence;
      }
    },
    {
      key: 'stages',
      title: 'Stages',
      width: 40,
      render: (value, record) => {
        return (
          <div>
            {record.stages.map((stage, index) => {
              return (
                <MessageStage key={index}>
                  <TraceMessage>{`${formatDate(parseISO(stage.timestamp), {
                    representation: 'complete'
                  })} ${stage.stage}`}</TraceMessage>
                  {stage.trace && (
                    <Popover
                      content={<StackTrace style={{ maxWidth: 400 }}>{stage.trace}</StackTrace>}
                      title={'Trace'}
                      trigger={'click'}
                      onOpenChange={(visible) => {
                        setIsTraceOpen(record.id, index, visible);
                      }}
                      open={isTraceOpen(record.id, index)}
                    >
                      <Button icon={<InfoCircleOutlined />} size={'small'} type={'link'} />
                    </Popover>
                  )}
                </MessageStage>
              );
            })}
          </div>
        );
      }
    },
    {
      key: 'actions',
      title: 'Actions',
      fixed: 'right',
      render: (value, record) => {
        return (
          <MySpace direction={'vertical'}>
            <Popconfirm
              title={'Are you sure you want to manually confirm this message?'}
              disabled={loading || record.confirmed}
              onConfirm={() => handleConfirmMessage(record)}
              okText={intl.formatMessage(commonMessages.yes)}
              cancelText={intl.formatMessage(commonMessages.no)}
            >
              <Button disabled={loading || record.confirmed} danger={true} size={'small'}>
                Confirm
              </Button>
            </Popconfirm>
            <Popconfirm
              title={'Are you sure you want to reset this message?'}
              onConfirm={() => handleResetMessageNoAwait(record)}
              okText={intl.formatMessage(commonMessages.yes)}
              cancelText={intl.formatMessage(commonMessages.no)}
            >
              <Button danger={true} size={'small'}>
                Reset
              </Button>
            </Popconfirm>
            <Popconfirm
              title={'Are you sure you want to delete this message?'}
              disabled={loading}
              onConfirm={() => handleDeleteMessage(record)}
              okText={intl.formatMessage(commonMessages.yes)}
              cancelText={intl.formatMessage(commonMessages.no)}
            >
              <Button danger={true} size={'small'} disabled={loading}>
                Delete
              </Button>
            </Popconfirm>
          </MySpace>
        );
      }
    }
  ];

  columns.push(...restColumns);

  const statusCode = getStatusCode(error);
  const friendlyErrorMessages = error ? getFriendlyApolloErrorMessages(error) : undefined;

  const items = useMemo(() => {
    const nodes = data?.allDataTransferMessages?.edges?.map((e) => e.node);
    if (filter.confirmed === undefined) return nodes;
    return nodes?.filter((e) => e.confirmed === filter.confirmed);
  }, [filter.confirmed, data]);

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col xs={18}>
          <Radio.Group
            optionType={'button'}
            value={filter.confirmed}
            onChange={(e) => {
              setFilter({ confirmed: e.target.value });
            }}
          >
            <Radio value={undefined}>All</Radio>
            <Radio value={false}>Unconfirmed</Radio>
            <Radio value={true}>Confirmed</Radio>
          </Radio.Group>
        </Col>
        <ActionCol xs={6}>
          <Button
            onClick={() =>
              message.info(
                'Not implemented yet, navigate to events tab filtered by events related to data transfer messages.'
              )
            }
          >
            Related events
          </Button>
        </ActionCol>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: 64 }}>
        <Col xs={24}>
          {error && !data && (
            <Result
              icon={
                statusCode === 403 ? (
                  <NotAuthorizedIllustration width={300} />
                ) : statusCode === 404 ? (
                  <NotFoundIllustration width={300} />
                ) : (
                  <ErrorIllustration width={300} />
                )
              }
              extra={
                <Space direction={'vertical'} align={'center'}>
                  <Button onClick={() => window.location.reload()} size={'small'} type={'link'}>
                    {intl.formatMsg(commonMessages.reload)}
                  </Button>
                  <Link to={'/'}>{intl.formatMsg(commonMessages.mainPageLink)}</Link>
                </Space>
              }
              title={intl.formatMsg({
                id: 'DataTransferList.error_loading_messages',
                defaultMessage: 'Error loading messages'
              })}
              subTitle={
                <Space direction={'vertical'}>
                  {friendlyErrorMessages?.map((line) => <div key={line}>{line}</div>)}
                </Space>
              }
            />
          )}
          {!error && (
            <Table<DataTransferMessageListItemFragment>
              columns={columns}
              bordered={false}
              size={'middle'}
              dataSource={items}
              rowKey={(record) => record.id}
              pagination={false}
              loading={!data}
              scroll={{ x: true }}
              style={{ marginBottom: hasNextPage ? 16 : 64 }}
              expandable={{
                expandedRowRender: (record) => (
                  <DataTransferMessage dataTransferMessageId={record.id} />
                )
              }}
              footer={() => {
                const count = data?.allDataTransferMessages?.totalCount;
                if (count === undefined) return <div />;
                const current = data?.allDataTransferMessages?.edges?.length;
                return (
                  <div>
                    <FormattedMessage
                      id={'data_transfer_list.footer'}
                      defaultMessage={'Showing {current} of total {count} messages'}
                      values={{ count, current }}
                    />
                  </div>
                );
              }}
            />
          )}
          {hasNextPage && (
            <Button
              loading={loading}
              disabled={loading}
              onClick={async () => {
                await fetchMore({
                  variables: {
                    cursor: nextCursor,
                    filter: {
                      retailerId,
                      machineId,
                      confirmed: filter.confirmed
                    }
                  }
                });
              }}
            >
              {intl.formatMessage(commonMessages.load_more)}
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default DataTransferList;
