import React, { useCallback } from 'react';
import { TicketListItemFragment } from 'generated/types.tsx';
import { defineMessages } from 'react-intl';
import useMatrixNav from 'layouts/matrix/useMatrixNav.ts';
import Link from 'components/lib/Link/Link.tsx';
import TicketTypeTag from 'components/ticket/TicketList/TicketTypeTag.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import Tag from 'components/lib/Tag/Tag.tsx';

const messages = defineMessages({
  title: {
    id: 'ticket_title.title',
    defaultMessage: '#{ticketId} {title}'
  }
});

interface Props {
  ticket: TicketListItemFragment;
  showType?: boolean;
  showTags?: boolean;
  noWrap?: boolean;
  onClickType?: (ticketTypeId: number) => void;
  onClickTag?: (tag: string) => void;
}

const TicketTitle: React.FC<Props> = ({ ticket, showTags, showType, onClickTag, onClickType }) => {
  const intl = useConnectIntl();
  const { getUrlToTicket } = useMatrixNav();
  const url = getUrlToTicket(ticket.ticketId);

  const handleClickTag = useCallback(
    (tag: string) => {
      onClickTag?.(tag);
    },
    [onClickTag]
  );

  return (
    <span>
      <Link to={url}>
        {intl.formatMsg(messages.title, { ticketId: ticket.ticketId, title: ticket.title })}
      </Link>
      {(showType ?? true) && (
        <TicketTypeTag
          ticketType={ticket.type}
          onClick={onClickType}
          style={{ marginLeft: '4px' }}
        />
      )}
      {showTags &&
        ticket.tags?.map((tag) => (
          <Tag
            key={tag}
            style={{ cursor: onClickTag ? 'pointer' : 'unset', marginLeft: '4px' }}
            onClick={() => handleClickTag(tag)}
          >
            {tag}
          </Tag>
        ))}
    </span>
  );
};

export default TicketTitle;
