import React from 'react';
import { Progress, Space, Tooltip } from 'antd';
import { ProgressProps } from 'antd/es/progress/progress';
import useAntThemeToken from 'themes/useAntThemeToken.ts';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import useIsMobile from 'layouts/responsive/useIsMobile.ts';

interface Props {
  score: number;
  minScore: number;
  showMinScore?: boolean;
}

const ClassificationPercentage: React.FC<
  Props & Omit<ProgressProps, 'steps' | 'size' | 'percent'>
> = ({ score, minScore, showMinScore }) => {
  const percentage = score * 100.0;
  const percentage2: number = Math.round((percentage + Number.EPSILON) * 100) / 100;
  const percentageFixed = percentage2.toFixed(2);
  const isSuccessful = score >= minScore;
  const unsuccessfulColor = '#4e4e4e';
  const { colorSuccess } = useAntThemeToken();
  const intl = useConnectIntl();
  const minScorePercentageFixed = (minScore * 100.0).toFixed(0);
  const isMobile = useIsMobile();

  return (
    <Tooltip
      title={intl.formatMsg(
        {
          id: 'prediction.classificationPercentage.tooltip',
          defaultMessage: 'Score: {score}{br}Threshold: {minScore}'
        },
        {
          score: score,
          minScore: minScore,
          br: <br />
        }
      )}
    >
      <Space>
        <Progress
          percent={percentage2}
          steps={isMobile ? 3 : 5}
          size={'default'}
          showInfo={false}
          strokeColor={isSuccessful ? colorSuccess : unsuccessfulColor}
        />
        <div style={{ width: '60px' }}>{percentageFixed}%</div>
        {showMinScore && <div style={{ width: '40px' }}>{minScorePercentageFixed}%</div>}
      </Space>
    </Tooltip>
  );
};

export default ClassificationPercentage;
