import React from 'react';
import { useParams } from 'react-router-dom';
import {
  MachineListItemFragmentDoc,
  MachinePermissionsFullFragment,
  MachinePermissionsFullFragmentDoc,
  useGetMachinePermissionsQuery
} from 'generated/types.tsx';
import parseNumber from 'util/parseNumber.ts';
import NotFoundPage from 'pages/util/NotFoundPage/NotFoundPage.tsx';
import ErrorPage from 'pages/util/ErrorPage/ErrorPage.tsx';
import useConnectIntl from 'i18n/useConnectIntl.ts';
import { isNotFoundError, isUnauthorizedApolloError } from 'graphql/apollo/apolloErrorUtil.ts';
import Spinner from 'components/lib/Spinner/Spinner.tsx';
import styled from 'styled-components';
import { gql } from '@apollo/client/core';

interface Props {
  children?: React.ReactNode;
}

const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

gql`
  query GetMachinePermissions($machineId: Int!) {
    machine(machineId: $machineId) {
      ...MachineListItem
      permissions {
        ...MachinePermissionsFull
      }
    }
  }
  ${MachineListItemFragmentDoc}
  ${MachinePermissionsFullFragmentDoc}
`;

interface MachinePermissionsContext {
  permissions: MachinePermissionsFullFragment;
  machineId: number;
}

const MachinePermissionsContext = React.createContext<MachinePermissionsContext | undefined>(
  undefined
);

export const useMachinePermissions = () => {
  const context = React.useContext(MachinePermissionsContext);
  if (!context) {
    throw new Error('useMachinePermissions must be used within a MachinePermissionsContext');
  }
  return context.permissions;
};

const RequireMachineAccess: React.FC<Props> = ({ children }) => {
  const intl = useConnectIntl();
  const { machineId: machineIdRaw } = useParams();
  const machineId = parseNumber(machineIdRaw);
  const { data, loading, error } = useGetMachinePermissionsQuery({
    variables: machineId
      ? {
          machineId: machineId
        }
      : undefined,
    skip: machineId === undefined || isNaN(machineId)
  });

  const isUnauthorized = isUnauthorizedApolloError(error);
  const isNotFound = isNotFoundError(error);
  const isInvalidMachineId = machineId === undefined || isNaN(machineId);

  if (loading) {
    return (
      <Container>
        <Spinner delayUntil={600} />
      </Container>
    );
  }

  if (isUnauthorized || isNotFound || isInvalidMachineId) {
    return (
      <NotFoundPage
        subtitle={intl.formatMsg(
          {
            id: 'require_machine_access.subtitle',
            defaultMessage:
              'Sorry, the machine with id {machineId} does not exist, {br}or you might not have access to it.'
          },
          {
            machineId: machineIdRaw,
            br: <br />
          }
        )}
      />
    );
  } else if (error) {
    return <ErrorPage error={error} />;
  }

  return data ? (
    <MachinePermissionsContext.Provider
      value={{
        permissions: data.machine.permissions,
        machineId: machineId
      }}
    >
      {children}
    </MachinePermissionsContext.Provider>
  ) : (
    children
  );
};

export default RequireMachineAccess;
