import React from 'react';
import { TicketCommentTypes } from 'generated/types';
import {
  TicketCloseAsNotCompletedIcon,
  TicketCloseIcon,
  TicketOpenIcon,
  TicketReopenIcon
} from 'components/ticket/TicketDiscussion/ticketIcons';
import { ClockCircleOutlined, CommentOutlined, UserOutlined } from '@ant-design/icons';

const getTicketCommentTypeIcon = (commentType: TicketCommentTypes) => {
  switch (commentType) {
    case TicketCommentTypes.Opened:
      return <TicketOpenIcon />;
    case TicketCommentTypes.ClosedAsNotCompleted:
      return <TicketCloseAsNotCompletedIcon />;
    case TicketCommentTypes.Closed:
      return <TicketCloseIcon />;
    case TicketCommentTypes.Reopened:
      return <TicketReopenIcon />;
    case TicketCommentTypes.Commented:
      return <CommentOutlined />;
    case TicketCommentTypes.Assigned:
    case TicketCommentTypes.Unassigned:
      return <UserOutlined />;
    case TicketCommentTypes.SetDue:
      return <ClockCircleOutlined />;
    case TicketCommentTypes.SetTitle:
      return null;
    case TicketCommentTypes.SetSeverity:
      return null;
    case TicketCommentTypes.SetType:
      return null;
  }
};

export default getTicketCommentTypeIcon;
