import { UploadLocale } from 'antd/es/upload/interface';
import { Message } from 'typescript-react-intl';

type UploadMessageKeys = keyof UploadLocale | 'upload' | 'uploadImageHint';
export type UploadMessages = { [key in UploadMessageKeys]: Message };
const uploadMessages: UploadMessages = {
  downloadFile: {
    id: 'upload.download_file',
    defaultMessage: 'Download'
  },
  uploadError: {
    id: 'upload.upload_error',
    defaultMessage: 'Error'
  },
  previewFile: {
    id: 'upload.preview_file',
    defaultMessage: 'Preview'
  },
  uploading: {
    id: 'upload.uploading',
    defaultMessage: 'Uploading'
  },
  removeFile: {
    id: 'upload.remove_file',
    defaultMessage: 'Remove'
  },
  upload: {
    id: 'upload.upload',
    defaultMessage: 'Upload'
  },
  uploadImageHint: {
    id: 'upload.upload_image',
    defaultMessage: '{icon} Upload image'
  }
};

export default uploadMessages;
